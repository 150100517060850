import React, { useState, useRef, useEffect } from "react";
import { FiMinus } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import { IoSearchOutline } from "react-icons/io5";
import Modal from "components/ui/Modal";
import Button from "components/ui/Button";
import { useForm, useFieldArray } from "react-hook-form";
import axios from "utils/axios";
import { Autocomplete } from "@react-google-maps/api"; // Removed useJsApiLoader
import SignatureCanvas from "react-signature-canvas";
import {
  fetchSignatureUrl,
  formatCurrency,
  formatDateToAEDT,
} from "utils/helperFunctions";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import "./AuthoriseSchedule.css";
import MarketingPrice from "components/ui/MarketingPrice";
import "../chat/BookAppraisal.css";
import EventsCalendar from "./EventsCalendar";
import CampaignAgent from "./CampaignAgent";
import EventsCalendarComplete from "./EventsCalendarComplete";
import ChatButton from "./Chatbot";

const AuthoriseScheduleComplete = ({ fetchedData }) => {
  const propertyId = fetchedData.propertyId;
  const [resetConfirm, setResetConfirm] = useState(false);

  const resetAuthSchedule = async () => {
    const response = await axios.delete(`/authSchedule/${propertyId}`);
    if (response.data.success) {
      window.location.reload();
    }
  };

  const data = {
    agentContribution: fetchedData.marketing.agentContribution.amount.replace(
      "$",
      ""
    ),
    dataEstimatedSalePrice: fetchedData.endPrice.replace(/[$,]/g, ""),
    listingId: fetchedData.propertyId,
    apiConsumerName: "AusRealty",
    userEmail: fetchedData.agent.email,
    hasPermission: true,
    userName: fetchedData.agent.name,
    dataMode: "advanced",
    officeName: "AusRealty",
    officeOwnershipGroupName: fetchedData.agent.officeName,
    officeOwnershipGroup_Ui: fetchedData.agent.officeId,
    officeUniqueIdentifier: fetchedData.agent.officeId,
    owners: fetchedData.vendors.map((vendor) => ({
      email: vendor.email,
      mobile: vendor.mobile,
      name: vendor.firstName + " " + vendor.lastName,
    })),
    propertyAddress: fetchedData.property.address + " NSW",
    requestedAmount: fetchedData.marketing.total,
    listingAgentName: fetchedData.agent.name,
    listingAgentEmail: fetchedData.agent.email,
    listingAgentMobile: fetchedData.agent.mobile,
  };

  const requiredAddresses = [
    // "43 Rona Street, Peakhurst",
    // "93 Clarke Street, Peakhurst",
    // // "80 Clarke Street, Peakhurst",
    // "22A Park Street, Peakhurst",
  ];

  return (
    <>
      <div className="authorise-form w-full h-full max-w-4xl mx-auto px-4">
        <p className="mb-4 text-darkgray text-center">{fetchedData.address}</p>
        <div className="flex items-center justify-between w-full">
          <div className="flex-1 text-center">
            <h5 className="inline-block">Status: Completed</h5>
          </div>
          <Button
            className="black-button px-2 py-1 text-xs"
            onClick={() => setResetConfirm(true)}
          >
            Reset
          </Button>
        </div>

        <table className="w-full border border-lightgray border-collapse mt-2">
          <thead>
            <tr className="bg-lightgray">
              <th className="py-2 px-4 text-center">Signer</th>
              <th className="py-2 px-4 text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            {fetchedData.vendors.map((vendor, index) => (
              <Disclosure key={index}>
                {({ open }) => (
                  <>
                    <Disclosure.Button as="tr" className="cursor-pointer">
                      <td className="py-2 px-4 border-b text-center">
                        {vendor.firstName} {vendor.lastName}
                      </td>

                      <td className="py-2 px-4 border-b text-center">
                        {vendor.isSigned
                          ? "Signed"
                          : vendor.viewedDate
                          ? "Viewed"
                          : vendor.sentDate
                          ? "Sent"
                          : ""}
                      </td>
                    </Disclosure.Button>
                    <Disclosure.Panel as="tr">
                      <td colSpan="2" className="border-t">
                        <div className="w-full overflow-x-auto">
                          <table className="w-full table-fixed border-collapse">
                            <thead>
                              <tr className="bg-lightgray">
                                <th className="py-2 px-4 text-center">
                                  Sent Date
                                </th>
                                <th className="py-2 px-4 text-center">
                                  Viewed Date
                                </th>
                                <th className="py-2 px-4 text-center">
                                  Signed Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="py-2 px-4 text-center">
                                  {vendor.sentDate || "N/A"}
                                </td>
                                <td className="py-2 px-4 text-center">
                                  {vendor.viewedDate || "N/A"}
                                </td>
                                <td className="py-2 px-4 text-center">
                                  {vendor.signedDate || "N/A"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </tbody>
        </table>

        <br></br>

        <div className="flex items-center justify-between py-2">
          <div className="flex flex-col">
            <p>Solicitor: {fetchedData.solicitor.name}</p>
            <p>{fetchedData.solicitor.sentDate}</p>
          </div>
          <Button className="gray-button px-2 py-1 text-xs h-auto">
            Resend
          </Button>
        </div>

        <div className="flex items-center justify-between py-2">
          <p>Building & Pest - {fetchedData.services[0].value}</p>
          <p>{fetchedData.services[0].sentDate}</p>
        </div>

        <div className="flex items-center justify-between py-2">
          <p>Strata - {fetchedData.services[1].value}</p>
          <p>{fetchedData.services[1].sentDate}</p>
        </div>

        <div className="flex items-center justify-between py-2">
          <p>Styling - {fetchedData.services[2].value}</p>
          <p>{fetchedData.services[2].sentDate}</p>
        </div>

        {fetchedData.vendorFollowers.length > 0 && (
          <div className="flex flex-col items-center justify-between py-2">
            <h6>Vendor Followers</h6>
            <table className="w-full border border-lightgray border-collapse mt-2">
              <thead>
                <tr className="bg-lightgray">
                  <th className="py-2 px-4 text-center">Name</th>
                  <th className="py-2 px-4 text-center">Email</th>
                  <th className="py-2 px-4 text-center">Mobile</th>
                </tr>
              </thead>
              <tbody>
                {fetchedData.vendorFollowers.map((vendorFollower, index) => (
                  <tr>
                    <td className="py-2 px-4 border-b text-center">
                      {vendorFollower?.name}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {vendorFollower?.email}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {vendorFollower?.mobile}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {fetchedData.followers.length > 0 && (
          <div className="flex flex-col items-center justify-between py-2">
            <h6>Co-Agent</h6>
            <table className="w-full border border-lightgray border-collapse mt-2">
              <thead>
                <tr className="bg-lightgray">
                  <th className="py-2 px-4 text-center">Name</th>
                  <th className="py-2 px-4 text-center">Email</th>
                  <th className="py-2 px-4 text-center">Mobile</th>
                </tr>
              </thead>
              <tbody>
                {fetchedData.followers.map((follower, index) => (
                  <tr>
                    <td className="py-2 px-4 border-b text-center">
                      {follower?.name}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {follower?.email}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {follower?.mobile}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* {requiredAddresses.includes(fetchedData.property.address) && (
          <div className="flex items-center justify-between py-2">
            <p>WhatsApp group created - Yes</p>
          </div>
        )} */}

        <br></br>

        <div className="col-span-12 grid grid-cols-12 gap-3">
          <div className="col-span-4 flex flex-col justify-between">
            <label className="form-label">Prepare Marketing</label>
            <input
              type="text"
              className={`form-input border border-mediumgray`}
              value={fetchedData.prepareMarketing}
              readOnly
            />
          </div>
          <div className="col-span-4 flex flex-col justify-between">
            <label className="form-label">
              {fetchedData.saleProcess === "Auction"
                ? "Auction Date"
                : "Closing Date"}
            </label>
            <input
              type="text"
              className={`form-input border border-mediumgray`}
              value={fetchedData.conclusionDate}
              readOnly
            />
          </div>
          <div className="col-span-4 flex flex-col justify-between">
            <label className="form-label">Access</label>
            <input
              type="text"
              className={`form-input border border-mediumgray`}
              value={fetchedData.access}
              readOnly
            />
          </div>
        </div>

        <br></br>

        {fetchedData?.marketing && (
          <div className="w-full">
            <label className="text-center form-label">SHOPPING CART</label>
            <div className="w-full flex items-center justify-center">
              <MarketingPrice
                suburb={fetchedData.property.suburb}
                price={fetchedData.endPrice}
                isEdit={false}
                existingData={fetchedData.marketing}
              />
            </div>
          </div>
        )}

        <br></br>

        {/* {requiredAddresses.includes(fetchedData.property.address) && ( */}
        <CampaignAgent data={data} />
        {/* )} */}

        <br></br>

        {/* {requiredAddresses.includes(fetchedData.property.address) && ( */}
        <EventsCalendarComplete
          propertyId={fetchedData.propertyId}
          data={fetchedData}
        />
        {/* )} */}

        {/* {requiredAddresses.includes(fetchedData.property.address) && ( */}
        <ChatButton />
        {/* )} */}
      </div>

      <Modal
        isOpen={resetConfirm}
        onClose={() => setResetConfirm(false)}
        title="Reset Agreement"
      >
        <div className="flex flex-col items-center justify-center">
          <p>
            Are you sure you want to reset the agreement? Once reset it cannot
            be reverted
          </p>
          <div className="w-full flex gap-2 justify-end py-4">
            <Button
              onClick={() => setResetConfirm(false)}
              className="gray-button"
            >
              No
            </Button>
            <Button onClick={resetAuthSchedule} className="black-button">
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AuthoriseScheduleComplete;
