import axios from "utils/axios";

export const formatCurrency = (value) => {
  if (value === undefined || value === null) return "N/A";
  return "$" + new Intl.NumberFormat().format(value);
};

export const fetchImageAsBase64 = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl, { mode: "cors" });
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // Data URI
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error fetching image as base64:", error);
    return null;
  }
};

export const fetchSignatureUrl = async () => {
  try {
    const response = await axios.get("/profile/get-signature-url");
    if (response.data.success) {
      const imageUrl = response.data.data;
      const base64Image = await fetchImageAsBase64(imageUrl);
      return base64Image;
    }
  } catch (error) {
    console.error("Error fetching signature URL:", error);
  }
};

export function formatDateToAEDT(date) {
  // If date is invalid or null, use current date and time
  const dateObj = date ? new Date(date) : new Date();

  // Options for date formatting
  const dateOptions = {
    timeZone: "Australia/Sydney",
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  const timeOptions = {
    timeZone: "Australia/Sydney",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short",
  };

  const dateFormatter = new Intl.DateTimeFormat("en-AU", dateOptions);
  const timeFormatter = new Intl.DateTimeFormat("en-AU", timeOptions);

  // Format date and time separately
  const formattedDate = dateFormatter.format(dateObj); // e.g., "17 Jun 2024"
  const formattedTimeWithZone = timeFormatter.format(dateObj); // e.g., "2:15 pm AEDT"

  // Extract time and timezone abbreviation
  const timeWithZoneParts = formattedTimeWithZone.split(" ");
  const time = timeWithZoneParts[0] + timeWithZoneParts[1].toLowerCase(); // "2:15pm"
  const timeZoneAbbreviation = timeWithZoneParts[2]; // "AEDT" or "AEST"

  // Split date into components
  const dateParts = formattedDate.split(" "); // ["17", "Jun", "2024"]
  const day = dateParts[0];
  const month = dateParts[1];
  const year = dateParts[2];

  // Construct the final formatted date
  const finalFormattedDate = `${day}/${month}/${year} ${time}(${timeZoneAbbreviation})`;

  return finalFormattedDate;
}

export const fetchVendorsSignatureUrl = async (propertyId) => {
  try {
    const response = await axios.get(`/authSchedule/vendorsSign/${propertyId}`);
    if (response.data.success) {
      const vendors = response.data.data;

      // Loop through vendors to fetch signature images in base64 format
      const vendorsWithBase64Images = await Promise.all(
        vendors.map(async (vendor) => {
          if (vendor && vendor.signatureSignedUrl) {
            const base64Image = await fetchImageAsBase64(vendor.signatureSignedUrl);
            return {
              ...vendor,
              base64Image,
            }; // Include the base64 image in the vendor object
          }
          return vendor; // Return vendor as-is if there's no signed URL
        })
      );

      return vendorsWithBase64Images; // Return the updated vendors list with base64 images
    }
  } catch (error) {
    console.error("Error fetching signature URL:", error);
    throw error; // Re-throw the error if needed for further handling
  }
};
export const parseOptionNameAccordingToCategory = (category, options) => {
  const lowerCategory = category.toLowerCase();

  return options.map(option => {
    let parsedName = option.name;

    switch (true) {
      case lowerCategory.includes("video"):
        if (parsedName.includes("Melo -")) {
          parsedName = parsedName.replace("Melo - ", "");
        }
        break;

      case lowerCategory.includes("auctioneer"):
        if (parsedName.includes(" - Auctioneer")) {
          parsedName = parsedName.replace(" - Auctioneer", "");
        }
        break;

      case lowerCategory.includes("photo"):
        if (parsedName.includes("Melo Photography -")) {
          parsedName = parsedName.replace("Melo Photography - ", "");
        }
        if (parsedName.includes("Virtual Furniture")) {
          parsedName = parsedName.replace("Virtual Furniture", "VF");
        }
        break;

      case lowerCategory.includes("signboard"):
        if (parsedName.includes("Melo -")) {
          parsedName = parsedName.replace("Melo - ", "");
        }
        if (parsedName.includes("Satin Laminated Edge Wrap")) {
          parsedName = parsedName.replace("Satin Laminated Edge Wrap", "");
        }
        if (parsedName.includes("Solar Illuminated")) {
          parsedName = parsedName.replace("Solar Illuminated", "solar");
        }
        parsedName = parsedName.replace("Signboard", "sign").trim();
        break;

      case lowerCategory.includes("social"):
        if (parsedName.includes("Melo -")) {
          parsedName = parsedName.replace("Melo - ", "");
        }
        break;

      case lowerCategory.includes("floorplan"):
        if (parsedName.includes("Melo -")) {
          parsedName = parsedName.replace("Melo - ", "");
        }
        break;

      case lowerCategory.includes("mailcards") || lowerCategory.includes("brochures"):
        const sizeMatch = parsedName.match(/\b(A\d|A\d+)\b/); // Matches "A4", "A6", etc.
        const pageMatch = parsedName.match(/(\d+ Page)/i); // Matches "8 Page", "16 Page", etc.

        if (sizeMatch && pageMatch) {
          parsedName = `${sizeMatch[0]} ${pageMatch[0]} brochure`;
        } else if (sizeMatch) {
          parsedName = `${sizeMatch[0]} mailcards`;
        }
        break;

      default:
        break;
    }

    return { ...option, name: parsedName.trim() };
  });
};
