import React, { useState, useRef, useEffect } from "react";
import Modal from "components/ui/Modal";
import Button from "components/ui/Button";
import axios from "utils/axios";
import "./AuthoriseSchedule.css";
import PageLoader from "components/ui/PageLoader";
import { showToast } from "components/ui/toast";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { FaListUl } from "react-icons/fa6";
import {
  format,
  parseISO,
  parse,
  addMinutes,
  differenceInMinutes,
  isValid,
} from "date-fns";

import Calendar from "react-calendar";
import "../chat/BookAppraisal.css";
import CalendarEvent from "components/ui/CalendarEvent";

const tileDisabled = ({ date, view }) => {
  if (view === "month") {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to midnight
    date.setHours(0, 0, 0, 0); // Normalize to midnight
    return date < today; // Disable dates before today
  }
  return false; // Do not disable other views
};

const SelectWithImage = ({
  options,
  onSelect,
  placeholder = "Select an option...",
  selectedValue, // Add this prop to pre-select an option
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    options.find((option) => option._id === selectedValue) || null // Initialize with the selected value
  );
  const dropdownRef = useRef(null);

  const handleSelect = (option) => {
    setSelectedOption(option);
    onSelect(option); // Callback to parent
    setIsOpen(false);
  };

  // Handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      {/* Dropdown Button */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between border border-mediumgray rounded px-4 py-1.5 text-sm focus:outline-none"
      >
        {selectedOption ? (
          <div className="flex items-center gap-3">
            <img
              src={selectedOption.picture}
              alt={selectedOption.name}
              className="w-8 h-8 rounded-full object-cover"
            />
            <div className="flex flex-col items-start">
              <span className="font-medium">{selectedOption.name}</span>
            </div>
          </div>
        ) : (
          <span className="text-darkergray">{placeholder}</span>
        )}
        <i className="fas fa-chevron-down text-sm"></i>
      </div>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute mt-1 w-full bg-white border border-mediumgray rounded z-10">
          <ul className="py-0 max-h-60 overflow-auto m-0">
            {options.map((option) => (
              <li key={option._id}>
                <button
                  onClick={() => handleSelect(option)}
                  className="w-full px-4 py-2 flex items-center gap-3 hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
                >
                  <img
                    src={option.picture || "default-avatar.png"}
                    alt={option.name}
                    className="w-8 h-8 rounded-full object-cover"
                  />
                  <div className="flex flex-col items-start">
                    <span className="font-medium">{option.name}</span>
                  </div>
                  {selectedOption?._id === option._id && (
                    <i className="fas fa-check-circle ml-auto text-black"></i>
                  )}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const EventsCalendarComplete = ({ propertyId, data }) => {
  const [events, setEvents] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Default to current date
  const [startTime, setStartTime] = useState("06:00 AM");
  const [endTime, setEndTime] = useState("");
  const [eventDuration, setEventDuration] = useState(0);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [showCalendarEvent, setShowCalendarEvent] = useState(false);
  const [slotsLoading, setSlotsLoading] = useState(false);
  const [contractors, setContractors] = useState([]);
  useEffect(() => {
    const fetchContractors = async () => {
      try {
        const response = await axios.get(`/contractor?profileComplete=true`);
        if (response.data.success) {
          setContractors(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching contractors", error);
      }
    };
    fetchContractors();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`/events/${propertyId}`);
        if (response.data.success) {
          const sortedEvents = response.data.data.sort(
            (a, b) => new Date(a.start) - new Date(b.start)
          );

          setEvents(sortedEvents);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, [propertyId]); // Add `propertyId` as a dependency to refetch if it changes

  useEffect(() => {
    if (selectedEvent && selectedEvent.start) {
      // Set the selected date based on the event start time
      setSelectedDate(parseISO(selectedEvent.start));

      if (!selectedEvent.end) {
        return;
      }
      // Set startTime and endTime based on the selected event's start and end times in AM/PM format
      const initialStart = format(parseISO(selectedEvent.start), "hh:mm a");
      const initialEnd = format(parseISO(selectedEvent.end), "hh:mm a");

      setStartTime(initialStart);
      setEndTime(initialEnd);

      const duration = differenceInMinutes(
        parseISO(selectedEvent.end),
        parseISO(selectedEvent.start)
      );
      setEventDuration(duration);
    }
  }, [selectedEvent]);

  // Function to handle date changes from the Calendar
  const handleDateChange = async (date) => {
    console.log(date);
    setSelectedDate(date);

    // Clear previous time selection when the date changes
    setStartTime("");
    setEndTime("");
    setSlotsLoading(true);

    try {
      // Fetch available slots for the selected date
      const response = await axios.post(`/events/slots`, {
        contractorId: selectedEvent?.contractor?.id, // Use contractor ID if available
        event: selectedEvent, // Pass the current event
        date: format(date, "yyyy-MM-dd"),
      });

      if (response.data.success) {
        setAvailableSlots(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching available slots:", error.message);
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      showToast("error", errorMessage);
      setAvailableSlots([]);
    } finally {
      setSlotsLoading(false);
    }

    // Set modification status if we have an existing event with a start time
    if (selectedEvent?.start) {
      const currentStartDate = parseISO(selectedEvent.start);
      setIsModified(
        date.getDate() !== currentStartDate.getDate() ||
          date.getMonth() !== currentStartDate.getMonth() ||
          date.getFullYear() !== currentStartDate.getFullYear() ||
          startTime !== format(currentStartDate, "hh:mm a")
      );
    } else {
      setIsModified(true);
    }
  };

  // Helper function to format time
  const formatTimeRange = (start, end) => {
    const startTime = format(parseISO(start), "hh:mm a");
    const endTime = format(parseISO(end), "hh:mm a");
    return `${startTime} - ${endTime}`;
  };

  // Helper function to group events by day
  const groupedEvents = events.reduce((acc, event) => {
    const eventDate = format(parseISO(event.start), "yyyy-MM-dd");
    if (!acc[eventDate]) {
      acc[eventDate] = [];
    }
    acc[eventDate].push(event);
    return acc;
  }, {});

  const [editEvent, setEditEvent] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const updateEvent = async (selectedEvent) => {
    // Create a new Date object combining the selected date and time
    const startDateTime = parse(
      `${format(selectedDate, "yyyy-MM-dd")} ${startTime || "12:00 AM"}`,
      "yyyy-MM-dd hh:mm a",
      new Date()
    );

    const endDateTime = selectedEvent.end
      ? addMinutes(startDateTime, eventDuration).toISOString()
      : null;

    // Create the formatted object
    const updatedEvent = {
      ...selectedEvent, // Spread all properties of selectedEvent
      start: startDateTime.toISOString(), // Overwrite start
      end: endDateTime, // Overwrite end
    };

    try {
      setUpdateLoading(true);

      const filteredEvents = events.filter(
        (event) =>
          event.type !== "manual" && event.eventId !== updatedEvent.eventId
      );

      const response = await axios.put("/events/reschedule", {
        eventUpdate: updatedEvent,
        events: filteredEvents,
      });

      if (response.data.success) {
        const updatedData = Array.isArray(response.data.data)
          ? response.data.data
          : [response.data.data]; // Ensure `updatedData` is always an array

        // Merge the updated events into the existing events array
        const updatedEvents = events.map((event) => {
          const matchingUpdatedEvent = updatedData.find(
            (updatedEvent) => updatedEvent.eventId === event.eventId
          );

          return matchingUpdatedEvent
            ? { ...event, ...matchingUpdatedEvent }
            : event;
        });

        updatedEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

        setEvents(updatedEvents); // Update the state with sorted events
        setEditEvent(false);
      }
    } catch (error) {
      console.log("Error in reschedule event: " + error.message);
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleRescheduleClick = async (event) => {
    try {
      // setStartTime("");
      // setEndTime("");

      setSlotsLoading(true);

      const response = await axios.post(`/events/slots`, {
        contractorId: event.contractor?.id, // Replace with actual contractor ID
        event, // Pass the event summary directly
        date: selectedDate,
      });

      setAvailableSlots(response.data.data);
      // Reset startTime and endTime to clear any previously selected slot
    } catch (error) {
      console.log(error.message);
    } finally {
      setSlotsLoading(false);
    }
  };

  const formatToAmPm = (timeString) => {
    const parsedTime = parse(timeString, "HH:mm", new Date());
    return format(parsedTime, "hh:mm a");
  };

  const toggleCalendarEvent = () => {
    setShowCalendarEvent(!showCalendarEvent);
  };

  const handleTimeSelect = (start) => {
    setStartTime(start);
    const startDateTime = parse(start, "hh:mm a", new Date());
    const endDateTime = addMinutes(startDateTime, 60); // Assuming a 1-hour duration
    setEndTime(format(endDateTime, "hh:mm a"));
  };

  const dropdownRef = useRef(null);
  const [addEvent, setAddEvent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const todayDate = format(new Date(), "yyyy-MM-dd");
  const [manualEvents, setManualEvents] = useState([
    // {
    //   id: 1,
    //   summary: "Melo Photography - Sneak Peak Photos",
    //   duration: 60,
    //   start: `${todayDate}T04:00:00.000Z`,
    //   end: `${todayDate}T05:00:00.000Z`,
    //   contractor: {},
    // },
    {
      id: 2,
      summary: "Private Inspection",
      duration: 30,
      start: `${todayDate}T04:00:00.000Z`,
      end: `${todayDate}T04:30:00.000Z`,
      contractor: {},
    },
    {
      id: 3,
      summary: "Open home",
      duration: 30,
      start: `${todayDate}T04:00:00.000Z`,
      end: `${todayDate}T04:30:00.000Z`,
      contractor: {},
    },
  ]);
  // Fetch contractor details for each event based on summary
  useEffect(() => {
    const fetchContractors = async () => {
      try {
        const updatedEvents = await Promise.all(
          manualEvents.map(async (event) => {
            const response = await axios.post("/events/getManualContractor", {
              summary: event.summary,
            });
            if (response.data.success) {
              // Update contractor details in each event
              return {
                ...event,
                contractor: response.data.data, // Set contractor data here
              };
            }
            return event;
          })
        );

        setManualEvents([...updatedEvents]); // Force state update with a new array
      } catch (error) {
        console.error("Error fetching contractor details:", error);
      }
    };

    fetchContractors();
  }, []);

  const filteredEvents = manualEvents.filter((event) =>
    event.summary.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    // Calculate end time based on event duration or start and end times
    if (selectedEvent && startTime) {
      try {
        let duration;

        if (selectedEvent.duration) {
          // Use the duration if available
          duration = selectedEvent.duration;
        } else if (selectedEvent.start && selectedEvent.end) {
          // Calculate duration from start and end times if duration is not provided
          const startDateTime = parseISO(selectedEvent.start);
          const endDateTime = parseISO(selectedEvent.end);

          duration = differenceInMinutes(endDateTime, startDateTime);
          if (duration <= 0) {
            throw new Error(
              "Invalid event duration calculated from start and end times"
            );
          }
        } else {
          // If neither duration nor end/start are present, we cannot proceed
          throw new Error("Missing duration or event start and end times");
        }

        // Parse the new start time
        const newStartDateTime = parse(startTime, "hh:mm a", new Date());
        if (isNaN(newStartDateTime)) {
          throw new Error("Invalid start time value");
        }

        // Calculate new end time based on duration
        const newEndDateTime = addMinutes(newStartDateTime, duration);
        setEndTime(format(newEndDateTime, "hh:mm a"));
      } catch (error) {
        console.error("Error calculating end time:", error);
      }
    }
  }, [startTime, selectedEvent]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const [additionalNotes, setAdditionalNotes] = useState(null);
  const [addLoading, setAddLoading] = useState(false);

  const saveManualEvent = async () => {
    try {
      setAddLoading(true);
      const startDateTime = parse(
        `${format(selectedDate, "yyyy-MM-dd")} ${startTime}`,
        "yyyy-MM-dd hh:mm a",
        new Date()
      );

      let endDateTime;

      if (selectedEvent.end) {
        // Use the end time from selectedEvent if available
        endDateTime = parseISO(selectedEvent.end);
      } else {
        // Calculate end time using duration if end is not present
        endDateTime = addMinutes(startDateTime, selectedEvent.duration || 60);
      }

      const newEvent = {
        propertyId,
        summary: selectedEvent.summary,
        start: startDateTime.toISOString(),
        end: endDateTime.toISOString(),
        type: "manual",
        additionalNotes,
        access: data.access,
        address: data.address,
        contractor:
          selectedEvent?.contractor &&
          Object.keys(selectedEvent.contractor).length > 0
            ? selectedEvent.contractor
            : null, // Send null if contractor is empty
      };

      const response = await axios.post("/events", newEvent);
      if (response.data.success) {
        let updatedEvents;

        updatedEvents = [...events, newEvent];

        // Sort events by start time
        updatedEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

        setEvents(updatedEvents);

        setSelectedEvent(null);
        setAddEvent(false);
        setAdditionalNotes(null);
      }
    } catch (error) {
      console.error("Error saving event:", error);
      showToast("error", error.message);
    } finally {
      setAddLoading(false);
    }
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDelete = async (id) => {
    try {
      setDeleteLoading(id);
      // Send delete request to the server
      await axios.delete(`/events/${id}`);

      setEvents((prevEvents) => {
        const updatedEvents = prevEvents.filter((event) => event._id !== id);
        // Sort events by start date and time
        return updatedEvents.sort(
          (a, b) => new Date(a.start) - new Date(b.start)
        );
      });

      showToast("success", "Event deleted successfully");
    } catch (error) {
      console.error("Error deleting event:", error.message);
      showToast("error", "Failed to delete event. Please try again.");
    } finally {
      setDeleteLoading(false);
    }
  };

  const fetchAvailableSlots = async (selectedContractor) => {
    setSlotsLoading(true);

    try {
      const response = await axios.post(`/events/slots`, {
        contractorId: selectedContractor._id, // Use the selected contractor's ID
        event: selectedEvent, // Pass the current event
        date: format(selectedDate, "yyyy-MM-dd"), // Format the selected date
      });

      if (response.data.success) {
        setAvailableSlots(response.data.data);
      } else {
        setAvailableSlots([]);
      }
    } catch (error) {
      console.error("Error fetching available slots:", error.message);
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      showToast("error", errorMessage);
      setAvailableSlots([]);
    } finally {
      setSlotsLoading(false);
    }
  };

  // Define the mapping between event keywords and services
  const serviceMap = {
    photography: "Photographer",
    video: "Videographer",
    videos: "Videographer",
    "floor plan": "Floor planner",
    auction: "Auctioneer",
  };

  // Extract services offered based on event summary
  const getServicesOffered = (summary) => {
    const services = [];
    const lowerCaseSummary = summary.toLowerCase();

    Object.keys(serviceMap).forEach((key) => {
      if (lowerCaseSummary.includes(key)) {
        const service = serviceMap[key];
        if (!services.includes(service)) {
          services.push(service);
        }
      }
    });

    return services;
  };

  // Get contractors for the selected event
  const servicesOffered = getServicesOffered(selectedEvent?.summary || "");
  const filteredContractors = contractors.filter((contractor) =>
    contractor.services.some((service) => servicesOffered.includes(service))
  );

  if (!events) {
    return <p>No events found</p>;
  }

  // Render the component
  return (
    <div>
      <div className="flex items-center justify-end gap-4 mt-4">
        <Button className="black-button" onClick={() => setAddEvent(true)}>
          Add Event
        </Button>
        {showCalendarEvent ? (
          <FaListUl
            className="text-darkergray text-lg cursor-pointer"
            onClick={toggleCalendarEvent} // Switch to list view
          />
        ) : (
          <FaRegCalendarAlt
            className="text-darkergray text-lg cursor-pointer"
            onClick={toggleCalendarEvent} // Toggle the CalendarEvent component
          />
        )}
      </div>

      {showCalendarEvent && (
        <CalendarEvent events={events} /> // Pass events data to CalendarEvent
      )}

      {!showCalendarEvent && (
        <div className="space-y-4">
          {Object.keys(groupedEvents).map((date, index) => {
            const eventDate = parseISO(date);
            const dayName = format(eventDate, "EEE"); // Get day name
            const dayNumber = format(eventDate, "d"); // Get day number
            const shortMonthName = format(eventDate, "MMMM"); // Get abbreviated month name, e.g., "Oct"

            // Check if this is the first event or a new month has started
            const isNewMonth =
              index === 0 ||
              format(
                parseISO(Object.keys(groupedEvents)[index - 1]),
                "MMMM"
              ) !== shortMonthName;

            return (
              <React.Fragment key={index}>
                {/* Display month name if a new month starts */}
                {isNewMonth && (
                  <div className="font-bold" style={{ fontSize: "16px" }}>
                    {shortMonthName}
                  </div>
                )}

                <div className="flex gap-4 pb-4">
                  {/* Date column */}
                  <div className="w-16 flex-shrink-0 bg-black text-white px-3 py-2 rounded h-min">
                    <div className="text-2xl font-bold">{dayNumber}</div>
                    <div className="text-sm">{dayName}</div>
                  </div>

                  {/* Events column */}
                  <div className="flex-grow space-y-2">
                    {groupedEvents[date].map((event, eventIndex) => (
                      <div key={eventIndex} className="bg-gray-100 p-3 rounded">
                        <div className="text-sm mb-1 flex justify-between">
                          <span>
                            {event.summary.includes("Notify")
                              ? null
                              : event.end
                              ? formatTimeRange(event.start, event.end)
                              : new Date(event.start).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                          </span>
                        </div>
                        {event.summary && (
                          <div
                            className={`text-sm mb-1 ${
                              event.summary
                                .toLowerCase()
                                .includes("key meeting")
                                ? "font-bold"
                                : ""
                            }`}
                          >
                            {event.summary}
                          </div>
                        )}

                        {(event.summary.toLowerCase().includes("photo") ||
                          event.summary.toLowerCase().includes("video") ||
                          event.summary.toLowerCase().includes("floor plan") ||
                          event.summary.toLowerCase().includes("auction")) && (
                          <>
                            {/* Show contractor details if contractor exists */}
                            {event.contractor && (
                              <div className="text-sm flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                  <img
                                    src={event.contractor?.picture}
                                    alt="contractor"
                                    className="w-8 h-8 rounded-full object-cover"
                                  />
                                  <span>{event.contractor?.name}</span>
                                </div>
                                <span>{event.contractor?.mobile}</span>
                              </div>
                            )}

                            {!event.contractor && (
                              <p className="italic text-darkergray my-1 text-xs">
                                No contractor available
                              </p>
                            )}
                          </>
                        )}

                        <p className="text-xs text-darkergray italic">
                          {event.googleEventId
                            ? "Calendar invite sent"
                            : "Calendar invite not sent"}
                        </p>

                        <div className="flex gap-2 justify-end mt-2">
                          <Button
                            className="gray-button text-xs py-1 px-2"
                            onClick={() => handleDelete(event._id)}
                            loading={deleteLoading === event._id} // Show loader only for the matching event
                            disabled={deleteLoading === event._id} // Disable only the matching button
                          >
                            Delete
                          </Button>

                          <Button
                            className="black-button text-xs py-1 px-2"
                            onClick={() => {
                              setEditEvent(true);
                              setSelectedEvent(event);
                              setAdditionalNotes(event.additionalNotes || "");
                              handleRescheduleClick(event);
                            }}
                          >
                            Reschedule
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      )}

      <Modal
        isOpen={editEvent}
        onClose={() => {
          setEditEvent(false);
          setSelectedEvent(null);
          setAvailableSlots([]);
        }}
        title="Reschedule Event"
        className="max-w-md min-h-[400px] max-h-[94vh] px-2"
      >
        <div className="w-full flex flex-col items-center justify-center px-4">
          <p className="text-center">{selectedEvent?.summary}</p>

          <div className="w-full mt-4">
            <label className="form-label mb-2 font-semibold">
              Select Contractor
            </label>
            <SelectWithImage
              options={filteredContractors} // Array of contractors with { _id, name, picture, ... }
              onSelect={(selectedContractor) => {
                setSelectedEvent((prev) => ({
                  ...prev,
                  contractor: selectedContractor, // Update the contractor in the event
                }));
                setIsModified(true); // Mark the event as modified

                // Fetch available slots for the selected date and contractor
                fetchAvailableSlots(selectedContractor);
              }}
              placeholder="Select a contractor"
              selectedValue={
                selectedEvent?.contractor?.id || selectedEvent?.contractor?._id
              } // Pre-select the current contractor
            />
          </div>

          {selectedEvent?.contractor && (
            <div className="w-full px-4 py-2 bg-lightgray mt-2">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 shrink-0">
                  <img
                    src={selectedEvent.contractor?.picture}
                    alt="contractor"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="flex flex-col items-start">
                  <span>
                    <strong>Name:</strong> {selectedEvent.contractor?.name}
                  </span>
                  <span>
                    <strong>Email:</strong> {selectedEvent.contractor?.email}
                  </span>
                  <span>
                    <strong>Mobile:</strong> {selectedEvent.contractor?.mobile}
                  </span>
                </div>
              </div>
            </div>
          )}

          <br></br>

          <Calendar
            tileDisabled={tileDisabled}
            prev2Label={null}
            next2Label={null}
            value={selectedDate} // Use the selected date
            onChange={handleDateChange} // Handle date changes
          />

          <br></br>

          {selectedEvent?.summary !== "Notify off market buyers" && (
            <>
              <p className="font-semibold mb-2">
                Next Available Slots for {format(selectedDate, "dd-MM-yyyy")}
              </p>
              {slotsLoading ? (
                <i className="fa-solid fa-spinner animate-spin"></i>
              ) : (
                <>
                  {availableSlots && availableSlots.length > 0 ? (
                    <>
                      <div className="w-full grid grid-cols-4 gap-2">
                        {availableSlots.map((slot, index) => (
                          <button
                            key={index}
                            className={`p-2 rounded border text-center text-xs cursor-pointer transition ${
                              startTime === formatToAmPm(slot.start)
                                ? "bg-black text-white"
                                : "bg-white border-mediumgray hover:bg-lightgray"
                            }`}
                            onClick={() => {
                              handleTimeSelect(formatToAmPm(slot.start));
                              setIsModified(true);
                            }}
                          >
                            {formatToAmPm(slot.start)}
                          </button>
                        ))}
                      </div>
                      <p className="w-full mt-2">
                        Selected Time: {startTime} - {endTime}
                      </p>
                    </>
                  ) : (
                    <span className="text-sm text-darkergray">
                      No slots available
                    </span>
                  )}
                </>
              )}
            </>
          )}
          <br></br>

          {selectedEvent?.additionalNotes && (
            <div className="w-full">
              <label className="form-label">Additional Notes</label>
              <textarea
                className="form-textarea border border-mediumgray"
                rows={3}
                placeholder="Add notes here"
                value={additionalNotes}
                onChange={(e) => setAdditionalNotes(e.target.value)}
              ></textarea>
            </div>
          )}

          <div className="w-full flex justify-end">
            <Button
              className="black-button my-4 w-fit"
              loading={updateLoading}
              disabled={
                !isModified ||
                updateLoading ||
                (selectedEvent?.summary.toLowerCase() !==
                  "notify off market buyers" &&
                  (!startTime || !endTime))
              }
              onClick={() => updateEvent(selectedEvent)}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={addEvent}
        onClose={() => {
          setAddEvent(false);
          setSelectedEvent(null);
          setAvailableSlots([]);
        }}
        title="Add Event"
        className="max-w-md min-h-[400px] max-h-[94vh] px-4"
      >
        <div className="relative" ref={dropdownRef}>
          {/* Search/Dropdown Container */}
          <div className="relative">
            {/* Dropdown Button */}
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="w-full flex items-center justify-between border border-mediumgray rounded px-4 py-2 text-sm focus:outline-none"
            >
              <span className="text-darkergray">
                {selectedEvent?.summary || "Select an event..."}
              </span>
              <i className="fas fa-chevron-down text-sm"></i>
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
              <div className="absolute mt-1 w-full bg-white border border-mediumgray rounded shadow-lg z-10">
                {/* Search Input */}
                <div className="px-3 py-2 border-b border-mediumgray">
                  <div className="flex items-center gap-2">
                    <IoSearchOutline className="text-darkgray" />
                    <input
                      type="text"
                      placeholder="Search events..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-full outline-none text-sm"
                      onClick={(e) => e.stopPropagation()} // Prevents dropdown close on input click
                    />
                  </div>
                </div>

                {/* Events List */}
                <ul className="py-1 max-h-60 overflow-auto m-0">
                  {filteredEvents.map((event) => (
                    <li key={event.id}>
                      <button
                        // onClick={() => {
                        //   console.log(event);
                        //   setSelectedEvent(event); // Set selected event
                        //   setIsOpen(false); // Close dropdown after selection
                        // }}
                        onClick={async () => {
                          console.log(event);
                          setSelectedEvent(event); // Set selected event
                          setIsOpen(false); // Close dropdown after selection

                          // Start loading and clear previous slots
                          setSlotsLoading(true);
                          setAvailableSlots([]);

                          try {
                            const response = await axios.post(`/events/slots`, {
                              contractorId: event.contractor?.id, // Use contractor ID from event if available
                              event, // Pass the current event
                              date: format(selectedDate, "yyyy-MM-dd"),
                            });

                            if (response.data.success) {
                              setAvailableSlots(response.data.data); // Set available slots
                            }
                          } catch (error) {
                            console.error(
                              "Error fetching available slots:",
                              error.message
                            );
                            showToast(
                              "error",
                              error.response?.data?.message ||
                                "An unexpected error occurred"
                            );
                          } finally {
                            setSlotsLoading(false); // Stop loading indicator
                          }
                        }}
                        className="w-full px-4 py-2 flex items-center justify-between hover:bg-gray-50 focus:outline-none focus:bg-gray-50 text-left"
                      >
                        <span className="font-medium">{event.summary}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <br></br>
            {/* Event Details and Calendar Selection */}
            {selectedEvent && (
              <div className="w-full flex flex-col items-center justify-center">
                {selectedEvent?.contractor &&
                  Object.keys(selectedEvent.contractor).length > 0 && (
                    <div className="w-full px-4 py-2 bg-lightgray mt-2">
                      <div className="flex items-center gap-2">
                        <div className="w-12 h-12 shrink-0">
                          <img
                            src={selectedEvent.contractor?.picture}
                            alt="contractor"
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="flex flex-col items-start">
                          <span>
                            <strong>Name:</strong>{" "}
                            {selectedEvent.contractor?.name}
                          </span>
                          <span>
                            <strong>Email:</strong>{" "}
                            {selectedEvent.contractor?.email}
                          </span>
                          <span>
                            <strong>Mobile:</strong>{" "}
                            {selectedEvent.contractor?.mobile}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="text-sm my-2">
                  Duration:{" "}
                  {selectedEvent.duration
                    ? `${selectedEvent.duration} mins`
                    : selectedEvent.end
                    ? differenceInMinutes(
                        parseISO(selectedEvent.end),
                        parseISO(selectedEvent.start)
                      ) + " mins"
                    : "N/A"}
                </div>

                <Calendar
                  tileDisabled={tileDisabled}
                  prev2Label={null}
                  next2Label={null}
                  value={selectedDate}
                  onChange={handleDateChange}
                />

                <div className="w-full flex flex-col justify-end mt-4">
                  <p className="font-semibold mb-2 text-center">
                    Next Available Slots for{" "}
                    {format(selectedDate, "dd-MM-yyyy")}
                  </p>
                  <div className="flex flex-col items-center">
                    {slotsLoading ? (
                      <i className="fa-solid fa-spinner animate-spin"></i>
                    ) : (
                      <>
                        {availableSlots && availableSlots.length > 0 ? (
                          <>
                            <div className="w-full grid grid-cols-4 gap-2">
                              {availableSlots.map((slot, index) => (
                                <button
                                  key={index}
                                  className={`p-2 rounded border text-center text-xs cursor-pointer transition ${
                                    startTime === formatToAmPm(slot.start)
                                      ? "bg-black text-white"
                                      : "bg-white border-mediumgray hover:bg-lightgray"
                                  }`}
                                  onClick={() => {
                                    handleTimeSelect(formatToAmPm(slot.start));
                                    setIsModified(true);
                                  }}
                                >
                                  {formatToAmPm(slot.start)}
                                </button>
                              ))}
                            </div>
                            <p className="w-full mt-2">
                              Selected Time: {startTime} - {endTime}
                            </p>
                          </>
                        ) : (
                          <span className="text-sm text-darkergray">
                            No slots available
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  <br></br>
                  <div>
                    <label className="form-label">Additional Notes</label>
                    <textarea
                      className="form-textarea border border-mediumgray"
                      rows={3}
                      placeholder="Add notes here"
                      value={additionalNotes}
                      onChange={(e) => setAdditionalNotes(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="flex justify-end">
                    <Button
                      className="black-button my-4 w-fit"
                      onClick={saveManualEvent}
                      loading={addLoading}
                      disabled={addLoading}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EventsCalendarComplete;
