import { useState, useEffect } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import axios from "utils/axios";
import { HiChevronDown } from "react-icons/hi2";
import { formatCurrency } from "utils/helperFunctions";
import { format, parseISO } from "date-fns";
import Button from "components/ui/Button";
import { showToast } from "components/ui/toast";

const EventsCalendar = ({
  prepareMarketing,
  conclusionDate,
  saleProcess,
  marketing,
}) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Correct the URL and add the query params properly
        const response = await axios.post(`/events/calEventst`, {
          prepareMarketing,
          conclusionDate,
          marketing,
          saleProcess,
        });

        if (response.data.success) {
          const fetchedEvents = response.data.data;

          // Sort events by start time
          fetchedEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

          setEvents(fetchedEvents);
        }
      } catch (error) {
        console.error("Error fetching events", error);
      }
    };

    fetchEvents();
  }, [prepareMarketing, conclusionDate, marketing, saleProcess]); // Correct dependencies

  // Helper function to format time
  const formatTimeRange = (start, end) => {
    const startTime = format(parseISO(start), "hh:mm a");
    const endTime = format(parseISO(end), "hh:mm a");
    return `${startTime} - ${endTime}`;
  };

  // Helper function to group events by day
  const groupedEvents = events.reduce((acc, event) => {
    const eventDate = format(parseISO(event.start), "yyyy-MM-dd");
    if (!acc[eventDate]) {
      acc[eventDate] = [];
    }
    acc[eventDate].push(event);
    return acc;
  }, {});

  const book = async (event) => {
    try {
      const response = await axios.post(`/events/eventBooking`, {
        event,
      });
      if (response.data.success) {
        showToast("success", "Booking created");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      showToast("error", errorMessage);
    }
  };

  if (!events.length) {
    return <p>Agents will add events manually</p>;
  }
  return (
    <div className="space-y-4">
      {Object.keys(groupedEvents).map((date, index) => {
        const dayName = format(parseISO(date), "EEE");
        const dayNumber = format(parseISO(date), "d");

        return (
          <div key={index} className="flex gap-4">
            <div className="w-16 flex-shrink-0 bg-black text-white px-3 py-2 rounded h-min">
              <div className="text-2xl font-bold">{dayNumber}</div>
              <div className="text-sm">{dayName}</div>
            </div>

            <div className="flex-grow space-y-2">
              {groupedEvents[date].map((event, eventIndex) => (
                <div key={eventIndex} className="bg-gray-100 p-3 rounded">
                  <div className="text-sm mb-1 flex justify-between">
                    <span>
                      {event.summary.includes("Notify")
                        ? null
                        : event.end
                        ? formatTimeRange(event.start, event.end)
                        : new Date(event.start).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                    </span>
                  </div>
                  {event.summary && (
                    <div className="text-sm mb-1">{event.summary}</div>
                  )}
                  {(event.summary.toLowerCase().includes("photo") ||
                    event.summary.toLowerCase().includes("video") ||
                    event.summary.toLowerCase().includes("auction") ||
                    event.summary.toLowerCase().includes("floor plan")) && (
                    <>
                      {/* Show contractor details if contractor exists */}
                      {event.contractor!=="no" && (
                        <div className="text-sm flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <img
                              src={event.contractor.picture}
                              alt="contractor"
                              className="w-8 h-8 rounded-full object-cover"
                            />
                            <span>{event.contractor.name}</span>
                          </div>
                          <span>{event.contractor.mobile}</span>
                        </div>
                      )}

                      {event.contractor === "no" && (
                        <p className="italic text-darkergray my-1 text-xs">
                          No contractor available
                        </p>
                      )}

                      {/* Show Book button if contractor exists */}
                      {event.contractor && (
                        <Button
                          className="black-button"
                          onClick={() => book(event)}
                        >
                          Book
                        </Button>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const AuthTest = () => {
  const existingData = {
    categories: [
      {
        _id: "670a86b35816964df31d8566",
        category: "Mailcards & Brochures",
        items: [
          {
            name: "Melo A6 Mailcard Double Sided Colour - Landscape - 300gsm Uncoated Bright White",
            price: 388,
            _id: "670a86b35816964df31d8567",
            isChecked: false,
          },
          {
            name: "Melo 16 Page A4 Booklet - 300gsm Uncoated Bright White Landscape (Short Edge Bound)",
            price: 358,
            _id: "670a86b35816964df31d8568",
            isChecked: false,
          },
          {
            name: "Melo 8 Page A5 Brochure - 300gsm Uncoated Bright White Landscape (Short Edge Bound)",
            price: 106,
            _id: "670a86b35816964df31d8569",
            isChecked: false,
          },
        ],
        __v: 0,
      },
      {
        _id: "670a86b35816964df31d856a",
        category: "Photos",
        items: [
          {
            name: "Melo Photography - Photography 10 Images",
            price: 430,
            _id: "670a86b35816964df31d856b",
            isChecked: false,
          },
          {
            name: "Melo Photography - Photography 20 Images",
            price: 730,
            _id: "670a86b35816964df31d856c",
            isChecked: false,
          },
          {
            name: "Melo Photography - Photography 7 Images",
            price: 340,
            _id: "670a86b35816964df31d856d",
            isChecked: false,
          },
          {
            name: "Melo Photography - Photography 5 Images",
            price: 280,
            _id: "670a86b35816964df31d856e",
            isChecked: false,
          },
          {
            name: "Melo Photography - Dusk Photography",
            price: 160,
            _id: "670a86b35816964df31d856f",
            isChecked: false,
          },
          {
            name: "Melo Photography - Drone Shots",
            price: 250,
            _id: "670a86b35816964df31d8570",
            isChecked: false,
          },
          {
            name: "Melo Photography - Virtual Furniture 2 Images",
            price: 154,
            _id: "670a86b35816964df31d8571",
            isChecked: false,
          },
          {
            name: "Melo Photography - Virtual Furniture 4 Images",
            price: 308,
            _id: "670a86b35816964df31d8572",
            isChecked: false,
          },
        ],
        __v: 0,
      },
      {
        _id: "670a86b35816964df31d8573",
        category: "Floorplans",
        items: [
          {
            name: "Melo - Large Floor Plan",
            price: 319,
            _id: "670a86b35816964df31d8574",
            isChecked: false,
          },
          {
            name: "Melo - Medium Floor Plan",
            price: 242,
            _id: "670a86b35816964df31d8575",
            isChecked: false,
          },
          {
            name: "Melo - Small Floor Plan",
            price: 193,
            _id: "670a86b35816964df31d8576",
            isChecked: false,
          },
          {
            name: "Melo - Redraw Large Floorplan",
            price: 198,
            _id: "670a86b35816964df31d8577",
            isChecked: false,
          },
          {
            name: "Melo - Redraw Medium Floorplan",
            price: 143,
            _id: "670a86b35816964df31d8578",
            isChecked: false,
          },
          {
            name: "Melo - Redraw Small Floorplan",
            price: 99,
            _id: "670a86b35816964df31d8579",
            isChecked: false,
          },
        ],
        __v: 0,
      },
      {
        _id: "670a86b35816964df31d857a",
        category: "Video",
        items: [
          {
            name: "Melo - Property Video",
            price: 1150,
            _id: "670a86b35816964df31d857b",
            isChecked: true,
          },
          {
            name: "Melo - Storytelling Videos",
            price: 2200,
            _id: "670a86b35816964df31d857c",
            isChecked: false,
          },
        ],
        __v: 0,
      },
      {
        _id: "670a86b35816964df31d857d",
        category: "Copy & social media",
        items: [
          {
            name: "Melo - Property Copywriting",
            price: 140,
            _id: "670a86b35816964df31d857e",
            isChecked: false,
          },
          {
            name: "Melo - Social Media Advertising",
            price: 300,
            _id: "670a86b35816964df31d857f",
            isChecked: true,
          },
        ],
        __v: 0,
      },
      {
        _id: "670a86b35816964df31d8580",
        category: "Signboards",
        items: [
          {
            name: "Melo - 8x4 Satin Laminated Edge Wrap Signboard",
            price: 375,
            _id: "670a86b35816964df31d8581",
            isChecked: false,
          },
          {
            name: "Melo - 8x6 Satin Laminated Edge Wrap Signboard",
            price: 780,
            _id: "670a86b35816964df31d8582",
            isChecked: false,
          },
          {
            name: "Melo - 8x4 Solar Illuminated Signboard",
            price: 830,
            _id: "670a86b35816964df31d8583",
            isChecked: false,
          },
          {
            name: "Melo - 8x6 Solar Illuminated Signboard",
            price: 1112,
            _id: "670a86b35816964df31d8584",
            isChecked: false,
          },
        ],
        __v: 0,
      },
      {
        _id: "670a86b35816964df31d8585",
        category: "Internet Portals",
        items: [
          {
            name: "Realestate.com.au",
            price: 0,
            _id: "670a86b35816964df31d8586",
            isChecked: true,
          },
          {
            name: "Domain.com.au",
            price: 0,
            _id: "670a86b35816964df31d8587",
            isChecked: true,
          },
          {
            name: "Campaign Fee",
            price: 40,
            _id: "670a86b35816964df31d8588",
            isChecked: false,
          },
        ],
        __v: 0,
      },
      {
        _id: "670a86b35816964df31d8589",
        category: "Auctioneer",
        items: [
          {
            name: "Narz Sayed - Auctioneer",
            price: 550,
            _id: "670a86b35816964df31d858a",
            isChecked: false,
          },
          {
            name: "Andrew Cooley Auctioneer",
            price: 995,
            _id: "670a86b35816964df31d858b",
            isChecked: false,
          },
        ],
        __v: 0,
      },
    ],
    agentContribution: {
      amount: "$0",
      isChecked: false,
    },
    total: 3790,
  };

  const [categories, setCategories] = useState(existingData?.categories);
  const [prepareMarketing, setPrepareMarketing] = useState("ASAP");
  const [conclusionDate, setConclusionDate] = useState("1 week");
  const [saleProcess, setSaleProcess] = useState("Auction"); // Add this if you need to manage sale process
  const [agentContribution, setAgentContribution] = useState(
    existingData?.agentContribution?.amount || "$0"
  );
  const [isAgentContributionChecked, setIsAgentContributionChecked] = useState(
    existingData?.agentContribution?.isChecked || false
  );

  // Handle Prepare Marketing change
  const handlePrepareMarketingChange = (e) => {
    const value = e.target.value;
    setPrepareMarketing(value);

    // Automatically set conclusion date to "Off market" if prepareMarketing is "Off market"
    if (value === "Off market") {
      setConclusionDate("Off market");
    }
  };

  // Handle Conclusion Date change (only allow change if not "Off market")
  const handleConclusionDateChange = (e) => {
    // Only allow changing conclusionDate if prepareMarketing is not "Off market"
    if (prepareMarketing !== "Off market") {
      setConclusionDate(e.target.value);
    }
  };

  const handleSaleProcessChange = (e) => {
    const value = e.target.value;
    setSaleProcess(value);
  };

  // Handle checkbox change
  const handleCheckboxChange = (id) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => ({
        ...category,
        items: category.items.map((item) =>
          item._id === id ? { ...item, isChecked: !item.isChecked } : item
        ),
      }))
    );
  };

  const handlePriceChange = (id, e) => {
    const value = e.target.value;
    const numericValue = value.split("$")[1]?.replace(/,/g, "") || "0";
    const parsedValue = Math.max(0, parseFloat(numericValue) || 0);

    setCategories((prevCategories) =>
      prevCategories.map((category) => ({
        ...category,
        items: category.items.map((item) =>
          item._id === id
            ? { ...item, price: formatCurrency(parsedValue) }
            : item
        ),
      }))
    );
  };
  const handleAgentContributionChange = (e) => {
    const value = e.target.value;
    // Use split to remove the dollar sign and extract numeric part
    const numericValue = value.split("$")[1]?.replace(/,/g, "") || "0"; // Get numeric part after the dollar sign
    const parsedValue = Math.max(0, parseFloat(numericValue) || 0); // Parse the number and ensure it's non-negative
    setAgentContribution(formatCurrency(parsedValue));
  };

  const handleAgentContributionCheckboxChange = () => {
    setIsAgentContributionChecked(!isAgentContributionChecked);
  };

  // Calculate total price for selected items
  const totalPrice = categories.reduce(
    (total, category) =>
      total +
      category.items.reduce((catTotal, item) => {
        // Safely handle item.price
        const priceString =
          typeof item.price === "string"
            ? item.price
            : item.price?.toString() || "0"; // Convert to string if it's a number
        const numericPrice =
          parseFloat(priceString.replace(/^\$/, "").replace(/,/g, "")) || 0; // Ensure price is numeric
        return item.isChecked ? catTotal + numericPrice : catTotal; // Add to total if checked
      }, 0),
    0
  );
  // Apply agent contribution if applicable
  const finalTotal = Math.max(
    0,
    isAgentContributionChecked
      ? totalPrice -
          parseFloat(agentContribution.replace(/^\$/, "").replace(/,/g, ""))
      : totalPrice // Extract numeric value from formatted agent contribution
  );

  return (
    <div className="w-full max-w-4xl mx-auto flex flex-col items-center justify-center px-4 py-4">
      <div className="w-full col-span-12 grid grid-cols-12 gap-3">
        <div className="col-span-4 flex flex-col justify-between">
          <label className="form-label">Sale Process</label>
          <div className="flex flex-col mt-auto">
            <select
              className="form-select border"
              value={saleProcess}
              onChange={handleSaleProcessChange} // Update the prepareMarketing state
            >
              {["Private treaty", "Auction"].map((option, idx) => (
                <option key={idx} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-span-4 flex flex-col justify-between">
          <label className="form-label">Prepare Marketing</label>
          <div className="flex flex-col mt-auto">
            <select
              className="form-select border"
              value={prepareMarketing}
              onChange={handlePrepareMarketingChange} // Update the prepareMarketing state
            >
              {[
                "Off market",
                "ASAP",
                "1 week",
                "2 weeks",
                "3 weeks",
                "4 weeks",
                "5 weeks",
                "6 weeks",
                "7 weeks",
                "8 weeks",
              ].map((option, idx) => (
                <option key={idx} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-span-4 flex flex-col justify-between">
          <label className="form-label">
            {saleProcess === "Auction" ? "Auction Date" : "Closing Date"}
          </label>
          <div className="flex flex-col mt-auto">
            <select
              className="form-select border"
              value={conclusionDate}
              onChange={handleConclusionDateChange}
              disabled={prepareMarketing === "Off market"} // Disable the dropdown if prepareMarketing is "Off market"
            >
              {["1 week", "2.5 weeks", "3 weeks", "4 weeks"].map(
                (option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
      </div>

      <table className="border-lightgray text-xs">
        <tbody>
          {categories.map((category, categoryIndex) => (
            <>
              <Disclosure>
                {({ open }) => (
                  <>
                    <DisclosureButton className="w-full cursor-pointer bg-lightgray px-3 py-2 my-1 flex items-center justify-between">
                      <p className="">{category.category}</p>
                      <HiChevronDown
                        className={`transition-transform duration-300 ${
                          open ? "rotate-180" : ""
                        }`}
                      />
                    </DisclosureButton>

                    <DisclosurePanel className="origin-top transition duration-200 ease-out">
                      {category.items.map((item, index) => (
                        <tr
                          key={item._id}
                          className="hover:bg-lightgray border-b border-mediumgray flex items-center justify-between cursor-pointer"
                          onClick={() => handleCheckboxChange(item._id)} // Toggle on row click
                        >
                          <td className="py-2 px-3 text-xs">{item.name}</td>
                          <div className="flex items-center">
                            <td className="py-2 px-3 text-xs">
                              {category.category === "Internet Portals" &&
                              index < 2 ? (
                                <input
                                  type="text"
                                  value={item.price}
                                  placeholder="$0"
                                  onChange={(e) =>
                                    handlePriceChange(item._id, e)
                                  }
                                  className="form-input border border-mediumgray text-right"
                                />
                              ) : (
                                formatCurrency(item.price)
                              )}
                            </td>
                            <td className="py-2 px-3">
                              <input
                                type="checkbox"
                                checked={item.isChecked}
                                onChange={() => handleCheckboxChange(item._id)} // Toggle when clicked
                                onClick={(e) => e.stopPropagation()} // Prevent row click event
                              />
                            </td>
                          </div>
                        </tr>
                      ))}
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            </>
          ))}

          <tr
            className="w-full hover:bg-lightgray border-b border-mediumgray flex items-center justify-between cursor-pointer"
            onClick={handleAgentContributionCheckboxChange} // Toggle on row click
          >
            <td className="py-2 px-3 text-xs w-[200px]">Agent Contribution</td>
            <div className="flex items-center">
              <td className="py-2 px-3 text-xs">
                <input
                  type="text"
                  className="form-input border border-mediumgray text-right"
                  placeholder="$0"
                  value={agentContribution}
                  onChange={handleAgentContributionChange}
                />
              </td>
              <td className="py-2 px-3">
                <input
                  type="checkbox"
                  checked={isAgentContributionChecked}
                  onChange={handleAgentContributionCheckboxChange} // Toggle when clicked
                  onClick={(e) => e.stopPropagation()} // Prevent row click event
                />
              </td>
            </div>
          </tr>

          <tr className="hover:bg-lightgray font-semibold flex items-center justify-between">
            <td className="py-2 px-3 text-center text-xs">TOTAL</td>
            <td className="py-2 px-3 text-center text-xs">
              {formatCurrency(finalTotal)}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="w-full">
        <EventsCalendar
          prepareMarketing={prepareMarketing}
          conclusionDate={conclusionDate}
          marketing={{
            categories: categories,
            agentContribution: {
              amount: "$0",
              isChecked: false,
            },
            total: 3790,
          }}
          saleProcess={saleProcess}
        />
      </div>
    </div>
  );
};

export default AuthTest;
