import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import Button from "components/ui/Button";
import Tooltip from "components/ui/Tooltip";
import React, { useCallback, useEffect,useState } from "react";
import { useForm, Controller, useWatch, get } from "react-hook-form";
import { HiChevronDown } from "react-icons/hi2";
import { IoMdRefresh } from "react-icons/io";
import { MdClearAll } from "react-icons/md";
import axios from "utils/axios";
import { BiDollar } from "react-icons/bi";
import Modal from "components/ui/Modal";
import ViewReport from "components/report/ViewReport";
import { Link, useSearchParams } from "react-router-dom";
export default function FiveStepProcess({ address, fiveStepProcess,conclusion,property,authSaleProcess,reserveMeetingReport,isLoading }) {

  const defaultData=[
    {
      name: "OFF MARKET",
      pricePoint: "1.8-1.9m",
      enquiries: "82",
      inspections1: "0",
      priceAssessment: "Top end of the range",
      inspections2: "0",
      engagement: "",
      finalise: "",
      keyMeeting: "KEY MEETING: LISTING APPOINTMENT",
    },
    {
      name: "WEEK 1",
      pricePoint: "1.6-1.7m",
      enquiries: "50",
      inspections1: "15",
      priceAssessment: "Top end of the range",
      inspections2: "15",
      engagement: "3",
      finalise: "",
      keyMeeting: "KEY MEETING: LAUNCH TO MARKET MEETING",
    },
    {
      name: "WEEK 2",
      pricePoint: "1.7-1.8m",
      enquiries: "26",
      inspections1: "5",
      priceAssessment: "Top end of the range",
      inspections2: "20",
      engagement: "2",
      finalise: "1.9m",
      keyMeeting: "KEY MEETING: MID CAMPAIGN MEETING",
    },
    {
      name: "WEEK 3",
      pricePoint: "",
      enquiries: "",
      inspections1: "",
      priceAssessment: "Top end of the range",
      inspections2: "",
      engagement: "",
      finalise: "",
      keyMeeting: "KEY MEETING: PRE CLOSING DATE",
    },
    {
      name: "WEEK 4",
      pricePoint: "",
      enquiries: "",
      inspections1: "",
      priceAssessment: "Top end of the range",
      inspections2: "",
      engagement: "",
      finalise: "",
      keyMeeting: "KEY MEETING: POST CLOSING DATE",
    },
  ];
  const clearedData=[
    {
      name: "OFF MARKET",
      pricePoint: "",
      enquiries: "0",
      inspections1: "0",
      priceAssessment: "Top end of the range",
      inspections2: "0",
      engagement: "0",
      finalise: "",
      keyMeeting: "KEY MEETING: LISTING APPOINTMENT",
    },
    {
      name: "WEEK 1",
      pricePoint: "",
      enquiries: "0",
      inspections1: "0",
      priceAssessment: "Top end of the range",
      inspections2: "0",
      engagement: "0",
      finalise: "",
      keyMeeting: "KEY MEETING: LAUNCH TO MARKET MEETING",
    },
    {
      name: "WEEK 2",
      pricePoint: "",
      enquiries: "0",
      inspections1: "0",
      priceAssessment: "Top end of the range",
      inspections2: "0",
      engagement: "0",
      finalise: "",
      keyMeeting: "KEY MEETING: MID CAMPAIGN MEETING",
    },
    {
      name: "WEEK 3",
      pricePoint: "",
      enquiries: "",
      inspections1: "",
      priceAssessment: "Top end of the range",
      inspections2: "",
      engagement: "",
      finalise: "",
      keyMeeting: "KEY MEETING: PRE CLOSING DATE",
    },
    {
      name: "WEEK 4",
      pricePoint: "",
      enquiries: "",
      inspections1: "",
      priceAssessment: "Top end of the range",
      inspections2: "",
      engagement: "",
      finalise: "",
      keyMeeting: "KEY MEETING: POST CLOSING DATE",
    },
  ];
  const [initialData, setInitialData] = useState(fiveStepProcess || defaultData);
  const [reportWeeks, setReportWeeks] = useState([]);
  const [loadingIndex,setLoadingIndex]=useState(null)
  const [openModal, setOpenModal] = useState(false);
  const [selectedUrl,setSelectedUrl]=useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [editIndex, setEditIndex] = useState(null)
  const [shareIndex,setShareIndex]=useState(null)
  let [searchParams, setSearchParams] = useSearchParams();

  
  // we have to check if the url has query param tab and if it includes string 'price'
  const isPriceTab = searchParams.get('tab')?.includes('price') || false;
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    clearErrors,
    control,
    getValues,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    
    shouldFocusError: false,
    defaultValues: {
      weeks: initialData?.map((week, index) => ({
        ...week,
        keyMeeting: initialData[index]?.keyMeeting,
      })),
    },
  });

  const weeks = useWatch({ control, name: "weeks" });

  // API call for form submission
  const onSubmit = async (data) => {
    try {
      await axios.put("/userProperty", {
        address,
        fiveStepProcess: data.weeks,
      });
    } catch (error) {
      console.log(error.message);
    }
  };
  const chatgptAPICall = async (index,auction=false) => {
  // collect all the data from the form till the current index
    setLoadingIndex(index)
    let input;
    if(index===5){
      input = weeks
    }else{input = weeks.slice(0, index + 1)}
    
    
    try {
      let response;
      if(index===5){
        response = await axios.post(`/userProperty/generate-conclusion-report`, {
          systemPrompt: auction?"RESERVE_MEETING_REPORT":"CONCLUSION_REPORT",
          userMessage: input,
          address:address,
          auction:auction
  
        });
      }else{
        response = await axios.post(`/userProperty/generate-report`, {
          systemPrompt: auction?"FIVE_STEP_PROCESS_AUCTION_REPORT":"FIVE_STEP_PROCESS_REPORT",
          userMessage: input,
          address:address,
          auction:auction
  
        });
      }

      if (response.data.success) {
        // replace if exists or add new report
        const newReportWeeks = reportWeeks.filter(
          (report) => report.index !== index
        );
        setReportWeeks([
          ...newReportWeeks,
          {
            index,
            data: response.data.data,
          },
        ]);
        setLoadingIndex(null)
        setSelectedUrl(response?.data?.data?.url)
        setOpenModal(true);
        setEditIndex(index)
        return response.data.data;
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
        setLoadingIndex(null)

    }
  };
  
  // Reset function for resetting the form and making the API call
  const handleReset = async (e) => {
    e.preventDefault();
    reset({ weeks: defaultData }); // Reset form to default data
    setInitialData(defaultData); // Update initial data

    // Call API immediately with reset data
    try {
      await axios.put("/userProperty", {
        address,
        fiveStepProcess: defaultData, // Send default data to the API
      });
    } catch (error) {
      console.log(error.message);
    }
  };
  const sharePDF = async (index) => {
    try {
      // Fetch the PDF as a Blob
      let response;
      if(index===5){
        if(authSaleProcess?.includes("Auction")){response = await fetch(
          reportWeeks.find((report) => report.index === index)?.data?.url || reserveMeetingReport?.url
        )}else{response = await fetch(
          reportWeeks.find((report) => report.index === index)?.data?.url || conclusion?.url
        )}
        
      }
      else{
        if((authSaleProcess?.includes("Auction"))){
          response = await fetch(
            reportWeeks.find((report) => report.index === index)?.data?.url || fiveStepProcess[index]?.auction?.url
          );
        }else{
          response = await fetch(
          reportWeeks.find((report) => report.index === index)?.data?.url || fiveStepProcess[index]?.url
        );
        }
        
      }
      const blob = await response.blob();
      const file = new File([blob], 'report.pdf', { type: 'application/pdf' });
  
      // Check if sharing files is supported
      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({
          files: [file],
          title: 'PDF Report',
          text: 'Here is the report.',
        });
        console.log('PDF file shared successfully!');
        setShareIndex(null)
      } else {
        // Fallback to sharing the link
        if(index===5){
          if(authSaleProcess?.includes("Auction")){await navigator.clipboard.writeText(
            reportWeeks.find((report) => report.index === index)?.data?.url || reserveMeetingReport?.url
          )}else{
            await navigator.clipboard.writeText(
              reportWeeks.find((report) => report.index === index)?.data?.url || conclusion?.url
            );
          }
        }else{
          if((authSaleProcess?.includes("Auction"))){
            await navigator.clipboard.writeText(
              reportWeeks.find((report) => report.index === index)?.data?.url || fiveStepProcess[index]?.auction?.url
            );
          }else{
            await navigator.clipboard.writeText(
            reportWeeks.find((report) => report.index === index)?.data?.url || fiveStepProcess[index]?.url
          );
          }
        }
        setShareIndex(null)

  
      }
    } catch (error) {
      setShareIndex(null)

      console.error('Error sharing the PDF:', error);
    }
  }
  const calculatePercentage = (value1, value2) => {
    const num1 = parseFloat(value1);
    const num2 = parseFloat(value2);
    if (isNaN(num1) || isNaN(num2) || num2 === 0) return 0;
    return Math.round((num1 / num2) * 100);
  };

  const handleEmptyAllFields=async (e)=>{
    e.preventDefault();
    reset({ weeks: clearedData }); // Reset form to default data
    setInitialData(clearedData); // Update initial dat
    // Call API immediately with reset data
    try {
      await axios.put("/userProperty", {
        address,
        fiveStepProcess: clearedData, // Send default data to the API
      });
    } catch (error) {
      console.log(error.message);
    }
  }
  const getMarkdownContent = () => {
    if (editIndex === 5) {
      return authSaleProcess?.includes("Auction")
        ? reportWeeks.find((report) => report.index === editIndex)?.data?.gptResponse || reserveMeetingReport?.gptResponse || ''
        : reportWeeks.find((report) => report.index === editIndex)?.data?.gptResponse || conclusion?.gptResponse || '';
    } else {
      return authSaleProcess?.includes("Auction")
        ? reportWeeks.find((report) => report.index === editIndex)?.data?.gptResponse || fiveStepProcess[editIndex]?.auction?.gptResponse || ''
        : reportWeeks.find((report) => report.index === editIndex)?.data?.gptResponse || fiveStepProcess[editIndex]?.gptResponse || '';
    }
  };
  
  return (
    <>
    <Modal
      isOpen={openModal}
      onClose={() => {
        setSelectedUrl(null)
        setOpenModal(false)
        setEditIndex(null)
        setIsEditing(false)
        
      }}
      className="max-w-3xl"
    >
    <ViewReport 
      pdfUrl={selectedUrl}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      markdown={getMarkdownContent()}
      weeks={weeks}
      address = {address}
      reportWeeks={reportWeeks}
      setReportWeeks  = {setReportWeeks}
      setSelectedUrl={setSelectedUrl}
      editIndex={editIndex}
      setEditIndex={setEditIndex}
      setIsOpen={setOpenModal}
      openModal={openModal}
      auction={authSaleProcess?.includes("Auction")}

    />
    </Modal>
    <div className="container mx-auto px-0 py-4">
      <form>
      {
        !isPriceTab ? (<div className="flex justify-end mb-4 space-x-2">
          <Button
      className="black-button"
      onClick={(e) => {
        e.preventDefault();
        if(authSaleProcess?.includes("Auction")){
          chatgptAPICall(5,true);
          }
        else{
          chatgptAPICall(5);
        }
      }}
      loading={loadingIndex === 5 || isLoading}
      disabled={loadingIndex === 5 || isLoading}
    >
      {loadingIndex === 5 ? 'Generating' : authSaleProcess?.includes("Auction")?"Reserve Meeting Report":"Generate Conclusion Report"}
    </Button>
        
    {((reportWeeks.some((report) => report.index === 5) || (
      authSaleProcess?.includes("Auction")?reserveMeetingReport?.url:conclusion?.url
    )) && loadingIndex !== 5) ? (
      <div className="relative inline-block text-left">
        <div>
          <Button
            className="black-button"
            onClick={() => document.getElementById(`dropdown-${5}`).classList.toggle('hidden')}
          >
            Actions
            
          </Button>
        </div>
        <div id={`dropdown-${5}`} className="hidden origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              onClick={(e) => {
                e.preventDefault();
                if(authSaleProcess?.includes("Auction")){
                  window.open(
                    reportWeeks.find((report) => report.index === 5)?.data?.url || reserveMeetingReport?.url
                  )
                }else{
                  window.open(
                  reportWeeks.find((report) => report.index === 5)?.data?.url || conclusion?.url
                );
                }
              }}
            >
              Download
            </button>
            <button
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              onClick={(e) => {
                e.preventDefault();
                setShareIndex(5);
                sharePDF(5);
              }}
              disabled={shareIndex === 5}
            >
              Share
            </button>
            <button
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              onClick={(e) => {
                e.preventDefault();
                setSelectedUrl(
                  authSaleProcess?.includes("Auction")
                    ? reportWeeks.find((report) => report.index === 5)?.data?.url || reserveMeetingReport?.url
                    : reportWeeks.find((report) => report.index === 5)?.data?.url || conclusion?.url
                )
                setOpenModal(true);
                setEditIndex(5);
              }}
            >
              View
            </button>
            <button
            className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            onClick={handleReset} 
          >
            Reset
          </button>
          <button
            className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            onClick={handleEmptyAllFields} 
          >
            Clear
          </button>
          </div>
        </div>
      </div>
    ):(<>
      <Button
            className="black-button flex items-center gap-1"
            onClick={handleReset} 
          >
            <IoMdRefresh className="text-white" />
            Reset
          </Button>
          <Button
            className="black-button flex items-center gap-1"
            onClick={handleEmptyAllFields} 
          >
            <MdClearAll  className="text-white" />
            Clear
          </Button>
    </>)}

          
        </div>):(
          <div className="flex justify-end mb-4 space-x-2">
          <Button
            className="black-button flex items-center gap-1"
            onClick={handleReset} 
          >
            <IoMdRefresh className="text-white" />
            Reset
          </Button>
          <Button
            className="black-button flex items-center gap-1"
            onClick={handleEmptyAllFields} 
          >
            <MdClearAll  className="text-white" />
            Clear
          </Button>
          </div>
        )
      }
        
        <div className="space-y-4">
          {
            weeks?.map((week, index) => {
              const isRequired = index <0;
              return (
                <Disclosure key={index}>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="rounded-lg w-full cursor-pointer bg-lightgray p-4 my-1 flex items-center justify-between text-black font-semibold">
                        <div className="flex items-center space-x-2">
                          <p className="">{week.name}</p>
                          <Tooltip
                            className="w-[200px]"
                            text={<i className="fa fa-info-circle text-xs"></i>}
                            tooltip={week.keyMeeting}
                          />
                        </div>
                        <HiChevronDown
                          className={`transition-transform duration-300 ${
                            open ? "rotate-180" : ""
                          }`}
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="px-2 pt-2 pb-2 text-sm text-start">
                        <div className="flex flex-col space-y-4">
                          <div>
                            <label className="form-label">Price Point</label>
                            <div className="relative">
  <div className="absolute inset-y-0  flex items-center ps-3 pointer-events-none">
  <BiDollar 
  className='w-4 h-4 text-black font-semibold'
  />
  </div>
  <input
                              type="text"
                              {...register(`weeks.${index}.pricePoint`, {
                                required: isRequired
                                  ? "Price Point is required"
                                  : false,
                              })}
                              onBlur={handleSubmit(onSubmit)}
                              className="mt-1 form-input border border-mediumgray ps-9"
                            />
</div>
                            
                          </div>
                          <div>
                            
                            <div className="flex items-center space-x-2">
                              <div><label className="form-label">
                              {
                                index===0?"Buyers Notified":"Enquiries"
                              }
                            </label><input
                                type="text"
                                {...register(`weeks.${index}.enquiries`, {
                                  required: isRequired
                                    ? "Enquiries are required"
                                    : false,
                                  validate: (value) => {
                                    if (value === "") return true;
                                    if (
                                      watch(`weeks.${index}.inspections1`) ===
                                      ""
                                    )
                                      return true;
                                    if (
                                      parseFloat(value) >=
                                      parseFloat(
                                        watch(`weeks.${index}.inspections1`)
                                      )
                                    ) {
                                      clearErrors(
                                        `weeks.${index}.inspections1`
                                      );

                                      return true;
                                    } else {
                                      return "Inspections must be less than or equal to enquiries";
                                    }
                                  },
                                })}
                                className="mt-1 form-input border border-mediumgray"
                                onBlur={handleSubmit(onSubmit)}
                              /></div>
                              <div><label className="form-label">
                              Inspections
                            </label><input
                                type="text"
                                {...register(`weeks.${index}.inspections1`, {
                                  required: isRequired
                                    ? "Inspections are required"
                                    : false,
                                  validate: (value) => {
                                    if (value === "") return true;
                                    if (
                                      watch(`weeks.${index}.enquiries`) === ""
                                    )
                                      return true;
                                    if (
                                      parseFloat(value) <=
                                      parseFloat(
                                        watch(`weeks.${index}.enquiries`)
                                      )
                                    ) {
                                      clearErrors(`weeks.${index}.enquiries`);
                                      return true;
                                    } else {
                                      return "Inspections must be less than or equal to enquiries";
                                    }
                                  },
                                })}
                                className="mt-1 form-input border border-mediumgray"
                                onBlur={
                                  (e) => {
                                    if(errors?.weeks?.[index]?.inspections1 ||
                                      errors?.weeks?.[index]?.enquiries){
                                      return;
                                    }
                                    if(index===0){
                                      setValue(
                                      `weeks.${index}.inspections2`,
                                      (parseFloat(e.target.value)).toString(),
                                      { shouldValidate: true }
                                    );
                                    }else{
                                      setValue(
                                      `weeks.${index}.inspections2`,
                                      (parseFloat(e.target.value)+parseFloat(
                                        getValues(`weeks.${index-1}.inspections2`) || 0
                                      )).toString(),
                                      { shouldValidate: true }
                                    );
                                    }
                                    if(index<4){
                                      setValue(
                                      `weeks.${index+1}.inspections2`,
                                      (parseFloat(
                                        getValues(`weeks.${index+1}.inspections1`) || 0
                                      ) + parseFloat(getValues(`weeks.${index}.inspections2`)) || 0
                                    ).toString(),
                                    { shouldValidate: true }
                                  );
                                    }
                                    handleSubmit(onSubmit)();
                                    
                                  }
                                }
                              /></div>

                              <span className="ml-2 text-sm font-semibold bg-black text-white px-2 py-1 rounded self-end">
                                {calculatePercentage(
                                  week.inspections1,
                                  week.enquiries
                                ) !== null &&
                                  `${calculatePercentage(
                                    week.inspections1,
                                    week.enquiries
                                  )}%`}
                              </span>
                            </div>
                            {(errors?.weeks?.[index]?.inspections1 ||
                              errors?.weeks?.[index]?.enquiries) && (
                              <span className="text-red-500 text-xs">
                                {errors?.weeks?.[index]?.enquiries
                                  ? errors.weeks[index].enquiries.message
                                  : errors.weeks[index].inspections1.message}
                              </span>
                            )}
                          </div>
                          <div>
                            <label className="form-label">
                              Price Test
                            </label>
                            <div className="relative">
  <div className="absolute inset-y-0  flex items-center ps-3 pointer-events-none">
  <BiDollar 
  className='w-4 h-4 text-black font-semibold'
  />
  </div>
  <input
                              type="text"
                              {...register(`weeks.${index}.priceAssessment`)}
                              className="mt-1 form-input border border-mediumgray ps-9"
                              onBlur={handleSubmit(onSubmit)}
                            />
</div>
                            
                          </div>
                          <div>
                            
                            <div className="flex items-center space-x-2">
                              <div><label className="form-label">
                              Inspections
                            </label><input
                                type="text"
                                {...register(`weeks.${index}.inspections2`, {
                                  required: isRequired
                                    ? "Inspections 2 are required"
                                    : false,
                                  validate: (value) => {
                                    if (value === "") return true;
                                    if (
                                      watch(`weeks.${index}.engagement`) === ""
                                    )
                                      return true;
                                    if (
                                      parseFloat(value) >=
                                      parseFloat(
                                        watch(`weeks.${index}.engagement`)
                                      )
                                    ) {
                                      clearErrors(`weeks.${index}.engagement`);
                                      return true;
                                    } else {
                                      return "Engagement must be less than or equal to Inspections";
                                    }
                                  },
                                })}
                                className="mt-1 form-input border border-mediumgray"
                                onBlur={(e) => {
                                  if(errors?.weeks?.[index]?.engagement ||
                                    errors?.weeks?.[index]?.inspections2){
                                    return;
                                  }
                                  if(index<4){
                                    setValue(
                                    `weeks.${index + 1}.inspections2`,
                                    (
                                      parseFloat(
                                        getValues(`weeks.${index+1}.inspections1`)
                                      ) + parseFloat(e.target.value) || 0
                                    ).toString(),
                                    { shouldValidate: true }
                                  );
                                  }
                                  handleSubmit(onSubmit)();
                                  
                                }}
                              /></div>
                              <div><label className="form-label">
                              Engagement
                            </label><input
                                type="text"
                                {...register(`weeks.${index}.engagement`, {
                                  required: false,
                                  validate: (value) => {
                                    if (value === "") return true;
                                    if (
                                      watch(`weeks.${index}.inspections2`) ===
                                      ""
                                    )
                                      return true;
                                    if (
                                      parseFloat(value) <=
                                      parseFloat(
                                        watch(`weeks.${index}.inspections2`)
                                      )
                                    ) {
                                      clearErrors(
                                        `weeks.${index}.inspections2`
                                      );
                                      return true;
                                    } else {
                                      return "Engagement must be less than or equal to Inspections";
                                    }
                                  },
                                })}
                                className="mt-1 form-input border border-mediumgray"
                                onBlur={handleSubmit(onSubmit)}
                              /></div>
                              <span className="ml-2 text-sm font-semibold bg-black text-white px-2 py-1 rounded self-end">
                                {calculatePercentage(
                                  week.engagement,
                                  week.inspections2
                                ) !== null &&
                                  `${calculatePercentage(
                                    week.engagement,
                                    week.inspections2
                                  )}%`}
                              </span>
                            </div>
                            {(errors?.weeks?.[index]?.engagement ||
                              errors?.weeks?.[index]?.inspections2) && (
                              <span className="text-red-500 text-xs">
                                {errors?.weeks?.[index]?.engagement
                                  ? errors.weeks[index].engagement.message
                                  : errors.weeks[index].inspections2.message}
                              </span>
                            )}
                          </div>
                          <div>
                            <label className="form-label">
                              Finalise
                            </label>
                            <div className="relative">
  <div className="absolute inset-y-0  flex items-center ps-3 pointer-events-none">
  <BiDollar 
  className='w-4 h-4 text-black font-semibold'
  />
  </div>
  <input
                              type="text"
                              {...register(`weeks.${index}.finalise`)}
                              className="mt-1 form-input border border-mediumgray ps-9"
                              onBlur={handleSubmit(onSubmit)}
                            />
</div>
                            
                          </div>
                          {
                            !isPriceTab && (
                              <div className="col-span-2">
  <div className="flex space-x-2 items-center">
  <Button
      className="black-button"
      onClick={(e) => {
        e.preventDefault();
        chatgptAPICall(index,authSaleProcess?.includes("Auction"));
      }}
      disabled={loadingIndex === index || isLoading}
      loading={loadingIndex === index || isLoading}
    >
      {loadingIndex === index ? 'Generating' : 'Generate Report'}
    </Button>
    
    
    {((reportWeeks.some((report) => report.index === index) || (
      authSaleProcess?.includes("Auction")?fiveStepProcess[index]?.auction?.url:fiveStepProcess[index]?.url
    )) && loadingIndex !== index) && (
      <div className="relative inline-block text-left">
        <div>
          <Button
            className="black-button inline-flex items-center"
            onClick={() => document.getElementById(`dropdown-${index}`).classList.toggle('hidden')}
          >
            Actions
            
          </Button>
        </div>
        <div id={`dropdown-${index}`} className="hidden origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              onClick={(e) => {
                e.preventDefault();
                authSaleProcess?.includes("Auction")?window.open(
                  reportWeeks.find((report) => report.index === index)?.data?.url || fiveStepProcess[index]?.auction?.url
                ):window.open(
                  reportWeeks.find((report) => report.index === index)?.data?.url || fiveStepProcess[index]?.url
                );
              }}
            >
              Download
            </button>
            <button
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              onClick={(e) => {
                e.preventDefault();
                setShareIndex(index);
                sharePDF(index);
              }}
              disabled={shareIndex === index}
            >
              Share
            </button>
            <button
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              onClick={(e) => {
                e.preventDefault();
                setSelectedUrl(
                  authSaleProcess?.includes("Auction")
                    ? reportWeeks.find((report) => report.index === index)?.data?.url || fiveStepProcess[index]?.auction?.url
                    : reportWeeks.find((report) => report.index === index)?.data?.url || fiveStepProcess[index]?.url
                );
                setOpenModal(true);
                setEditIndex(index);
              }}
            >
              View
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
</div>
                            )
                          }
                        </div>
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              );
            })
          }
        </div>
      </form>
    </div></>
  );
}
