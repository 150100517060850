import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`


export default function PdfViewer({ pdf="https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/files/FTR32_Agency_agreement.pdf"}) {
  const [numPages, setNumPages] = useState(null)
  
  const [containerWidth, setContainerWidth] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

    const measureContainer = (element) => {
      if (element) {
        const width = element.getBoundingClientRect().width;
        setContainerWidth(width);
      }
    };
  return (
    <div className="flex flex-col h-full w-full">
      <>
          <div 
        ref={measureContainer}
        className="flex-grow overflow-auto py-4 w-full px-0 md:px-2 lg:px-4"
      >
        <div className="max-w-4xl mx-auto">
          <Document
            file={pdf}
            onLoadSuccess={onDocumentLoadSuccess}
            className="flex flex-col items-center"
          >
            {Array.from(new Array(numPages), (el, index) => (
              <div key={`page_${index + 1}`} className="mb-4">
                <Page
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={containerWidth ? Math.min(containerWidth - 32, 896) : undefined}
                  className="shadow-lg"
                />
              </div>
            ))}
          </Document>
        </div>
      </div>
      
      </>
    </div>
  )
}
