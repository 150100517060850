import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "utils/axios";
import Error from "components/ui/Error";
import {
  Autocomplete,
  GoogleMap,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";
import Button from "components/ui/Button";
import { showToast } from "components/ui/toast";
import { IoSend } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { MdOutlineEdit } from "react-icons/md";
import { HiChevronDown } from "react-icons/hi2";
import PageLoader from "components/ui/PageLoader";
import GoogleMaps from "components/GoogleMaps";
import GoogleChart from "components/GoogleChart";
import video from "assets/videos/postlist-video.mp4";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "components/ui/Tooltip";
import arrow from "assets/images/arrow.png";
import GooglePieChart from "components/GooglePieChart";
import { AuthContext } from "context/AuthContext";
import Modal from "components/ui/Modal";
import EditableField from "components/ui/EditableField";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  adaptiveHeight: true,
  arrows: true,
  slidesToShow: 1, // Show only 2 slides if they are large in width
  slidesToScroll: 1,
  variableWidth: true, // Allows variable width for slides
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
};

const RegenerateLogicalPrice = ({
  property,
  regenerateLogicalPrice,
  setRegenerateLogicalPrice,
  soldProperties,
  setLogicalPrice,
  setLogicalReasoning,
}) => {
  const [checkedProperties, setCheckedProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  // Handle checkbox selection
  const handleCheckboxChange = (propertyId) => {
    setCheckedProperties(
      (prevChecked) =>
        prevChecked.includes(propertyId)
          ? prevChecked.filter((id) => id !== propertyId) // Uncheck
          : [...prevChecked, propertyId] // Check
    );
  };

  // Handle regenerate button click
  const handleRegenerate = async () => {
    try {
      setLoading(true);
      const response = await axios.post("/property/regenerateLogicalPrice", {
        property: property,
        checkedProperties: soldProperties.filter(({ property }) =>
          checkedProperties.includes(property._id)
        ),
      });
      if (response.data.success) {
        const { logicalPrice, logicalReasoning } = response.data.data;

        // Update state with new logical price and reasoning
        setRegenerateLogicalPrice(false);
        setLogicalPrice(logicalPrice);
        setLogicalReasoning(logicalReasoning);

        // Call updateQuickSearch API to update the QuickSearch document with new logical price
        // await axios.put(`/quickShare/${property.shareableLink}`, {
        //   property: {
        //     ...property,
        //     logicalPrice: logicalPrice,
        //     logicalReasoning: logicalReasoning,
        //   },
        // });
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={regenerateLogicalPrice}
      onClose={() => setRegenerateLogicalPrice(false)}
      title="Regenerate Logical Price"
      className="max-w-2xl"
    >
      {soldProperties && soldProperties.length > 0 && (
        <div className="w-full overflow-x-auto">
          <table className="w-full border-collapse text-center">
            <thead>
              <tr className="bg-lightgray">
                <th className="py-2 px-3">Select</th>
                <th className="py-2 px-3">Address</th>
                <th className="py-2 px-3">Price</th>
                <th className="py-2 px-3">Score Match</th>
              </tr>
            </thead>
            <tbody>
              {soldProperties.map(({ property, score }, index) => (
                <tr key={property._id} className="border-b">
                  <td className="py-2 px-3">
                    <input
                      type="checkbox"
                      checked={checkedProperties.includes(property._id)}
                      onChange={() => handleCheckboxChange(property._id)}
                    />
                  </td>
                  <td className="py-2 px-3 flex flex-col gap-2">
                    <img
                      src={property?.media?.[0]?.url}
                      alt="property"
                      className="w-auto h-24 sm:h-auto max-h-40"
                    ></img>
                    {property.address}
                  </td>
                  <td className="py-2 px-3">{property.price}</td>
                  <td className="py-2 px-3">{score}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="flex justify-end mt-4">
        <Button
          onClick={handleRegenerate}
          className="black-button"
          loading={loading}
        >
          Regenerate
        </Button>
      </div>
    </Modal>
  );
};

const FinishesSelector = ({
  register,
  errors,
  selectedValue,
  onChange,
  onEdit,
}) => {
  const finishesData = [
    {
      label: "High-end finishes",
      value: "High-end finishes",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/finishesGuide/high-end.png",
    },
    {
      label: "Updated",
      value: "Updated",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/finishesGuide/updated.png",
    },
    {
      label: "Original",
      value: "Original",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/finishesGuide/original.png",
    },
  ];

  useEffect(() => {
    register("finishes", { required: "Finishes selection is required" });
  }, [register]);

  return (
    <div>
      <label className="form-label">Select Finishes</label>
      {selectedValue ? (
        <div className="flex items-center justify-between form-input border border-mediumgray p-2">
          <span>{selectedValue}</span>
          <button type="button" onClick={onEdit} className="text-darkergray">
            <MdOutlineEdit />
          </button>
        </div>
      ) : (
        <div className="flex overflow-x-auto box-scrollbar gap-4">
          {finishesData.map((finish) => (
            <div
              key={finish.value}
              className="cursor-pointer rounded mr-4 transition"
              onClick={() => onChange(finish.value)}
            >
              <img
                src={finish.imgSrc}
                alt={finish.label}
                className="w-full h-auto min-w-[250px] max-h-[256px] object-cover"
              />
              <span className="block text-sm text-center mt-2">
                {finish.label}
              </span>
            </div>
          ))}
        </div>
      )}
      {errors.finishes && (
        <span className="form-error-message">{errors.finishes.message}</span>
      )}
    </div>
  );
};

const WaterViewsSelector = ({
  register,
  errors,
  selectedValue,
  onChange,
  onEdit,
}) => {
  const waterViewsData = [
    {
      label: "Deep waterfront with jetty",
      value: "Deep waterfront with jetty",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/waterViews/deep-waterfront-with-jetty.jpg",
    },
    {
      label: "Water views",
      value: "Water views",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/waterViews/water-views.jpg",
    },
    {
      label: "Tidal waterfront with jetty",
      value: "Tidal waterfront with jetty",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/waterViews/tidal-waterfront-with-jetty.jpg",
    },
    {
      label: "Waterfront reserve",
      value: "Waterfront reserve",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/waterViews/waterfront-reserve.jpg",
    },
  ];

  useEffect(() => {
    register("waterViews", { required: "Waterviews selection is required" });
  }, [register]);

  const handleNoCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      onChange("No");
    } else {
      onChange("");
    }
  };

  return (
    <div>
      <label className="form-label">Select Water Aspect</label>

      {/* Display selected value with Edit button if a selection is made */}
      {selectedValue ? (
        <div className="flex items-center justify-between form-input border border-mediumgray p-2">
          <span>{selectedValue}</span>
          <button type="button" onClick={onEdit} className="text-darkergray">
            <MdOutlineEdit />
          </button>
        </div>
      ) : (
        // Display options when no selection is made
        <>
          {/* "No" Checkbox */}
          <label className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={selectedValue === "No"}
              onChange={handleNoCheckboxChange}
              className="mr-2"
            />
            No
          </label>

          {/* Display options if "No" is not selected */}
          {selectedValue !== "No" && (
            <div className="flex overflow-x-auto box-scrollbar gap-4">
              {waterViewsData.map((waterView) => (
                <div
                  key={waterView.value}
                  className="cursor-pointer rounded mr-4 transition"
                  onClick={() => onChange(waterView.value)}
                >
                  <img
                    src={waterView.imgSrc}
                    alt={waterView.label}
                    className="w-full h-auto min-w-[250px] max-h-[256px] object-cover"
                  />
                  <span className="block text-sm text-center mt-2">
                    {waterView.label}
                  </span>
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {/* Display errors if any */}
      {errors.waterViews && (
        <span className="form-error-message">{errors.waterViews.message}</span>
      )}
    </div>
  );
};

const TopographyDropdown = ({
  selectedValues = [], // Ensure selectedValues is an array
  onToggleOption,
  isOpen,
  toggleDropdown,
  errors,
  register,
  trigger, // add trigger to manually validate
}) => {
  const dropdownRef = useRef(null);

  // useEffect(() => {
  //   register("topography", {
  //     validate: (value) =>
  //       value.length > 0 || "At least one option must be selected",
  //   });
  // }, [register]);

  useEffect(() => {
    register("topography", {
      validate: (value) => {
        if (!Array.isArray(value) || value.length === 0) {
          return "At least one option must be selected"; // Error if no selection
        }
        return true; // No error if validation passes
      },
    });
  }, [register]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);

  const handleToggleOption = (option) => {
    onToggleOption(option);
    trigger("topography"); // Trigger validation after toggling an option
  };

  return (
    <div ref={dropdownRef} className="relative">
      <div
        className="flex items-center justify-between form-select p-2 border border-mediumgray cursor-pointer"
        onClick={() => toggleDropdown(!isOpen)}
      >
        <div className="truncate">
          {selectedValues?.length > 0
            ? selectedValues.join(", ")
            : "Select options..."}
        </div>

        <HiChevronDown />
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full bg-white border border-mediumgray">
          {[
            "High side",
            "Level block",
            "Low side",
            "Irregular block",
            "Unusable land",
          ].map((option) => (
            <div
              key={option}
              className="flex items-center text-xs p-2 cursor-pointer hover:bg-lightgray"
              onClick={() => handleToggleOption(option)}
            >
              <input
                type="checkbox"
                checked={selectedValues?.includes(option) || false}
                readOnly
                className="mr-2"
              />

              {option}
            </div>
          ))}
        </div>
      )}
      {errors.topography && (
        <span className="form-error-message">{errors.topography.message}</span>
      )}
    </div>
  );
};

const PropertyForm = ({ property, onSubmitForm }) => {
  const { address, waterViews } = property || {};
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(property);
  console.log(formData);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    reset,
  } = useForm({
    defaultValues: formData,
    // Ensure that fields are not unregistered when they are removed from the UI
    shouldUnregister: false,
  });

  useEffect(() => {
    setFormData(property);
    reset(property); // Reset form with new property data
  }, [property, reset]);

  const [editMode, setEditMode] = useState(false);
  const [topographyOpen, setTopographyOpen] = useState(false);

  const prevStep = () => setStep(step - 1);

  const selectedFinish = watch("finishes");
  const selectedWaterView = watch("waterViews");
  const selectedTopography = watch("topography");
  const propertyType = watch("propertyType"); // Watch propertyType

  const onSubmit = (data) => {
    const updatedData = { ...formData, ...data };

    setFormData(updatedData);

    if (step === 3) {
      // Call the parent function and pass the updated formData
      onSubmitForm(updatedData);
    } else {
      setStep(step + 1);
    }
  };

  const handleSelectFinish = (value) => {
    setValue("finishes", value);
    trigger("finishes"); // Trigger validation after setting the value
    setEditMode(false);
  };

  const handleEditFinish = () => {
    setEditMode(true);
    setValue("finishes", ""); // Clear the value when editing
    trigger("finishes"); // Trigger validation after setting the value
  };

  const handleSelectWaterView = (value) => {
    setValue("waterViews", value);
    trigger("waterViews"); // Trigger validation after setting the value
    setEditMode(false);
  };

  const handleEditWaterView = () => {
    setEditMode(true);
    setValue("waterViews", ""); // Clear the value when editing
    trigger("waterViews"); // Trigger validation after setting the value
  };

  const handleToggleTopographyOption = (option) => {
    let newSelectedTopography = [...(selectedTopography || [])];
    if (option === "High side") {
      if (selectedTopography?.includes("Low side")) {
        // Remove "Low side" if "High side" is selected
        newSelectedTopography = selectedTopography.filter(
          (item) => item !== "Low side"
        );
      }
    } else if (option === "Low side") {
      if (selectedTopography?.includes("High side")) {
        // Remove "High side" if "Low side" is selected
        newSelectedTopography = selectedTopography.filter(
          (item) => item !== "High side"
        );
      }
    }

    // Toggle the current option
    if (newSelectedTopography?.includes(option)) {
      newSelectedTopography = newSelectedTopography.filter(
        (item) => item !== option
      );
    } else {
      newSelectedTopography.push(option);
    }

    setValue("topography", newSelectedTopography);
    trigger("topography"); // Trigger validation after changing value
  };

  const toggleTopographyDropdown = () => {
    setTopographyOpen(!topographyOpen);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      {step === 1 && (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <label className="form-label">Property Address</label>
            <input
              type="text"
              className={`form-input border ${
                errors.propertyAddress ? "border-red-500" : "border-mediumgray"
              }`}
              {...register("propertyAddress", {
                required: false,
              })}
              value={address}
              disabled
              readOnly
            />
            {errors.propertyAddress && (
              <span className="form-error-message">
                {errors.propertyAddress.message}
              </span>
            )}
          </div>

          <div className="col-span-12 sm:col-span-6 relative">
            <label className="form-label">Property Type</label>
            <select
              className={`form-select border ${
                errors.propertyType ? "border-red-500" : "border-mediumgray"
              }`}
              {...register("propertyType", {
                required: "Property Type is required",
              })}
            >
              <option value="">Select Property Type</option>
              {[
                "ApartmentUnitFlat",
                "Duplex",
                "House",
                "Terrace",
                "Townhouse",
                "VacantLand",
                "Villa",
              ].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.propertyType && (
              <span className="form-error-message">
                {errors.propertyType.message}
              </span>
            )}
          </div>

          {/* Conditionally render Land Area and Frontage */}
          {propertyType !== "ApartmentUnitFlat" && (
            <>
              <div className="col-span-6">
                <label className="form-label">Land Area</label>
                <input
                  type="number"
                  step="any"
                  className={`form-input border ${
                    errors.landArea ? "border-red-500" : "border-mediumgray"
                  }`}
                  {...register("landArea", {
                    required:
                      propertyType !== "ApartmentUnitFlat"
                        ? "Land Area is required"
                        : false,
                  })}
                />
                {errors.landArea && (
                  <span className="form-error-message">
                    {errors.landArea.message}
                  </span>
                )}
              </div>

              <div className="col-span-6">
                <label className="form-label">Frontage</label>
                <input
                  type="number"
                  step="any" // Allows decimal values
                  className={`form-input border ${
                    errors.frontage ? "border-red-500" : "border-mediumgray"
                  }`}
                  {...register("frontage")}
                />
                {errors.frontage && (
                  <span className="form-error-message">
                    {errors.frontage.message}
                  </span>
                )}
              </div>
            </>
          )}

          <div className="col-span-6 sm:col-span-4">
            <label className="form-label">Bedrooms</label>
            <input
              type="number"
              className={`form-input border ${
                errors.bedrooms ? "border-red-500" : "border-mediumgray"
              }`}
              {...register("bedrooms", {
                required: "Number of Beds is required",
              })}
              min={0}
            />
            {errors.bedrooms && (
              <span className="form-error-message">
                {errors.bedrooms.message}
              </span>
            )}
          </div>

          <div className="col-span-6 sm:col-span-4">
            <label className="form-label">Bathrooms</label>
            <input
              type="number"
              className={`form-input border ${
                errors.bathrooms ? "border-red-500" : "border-mediumgray"
              }`}
              {...register("bathrooms", {
                required: "Number of Baths is required",
              })}
              min={0}
            />
            {errors.bathrooms && (
              <span className="form-error-message">
                {errors.bathrooms.message}
              </span>
            )}
          </div>

          <div className="col-span-6 sm:col-span-4">
            <label className="form-label">Car Spaces</label>
            <input
              type="number"
              className={`form-input border ${
                errors.carspaces ? "border-red-500" : "border-mediumgray"
              }`}
              {...register("carspaces", {
                required: "Number of Car Spaces is required",
              })}
              min={0}
            />
            {errors.carspaces && (
              <span className="form-error-message">
                {errors.carspaces.message}
              </span>
            )}
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <FinishesSelector
              register={register}
              errors={errors}
              selectedValue={editMode ? null : selectedFinish}
              onChange={handleSelectFinish}
              onEdit={handleEditFinish}
            />
          </div>

          <div className="col-span-12">
            <WaterViewsSelector
              register={register}
              errors={errors}
              selectedValue={editMode ? null : selectedWaterView}
              onChange={handleSelectWaterView}
              onEdit={handleEditWaterView}
            />
          </div>

          {/* Conditionally render Topography */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-12">
              <label className="form-label">Topography</label>
              <TopographyDropdown
                selectedValues={selectedTopography}
                onToggleOption={handleToggleTopographyOption}
                isOpen={topographyOpen}
                toggleDropdown={toggleTopographyDropdown}
                errors={errors}
                register={register}
                trigger={trigger}
              />
            </div>
          )}
        </div>
      )}

      {step === 3 && (
        <div className="grid grid-cols-12 gap-4">
          {/* Conditionally render Build Construction Type */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-6">
              <label className="form-label">Build Construction Type</label>
              <select
                className={`form-input border ${
                  errors.buildType ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("buildType", {
                  required:
                    propertyType !== "ApartmentUnitFlat"
                      ? "Build Construction Type is required"
                      : false,
                })}
              >
                <option value="">Select Construction Type</option>
                <option value="1 storey">1 storey</option>
                <option value="2 storey">2 storey</option>
                <option value="3 story">3 story</option>
                <option value="4+ storey">4+ storey</option>
              </select>
              {errors.buildType && (
                <span className="form-error-message">
                  {errors.buildType.message}
                </span>
              )}
            </div>
          )}

          {/* Conditionally render Granny Flat */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-6">
              <label className="form-label">Granny Flat</label>
              <select
                className={`form-select border ${
                  errors.grannyFlat ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("grannyFlat", {
                  required:
                    propertyType !== "ApartmentUnitFlat"
                      ? "Granny Flat selection is required"
                      : false,
                })}
              >
                <option value="">Select Granny Flat</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {errors.grannyFlat && (
                <span className="form-error-message">
                  {errors.grannyFlat.message}
                </span>
              )}
            </div>
          )}

          {/* Conditionally render Wall Material */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-6">
              <label className="form-label">Wall Material</label>
              <select
                className={`form-input border ${
                  errors.wallMaterial ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("wallMaterial", {
                  required:
                    propertyType !== "ApartmentUnitFlat"
                      ? "Wall Material is required"
                      : false,
                })}
              >
                <option value="">Select Wall Material</option>
                <option value="Brick">Brick</option>
                <option value="Double brick">Double brick</option>
                <option value="Clad">Clad</option>
                <option value="Fibro">Fibro</option>
                <option value="Hebel">Hebel</option>
              </select>
              {errors.wallMaterial && (
                <span className="form-error-message">
                  {errors.wallMaterial.message}
                </span>
              )}
            </div>
          )}

          {/* Conditionally render Pool */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-6">
              <label className="form-label">Pool</label>
              <select
                className={`form-select border ${
                  errors.pool ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("pool", {
                  required:
                    propertyType !== "ApartmentUnitFlat"
                      ? "Pool selection is required"
                      : false,
                })}
              >
                <option value="">Select Pool</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {errors.pool && (
                <span className="form-error-message">
                  {errors.pool.message}
                </span>
              )}
            </div>
          )}

          {/* Conditionally render Tennis Court */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-6">
              <label className="form-label">Tennis Court</label>
              <select
                className={`form-select border ${
                  errors.tennisCourt ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("tennisCourt", {
                  required:
                    propertyType !== "ApartmentUnitFlat"
                      ? "Tennis Court selection is required"
                      : false,
                })}
              >
                <option value="">Select Tennis Court</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {errors.tennisCourt && (
                <span className="form-error-message">
                  {errors.tennisCourt.message}
                </span>
              )}
            </div>
          )}

          {/* Conditionally render Street Traffic */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-6">
              <label className="form-label">Street Traffic</label>
              <select
                className={`form-select border ${
                  errors.streetTraffic ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("streetTraffic", {
                  required:
                    propertyType !== "ApartmentUnitFlat"
                      ? "Street Traffic selection is required"
                      : false,
                })}
              >
                <option value="">Select Street Traffic</option>
                <option value="Low traffic">Low traffic</option>
                <option value="Moderate traffic">Moderate traffic</option>
                <option value="High traffic">High traffic</option>
              </select>
              {errors.streetTraffic && (
                <span className="form-error-message">
                  {errors.streetTraffic.message}
                </span>
              )}
            </div>
          )}

          {/* Conditionally render Development Potential */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-12 sm:col-span-6">
              <label className="form-label">Development Potential</label>
              <select
                className={`form-select border ${
                  errors.developmentPotential
                    ? "border-red-500"
                    : "border-mediumgray"
                }`}
                {...register("developmentPotential")}
              >
                <option value="">Select Development Potential</option>
                <option value="Childcare">Childcare</option>
                <option value="Duplex site">Duplex site</option>
                <option value="Townhouse site">Townhouse site</option>
                <option value="Unit site">Unit site</option>
              </select>
              {errors.developmentPotential && (
                <span className="form-error-message">
                  {errors.developmentPotential.message}
                </span>
              )}
            </div>
          )}

          <div className="col-span-12 sm:col-span-6">
            <label className="form-label">Additional Information</label>
            <textarea
              className={`form-textarea border ${
                errors.additionalInformation
                  ? "border-red-500"
                  : "border-mediumgray"
              }`}
              {...register("additionalInformation")}
              rows={3}
            />
          </div>
        </div>
      )}

      <div className="flex justify-end gap-4 mt-6">
        {step > 1 && (
          <Button type="button" onClick={prevStep} className="gray-button">
            Previous
          </Button>
        )}

        <Button type="submit" className="black-button">
          {step === 3 ? "Save" : "Next"}
        </Button>
      </div>
    </form>
  );
};

const PropertyResult = ({ property, onEdit }) => {
  console.log(property);
  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);
  const { name, email, picture } = user || {};

  const [logicalPrice, setLogicalPrice] = useState(null);
  const [saleProperties, setSaleProperties] = useState([]);
  const [soldProperties, setSoldProperties] = useState([]);
  const [areaDynamics, setAreaDynamics] = useState(null);
  const [logicalReasoning, setLogicalReasoning] = useState(null);
  const [pieChartData, setPieChartData] = useState([["Process", "Count"]]);
  const [regenerateLogicalPrice, setRegenerateLogicalPrice] = useState(false);

  useEffect(() => {
    if (!property?._id) return; // Ensure property exists before making API calls

    const fetchAreaDynamics = async () => {
      try {
        const response = await axios.get(`/property/suburb/${property.suburb}`);
        console.log("Area Dynamics Response:", response);
        setAreaDynamics(response?.data?.data);
      } catch (error) {
        console.error("Error fetching area dynamics:", error);
      }
    };

    const fetchRecommendProperties = async () => {
      try {
        const response = await axios.post("/property/recommend", {
          property, // Send the whole property object in the request body
        });
        console.log("Recommended Properties Response:", response);

        setLogicalPrice(response?.data?.data?.logical?.logicalPrice || null);
        setLogicalReasoning(
          response?.data?.data?.logical?.logicalReasoning || null
        );
        setSaleProperties(response?.data?.data?.recommendedSales || []);
        setSoldProperties(response?.data?.data?.recommendedSold || []);
      } catch (error) {
        console.error("Error fetching recommended properties:", error);
      }
    };

    const fetchBeleefSaleProcess = async () => {
      try {
        const response = await axios.get(
          `/property/beleefSaleProcess/${property.suburb}`
        );
        const apiData = response.data.data;

        // Map the API data to the format required for the chart
        const formattedData = [["Process", "Count"]]; // Initialize with header row
        for (const process in apiData) {
          // Skip "Withdrawn" process
          if (process !== "Withdrawn") {
            formattedData.push([process, apiData[process]]);
          }
        }
        // Update the chart data
        setPieChartData(formattedData);
      } catch (error) {
        console.error("Error fetching beleef sale process:", error);
      }
    };

    const executeFetchFunctions = async () => {
      try {
        setLoading(true);
        await fetchAreaDynamics(); // Waits for this to complete before moving on
        await fetchRecommendProperties(); // Waits for this to complete
        await fetchBeleefSaleProcess(); // Waits for this to complete
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Executed after all functions are completed or in case of error
      }
    };

    executeFetchFunctions();

    // Only trigger the useEffect when property._id changes
  }, [property._id]);

  const formatCurrency = (value) => {
    if (value === undefined || value === null) return "N/A";
    return "$" + new Intl.NumberFormat().format(value);
  };

  const convertDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  function getAverageValueInUnits(range) {
    // Remove dollar signs and split the range into two numbers
    const [low, high] = range
      .replace(/\$/g, "") // Remove dollar signs
      .split("-") // Split by the hyphen into low and high values
      .map((str) => str.trim()); // Trim spaces in case they exist

    // Function to convert the value based on suffix (M, K, or no suffix)
    const convertToUnits = (value, suffix) => {
      if (suffix === "M") {
        return parseFloat(value) * 1000000; // Convert millions to units
      } else if (suffix === "K") {
        return parseFloat(value) * 1000; // Convert thousands to units
      } else {
        return parseFloat(value); // If no suffix, assume it's already in units
      }
    };

    // Determine if the high value has a suffix (M or K)
    const highSuffix = high.includes("M") ? "M" : high.includes("K") ? "K" : "";

    // Strip suffix from the high value before conversion
    const highValue = high.replace(/[MK]/, "");

    // Convert low and high values to unit form, applying the same suffix to both
    const lowInUnits = convertToUnits(low, highSuffix); // Apply the suffix from high to low
    const highInUnits = convertToUnits(highValue, highSuffix); // Convert high value without suffix

    // Calculate the average
    const average = (lowInUnits + highInUnits) / 2;

    // Return the average in units format, no suffix
    return average;
  }

  // Create dataPoints for GoogleChart
  const dataPoints = [
    [
      property.landArea || 0,
      logicalPrice ? getAverageValueInUnits(logicalPrice) : 0,
      property.address,
    ],
    ...(() => {
      // Get today's date
      const today = new Date();

      // Filter soldProperties based on dateListed within the last 90 days
      const recentSoldProperties = soldProperties.filter(({ property }) => {
        const dateListed = new Date(property.dateListed);
        const diffInDays = (today - dateListed) / (1000 * 60 * 60 * 24); // Convert milliseconds to days
        return diffInDays <= 180 && property.landArea && property.price;
      });

      // If there are more than 5 properties listed in the last 90 days, return them
      if (recentSoldProperties.length > 5) {
        return recentSoldProperties.map(({ property }) => [
          property.landArea,
          property.price,
          property.address,
        ]);
      } else {
        return soldProperties
          .filter(({ property }) => property.landArea && property.price) // Filter properties with both landArea and price
          .map(({ property }) => [
            property.landArea,
            property.price,
            property.address,
          ]);
      }
    })(),
  ];

  const [shareLoading, setShareLoading] = useState(false);

  const handleShareClick = async () => {
    try {
      setShareLoading(true);

      // Make the API request to generate the shareable link first
      const response = await axios.post("/share/quickShare", {
        property: {
          ...property,
          logicalPrice,
          logicalReasoning,
        },
        saleProperties,
        soldProperties,
        areaDynamics,
        pieChartData,
      });

      const shareableLink = response.data.data;

      // Trigger the share dialog with the title and link
      if (navigator.share) {
        // Ensure the share call is synchronous within the user action
        navigator.share({
          title: `Quick Search Results`,
          text: `Hi,

Please find enclosed Information for the property at ${property.address}`,
          url: shareableLink,
        });
      } else {
        alert(
          "Sharing is not supported in your browser. Please copy the link: " +
            shareableLink
        );
      }
    } catch (error) {
      console.error("Error generating shareable link:", error);
    } finally {
      setShareLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full max-w-4xl mx-auto flex flex-col items-center justify-center text-center space-y-20">
        <div className="w-full flex justify-end gap-2">
          <Button className="gray-button" onClick={onEdit} disabled={loading}>
            Edit
          </Button>
          <Button
            className="black-button"
            onClick={handleShareClick}
            loading={shareLoading}
            disabled={shareLoading || loading}
          >
            Share
          </Button>
        </div>

        <p>{property.address}</p>

        <div className="space-y-8">
          <h4>LOGICAL PRICE</h4>

          {/* Price and Info Icon */}
          <div className="flex items-center justify-center gap-4">
            {!loading ? (
              <>
                <span className="font-bold">
                  <EditableField
                    value={logicalPrice ? logicalPrice : "N/A"}
                    onSave={(newValue) => {
                      setLogicalPrice(newValue); // Update postList in state
                    }}
                  />
                </span>
                <i
                  class="fas fa-sync-alt cursor-pointer"
                  onClick={() => setRegenerateLogicalPrice(true)}
                ></i>
              </>
            ) : (
              <i className="fa-solid fa-spinner animate-spin"></i>
            )}
            <Tooltip
              className="w-[300px]"
              text={<i className="fa fa-info-circle text-xs"></i>}
              tooltip="This is just a logical estimated price, and is grounded on a comprehensive set of factors including recent local sales, property size, number of bedrooms, the topography of the land, street traffic, recent updates to the property, and various other determinants. The information is sourced from public datasets which, while extensive, might be incomplete or contain inaccuracies, so should not be solely relied upon. For a more precise and accurate estimation of price, we strongly recommend consulting with a licensed real estate agent or a registered valuer. Occasionally, we may send you updates on the property market"
            />
          </div>

          {/* Logical Reasoning */}
          {logicalReasoning && (
            <div className="text-start my-1 text-sm">
              <span className="font-medium">Reasoning:</span> {logicalReasoning}
            </div>
          )}

          {/* Property Details */}
          <div className="max-w-md mx-auto space-y-6">
            {/* Property Image */}
            <img
              // className="w-full h-auto rounded-lg filter grayscale"
              className="w-full h-auto rounded-lg"
              src={property?.media?.[0]?.url}
              alt="property"
            />

            {/* Property Address */}
            <p className="text-center">{property.address}</p>

            {/* Property Features */}
            <div className="flex items-center justify-center gap-4">
              <div className="text-sm">
                <i className="fa fa-bed me-2"></i>
                {property.bedrooms || "N/A"}
              </div>
              <div className="text-sm">
                <i className="fa fa-bath me-2"></i>
                {property.bathrooms || "N/A"}
              </div>
              <div className="text-sm">
                <i className="fa fa-car me-2"></i>
                {property.carspaces || "N/A"}
              </div>
              <div className="text-sm">{property.propertyType}</div>
            </div>

            {/* Sale Details */}
            <div className="flex justify-between gap-4 text-left text-sm">
              <div className="flex flex-col">
                <span className="font-semibold">Sale Price:</span>
                <span>{formatCurrency(property.price)}</span>
              </div>
              <div className="flex flex-col">
                <span className="font-semibold">Sale Date:</span>
                <span>
                  {property.dateListed
                    ? convertDate(property.dateListed)
                    : "N/A"}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="font-semibold">Days to Sell:</span>
                <span>{property.daysListed}</span>
              </div>
            </div>
          </div>
        </div>

        {soldProperties && soldProperties.length > 0 && (
          <div class="w-full space-y-8">
            <h4>SOLD MATCHES</h4>
            <Slider {...settings} className="w-full mr-4">
              {soldProperties.map(({ property, score, keyMatches }) => (
                <div
                  key={property._id}
                  className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                >
                  <img
                    className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] "
                    src={property?.media?.[0]?.url}
                    alt="property"
                  />
                  <div className="text-center mt-4 space-y-3">
                    <p className="font-semibold">
                      {formatCurrency(property.price)} ({score}%)
                    </p>
                    <p className="text-sm">{property.address}</p>
                    <div className="flex items-center justify-center gap-4">
                      <div className="text-sm">
                        <i className="fa fa-bed me-2"></i>
                        {property.bedrooms || "N/A"}
                      </div>
                      <div className="text-sm">
                        <i className="fa fa-bath me-2"></i>
                        {property.bathrooms || "N/A"}
                      </div>
                      <div className="text-sm">
                        <i className="fa fa-car me-2"></i>
                        {property.carspaces || "N/A"}
                      </div>
                      <div className="text-sm">{property.propertyType}</div>
                    </div>
                    <div className="text-xs text-start space-y-1">
                      <p className="font-semibold">Key Matches</p>
                      <p className="italic">{keyMatches.join(", ")}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}

        {saleProperties && saleProperties.length > 0 && (
          <div class="w-full space-y-8">
            <h4>ON MARKET MATCHES</h4>
            <Slider {...settings} className="w-full mr-4">
              {saleProperties.map(({ property, score, keyMatches }) => (
                <div
                  key={property._id}
                  className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                >
                  <img
                    className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] "
                    src={property?.media?.[0]?.url}
                    alt="property"
                  />
                  <div className="text-center mt-4 space-y-3">
                    <p className="font-semibold">
                      {formatCurrency(property.price)} ({score}%)
                    </p>
                    <p className="text-sm">{property.address}</p>
                    <div className="flex items-center justify-center gap-4">
                      <div className="text-sm">
                        <i className="fa fa-bed me-2"></i>
                        {property.bedrooms || "N/A"}
                      </div>
                      <div className="text-sm">
                        <i className="fa fa-bath me-2"></i>
                        {property.bathrooms || "N/A"}
                      </div>
                      <div className="text-sm">
                        <i className="fa fa-car me-2"></i>
                        {property.carspaces || "N/A"}
                      </div>
                      <div className="text-sm">{property.propertyType}</div>
                    </div>
                    <div className="text-xs text-start space-y-1">
                      <p className="font-semibold">Key Matches</p>
                      <p className="italic">{keyMatches.join(", ")}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}

        {soldProperties && soldProperties.length > 0 && (
          <div className="space-y-8 w-full">
            <h4>SCORE MATCH ON MARKET</h4>
            <GoogleChart dataPoints={dataPoints} />
          </div>
        )}

        <div className="space-y-8 w-full">
          <h4>AREA SALES PROCESS BREAKDOWN</h4>
          <GooglePieChart data={pieChartData} />
        </div>

        <div class="w-full max-w-lg mx-auto space-y-8">
          <h4>YOUR AREA DYNAMICS</h4>
          {areaDynamics ? (
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-start border border-mediumgray border-collapse">
                <thead>
                  <tr className="bg-mediumgray border border-mediumgray">
                    <th className="py-2 px-3 border border-mediumgray"></th>
                    <th className="py-2 px-3 text-left border border-mediumgray">
                      <i className="fa-solid fa-house mr-2"></i> House
                    </th>
                    <th className="py-2 px-3 text-left border border-mediumgray">
                      <i className="fa-solid fa-building mr-2"></i> Unit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-mediumgray">
                    <td className="py-2 px-3 border border-mediumgray">
                      Median Sale Price
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.houseStats
                        ? `$${areaDynamics.houseStats.medianSalePrice?.toLocaleString()}`
                        : "N/A"}
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.unitStats
                        ? `$${areaDynamics.unitStats.medianSalePrice?.toLocaleString()}`
                        : "N/A"}
                    </td>
                  </tr>
                  <tr className="border-b border-mediumgray">
                    <td className="py-2 px-3 border border-mediumgray">
                      Annual Sales Volume
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.houseStats
                        ? areaDynamics.houseStats.annualSalesVolume
                        : "N/A"}
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.unitStats
                        ? areaDynamics.unitStats.annualSalesVolume
                        : "N/A"}
                    </td>
                  </tr>
                 
                  <tr className="border-b border-mediumgray">
                    <td className="py-2 px-3 border border-mediumgray">
                      Suburb Growth
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.houseStats
                        ? areaDynamics.houseStats.suburbGrowth
                        : "N/A"}
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.unitStats
                        ? areaDynamics.unitStats.suburbGrowth
                        : "N/A"}
                    </td>
                  </tr>
                  <tr className="border-b border-mediumgray">
                    <td className="py-2 px-3 border border-mediumgray">
                      High Demand Area
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.houseStats
                        ? areaDynamics.houseStats.highDemandArea
                        : "N/A"}
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.unitStats
                        ? areaDynamics.unitStats.highDemandArea
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <i className="fa-solid fa-spinner animate-spin mr-2"></i> Loading
              ...
            </div>
          )}
        </div>

        <GoogleMaps lat={property.latitude} lon={property.longitude} />

        <div class="space-y-8 flex flex-col">
          <img src={picture} alt="Agent" className="max-h-[400px]" />
          <p>{name}</p>
          <p>{email}</p>
        </div>

        <RegenerateLogicalPrice
          property={property}
          regenerateLogicalPrice={regenerateLogicalPrice}
          setRegenerateLogicalPrice={setRegenerateLogicalPrice}
          soldProperties={soldProperties}
          setLogicalPrice={setLogicalPrice}
          setLogicalReasoning={setLogicalReasoning}
        />
      </div>
    </div>
  );
};

const PropertyContainer = ({ property: initialProperty }) => {
  const [formData, setFormData] = useState(initialProperty);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Function to handle the form submission and pass data to PropertyResult
  const handleFormSubmit = (data) => {
    setFormData(data);
    setIsSubmitted(true); // This will show the PropertyResult component
  };

  // Function to go back to editing the form
  const handleEdit = () => {
    setIsSubmitted(false); // Switch back to the form
  };

  useEffect(() => {
    // Update the form data if the initial property changes
    setFormData(initialProperty);
  }, [initialProperty]);

  return (
    <div className="w-full">
      {!isSubmitted ? (
        <PropertyForm property={formData} onSubmitForm={handleFormSubmit} />
      ) : (
        <PropertyResult property={formData} onEdit={handleEdit} />
      )}
    </div>
  );
};

const QuickSearch = () => {
  const [property, setProperty] = useState("");
  const [propertyForm, setPropertyForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState("");

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const searchProperty = async (
    shortAddress,
    suburb,
    postcode,
    latitude,
    longitude
  ) => {
    try {
      setLoading(true);
      const response = await axios.post("/userProperty", {
        address: shortAddress,
        suburb,
        postcode,
        latitude,
        longitude,
      });

      if (response.data.success) {
        return response.data.data;
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
      setError(error.message);
    } finally {
      setLoading(false);
      setPropertyForm(true);
    }
  };

  const handlePlaceChanged = async () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      console.log(place);

      if (place && place.address_components) {
        const addressComponents = place.address_components;

        // Extract the formatted address components
        const suburb =
          addressComponents.find(
            (component) =>
              component.types.includes("locality") &&
              component.types.includes("political")
          )?.long_name || "";

        const postcode =
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "";

        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        let fullAddress = place.formatted_address;

        // Check if the address contains 'NSW'
        if (!fullAddress.includes("NSW")) {
          showToast("error", "Only NSW properties are allowed.");
          return;
        }

        // Step 1: Remove everything after 'NSW'
        let shortAddress = fullAddress.split("NSW")[0].trim();

        // Step 2: Dynamically replace abbreviations with long form from address components
        addressComponents.forEach((component) => {
          const longName = component.long_name;
          const shortName = component.short_name;

          // If the fullAddress contains the abbreviation (short name), replace it with the long name
          if (shortName && fullAddress.includes(shortName)) {
            shortAddress = shortAddress.replace(shortName, longName);
          }
        });

        // Set the final formatted address
        setAddress(shortAddress);
        console.log(shortAddress);

        // Important functionality: Add further operations like searching the property
        const response = await searchProperty(
          shortAddress,
          suburb,
          postcode,
          latitude,
          longitude
        );

        setProperty(response || "");
      } else {
        // Logic when the input is cleared or invalid place selected
        showToast("error", "Invalid place selected.");
      }
    }
  };

  const handleInputChange = (e) => {
    setAddress(e.target.value);
  };

  const handleSend = async () => {
    if (address.trim() === "") {
      showToast("error", "Please type or select a valid address.");
      return;
    }
    if (!address.toLowerCase().includes("nsw")) {
      showToast("error", "Only NSW properties are allowed.");
      return;
    }

    const response = await searchProperty(address);
    setProperty(response || "");
  };

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <Error />;
  }

  if (propertyForm) {
    return (
      <div className="container">
        <PropertyContainer property={property} />
      </div>
    );
  }

  return (
    <div className="container flex flex-col items-center">
      <div className="w-full fixed bottom-8 left-0 right-0 px-6 pt-2.5 pb-4 z-50 bg-white">
        <div className="w-full max-w-4xl mx-auto flex gap-2 items-end justify-center relative">
          <Autocomplete
            onLoad={handleLoad}
            onPlaceChanged={handlePlaceChanged}
            options={{
              componentRestrictions: { country: ["au"] },
              fields: ["address_components", "geometry", "formatted_address"],
              types: ["geocode"],
            }}
            className="w-full"
          >
            <div className="max-w-md mx-auto relative text-xs">
              <input
                type="text"
                value={address}
                onChange={handleInputChange}
                placeholder="Enter address here"
                className="start-campaign-input w-full relative z-10 flex-grow p-2 bg-lightgray rounded-md py-3 pl-3 pr-8 outline-none focus:outline-none resize-none overflow-y-hidden"
              />
              {address && (
                <IoSend
                  onClick={handleSend}
                  className="cursor-pointer text-darkgray hover:text-darkergray text-xl absolute z-20 top-[10px] right-[10px]"
                />
              )}
            </div>
          </Autocomplete>
        </div>
      </div>
    </div>
  );
};

export default QuickSearch;
