import React, { useState, useEffect } from "react";
import axios from "utils/axios";

const DuplicateProperties = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from the API
  const fetchDuplicates = async () => {
    try {
      const response = await axios.get("property/sharedDB"); // Replace with your API endpoint
      setData(response.data.data);
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  // Run on component mount
  useEffect(() => {
    fetchDuplicates();
  }, []);

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold text-center mb-6">Duplicate Properties</h1>

      {data.length === 0 ? (
        <p className="text-center">No duplicate properties found.</p>
      ) : (
        <div className="space-y-4">
          {data.map((item, index) => (
            <div
              key={index}
              className="border border-gray-300 rounded p-4 bg-white shadow"
            >
              {/* Address and Count */}
              <h4 className="font-semibold">Address: {item.address}</h4>
              <p className="text-gray-600">Duplicates Count: {item.count}</p>

              {/* Users */}
              <div className="mt-4">
                <h4>Agents:</h4>
                <ul className="list-none list-inside ml-4">
                  {item.users.map((user, idx) => (
                    <li key={idx} className="text-gray-800">
                      {user.name}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Properties and Five Step Process */}
              <div className="mt-4">
                <h4>Properties:</h4>
                {item.properties.map((property, propIdx) => (
                  <div key={propIdx} className="border rounded p-2 mb-2">
                    {property.fiveStepProcess && property.fiveStepProcess.length > 0 ? (
                      <div>
                        <h5 className="mt-2 font-semibold">Five Step Process:</h5>
                        <ul className="list-none list-inside ml-4">
                          {property.fiveStepProcess.map((step, stepIdx) => (
                            <li key={stepIdx} className="mt-2">
                              <p>
        <strong>Agent:</strong> {step.userName?.name || "N/A"}
      </p>
                              <p>
                                <strong>Step Name:</strong> {step.name}
                              </p>
                              <p>
                                <strong>Price Point:</strong> {step.pricePoint || "N/A"}
                              </p>
                              <p>
                                <strong>Enquiries:</strong> {step.enquiries || "N/A"}
                              </p>
                              <p>
                                <strong>Key Meeting:</strong> {step.keyMeeting || "N/A"}
                              </p>
                              <p>
                                <strong>Finalise:</strong> {step.finalise || "N/A"}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p className="text-gray-600">No Five Step Process available.</p>
                    )}
                  </div>
                ))}
              </div>

              {/* Book Appraisals */}
              <div className="mt-4">
                <h4>Book Appraisals:</h4>
                <ul className="list-none list-inside ml-4">
                  {item.bookAppraisals.map((ba, idx) => (
                    <li key={idx} className="text-gray-800">
                      Agent: {ba.userName || "N/A"}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Post Lists */}
              <div className="mt-4">
                <h4>Post Lists:</h4>
                <ul className="list-none list-inside ml-4">
                  {item.postLists.map((pl, idx) => (
                    <li key={idx} className="text-gray-800">
                      Agent: {pl.userName || "N/A"}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Auth Schedules */}
              <div className="mt-4">
                <h4>Auth Schedules:</h4>
                <ul className="list-none list-inside ml-4">
                  {item.authSchedules.map((as, idx) => (
                    <li key={idx} className="text-gray-800">
                      Agent: {as.userName || "N/A"}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DuplicateProperties;
