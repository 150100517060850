import React, { useEffect } from "react";
import CryptoJs from "crypto-js"; // Import CryptoJS for HMAC signature generation

export const dummyData = [
  {
    agentContribution: 0,
    listingId: "66e926f6a67237b0fc63b10e",
    apiConsumerName: "AusRealty",
    userEmail: "joyce.boustani@ausrealty.com.au",
    hasPermission: true,
    userName: "Joyce Boustani",
    dataMode: "advanced",
    officeName: "AusRealty",
    officeOwnershipGroupName: "Riverwood",
    officeOwnershipGroup_Ui: "1234",
    officeUniqueIdentifier: "myapp_2",
    owners: [
      {
        email: "careers@ausrealty.com.au",
        mobile: "0459318112",
        name: "John Ko",
      },
      {
        email: "owner2@example.com",
        mobile: "0459318113",
        name: "Jane Smith",
      },
    ],
    propertyAddress: "14 dummy Street, Peakhurst NSW",
    requestedAmount: 0,
  },
];

const CAPayments = () => {
  const preSharedKey = "hardwired-structure-annoying-sedative"; // Your pre-shared key
  // const preSharedKey="affecting-defection-envious-entering"
  useEffect(() => {
    let script;

    const loadCAPaymentsScript = () => {
      // Dynamically load the external script
      script = document.createElement("script");
      script.src =
        "https://uat.campaignagent.com.au/ca_payments/ca_payments.js";
      // For production use:
      // script.src =
      //   "https://cas.campaignagent.com.au/ca_payments/ca_payments.js";
      script.async = true;
      // Error handling for script loading
      script.onerror = () => {
        console.error("Error loading the ca_payments script.");
      };

      script.onload = () => {
        console.log("External script loaded and ready.");
        try {
          // Wait for DOM elements to be ready before calling ca_payments_convert_tags
          setTimeout(() => {
            if (window.ca_payments_convert_tags) {
              window.ca_payments_convert_tags();
            } else {
              console.error("ca_payments_convert_tags is not available.");
            }
          }, 100); // Slight delay to ensure the DOM is updated
        } catch (error) {
          console.error("Error executing ca_payments_convert_tags:", error);
        }
      };

      document.body.appendChild(script);
    };

    loadCAPaymentsScript();

    return () => {
      if (script) {
        document.body.removeChild(script); // Cleanup on component unmount
      }
      console.log("Script cleaned up.");
    };
  }, []);

  // Function to generate the HMAC signature
  const generateSignature = (attributes) => {
    const params = {
      "data-agent-contribution-amount": attributes.agentContribution,
      "data-api-consumer-listing-id": attributes.listingId,
      "data-api-consumer-name": attributes.apiConsumerName,
      "data-current-user-email": attributes.userEmail,
      "data-current-user-has-permission-to-setup-integration":
        attributes.hasPermission,
      "data-current-user-name": attributes.userName,
      "data-mode": attributes.dataMode,
      "data-office-name": attributes.officeName,
      "data-office-ownership-group-name": attributes.officeOwnershipGroupName,
      "data-office-ownership-group-unique-identifier":
        attributes.officeOwnershipGroup_Ui,
      "data-office-unique-identifier": attributes.officeUniqueIdentifier,
      "data-property-address": attributes.propertyAddress,
      "data-requested-amount": attributes.requestedAmount,
      "data-on-payment-status-change-webhook-url":
        "https://backend-staging.beleef.com.au/api/v1/webhooks/campaignAgent",
      // "data-search-mod": "apiConsumerListingId",
    };

    // Add dynamic owners' details
    attributes.owners.forEach((owner, index) => {
      const ownerNumber = index + 1;
      params[`data-owner-${ownerNumber}-name`] = owner.name;
      params[`data-owner-${ownerNumber}-email`] = owner.email;
      params[`data-owner-${ownerNumber}-mobile`] = owner.mobile;
    });

    // Sort the keys alphabetically to ensure consistency in the signature
    const sortedKeys = Object.keys(params).sort();
    // Concatenate the sorted key-value pairs into a string
    const stringToSign = sortedKeys
      .map((key) => `${key}=${params[key]}`)
      .join("");

    // Generate the HMAC-SHA256 hash using the preSharedKey
    const signature = CryptoJs.HmacSHA256(stringToSign, preSharedKey).toString(
      CryptoJs.enc.Base64
    );
    console.log("Generated Signature:", signature);
    return signature;
  };

  const generateSignaturePdf = () => {
    // Generate the HMAC-SHA256 hash using the preSharedKey
    const signature = CryptoJs.HmacSHA256(
      "https://cas.campaignagent.com.au/caPaymentsTag/documents/AusRealty/myapp_2/myapp1072/agency-agreement/agreement.pdf",
      preSharedKey
    ).toString(CryptoJs.enc.Base64);
    console.log("Generated Signature pdf:", signature);
  };
  generateSignaturePdf();

  return (
    <div className="relative">
      {dummyData.map((item, index) => {
        // Prepare dynamic owner attributes
        const ownerAttributes = {};
        item.owners.forEach((owner, i) => {
          ownerAttributes[`data-owner-${i + 1}-name`] = owner.name;
          ownerAttributes[`data-owner-${i + 1}-email`] = owner.email;
          ownerAttributes[`data-owner-${i + 1}-mobile`] = owner.mobile;
        });

        // Combine static and dynamic attributes
        const caPaymentsAttributes = {
          "data-agent-contribution-amount": item.agentContribution,
          "data-api-consumer-listing-id": item.listingId,
          "data-api-consumer-name": item.apiConsumerName,
          "data-current-user-email": item.userEmail,
          "data-current-user-has-permission-to-setup-integration":
            item.hasPermission,
          "data-current-user-name": item.userName,
          "data-mode": item.dataMode,
          "data-office-name": item.officeName,
          "data-office-ownership-group-name": item.officeOwnershipGroupName,
          "data-office-ownership-group-unique-identifier":
            item.officeOwnershipGroup_Ui,
          "data-office-unique-identifier": item.officeUniqueIdentifier,
          "data-property-address": item.propertyAddress,
          "data-requested-amount": item.requestedAmount,
          "data-on-payment-status-change-webhook-url":
            "https://backend-staging.beleef.com.au/api/v1/webhooks/campaignAgent",
          // "data-search-mod": "apiConsumerListingId",
          ...ownerAttributes, // Spread owner attributes
          "data-signature": generateSignature(item), // Add generated signature
        };

        return (
          <>
            <ca_payments
              {...caPaymentsAttributes}
              env="uat"
            ></ca_payments>
          </>
        );
      })}
    </div>
  );
};

export default CAPayments;
