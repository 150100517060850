import defaultImage from "../../assets/images/preview.jpg";
import React, { useEffect, useRef, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { parseOptionNameAccordingToCategory } from "utils/helperFunctions";
const ShoppingCard = ({
  isVideo,
  category,
  handleCheckboxChange,
  handlePriceChange,
  formatCurrency,
  key,
  thumbnail = defaultImage,
  thumbnailFolder = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // we have to initally set the selected options to the ones that are already checked in category.items
  useEffect(() => {
    const options = parseOptionNameAccordingToCategory(
      category.category,
      category.items.filter((item) => item.isChecked)
    );
    setSelectedOptions(options);
  }, [category.items]);
  const filterOutSelectedOptions = (options, isChecked) => {
    if (isChecked) {
      const option = parseOptionNameAccordingToCategory(
        category.category,
        selectedOptions.filter((option) => option._id !== options._id)
      );

      setSelectedOptions(option);
    } else {
      const option = parseOptionNameAccordingToCategory(category.category, [
        ...selectedOptions,
        options,
      ]);

      setSelectedOptions(option);
    }
  };
  const handleUpdateSelectedOptionPrice = (id, price) => {
    const option = parseOptionNameAccordingToCategory(
      category.category,
      selectedOptions.map((option) => {
        if (option._id === id) {
          return { ...option, price };
        }
        return option;
      })
    );
    setSelectedOptions(option);
  };
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
    adaptiveHeight: true,
  };
  return (
    <>
      <div
        className="bg-white shadow-md flex flex-col h-full w-full max-w-xs mx-auto"
        key={key}
      >
        <div className="mb-1 w-full h-64 overflow-hidden">
          {thumbnailFolder.length > 0 ? (
            <Slider {...sliderSettings} className="h-full">
              {thumbnailFolder.map((thumbnail, index) => (
                <div key={index} className="h-full">
                  {isVideo ? (
                    <video
                      className="object-cover w-full h-full"
                      autoPlay={true}
                      loop
                      preload="metadata"
                      muted
                      controls={false}
                      playsInline
                      webkit-playsinline="true"
                    >
                      <source src={thumbnail} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={thumbnail}
                      alt={category.category}
                      className="object-cover w-full h-full"
                    />
                  )}
                </div>
              ))}
            </Slider>
          ) : (
            <img
              src={thumbnail}
              alt={category.category}
              className="object-cover w-full h-full"
            />
          )}
        </div>
        <p className="text-sm font-bold mb-2 px-3">{category.category}</p>
        {selectedOptions.length > 0 && (
          <ul className="mx-3 list-none">
            {selectedOptions.map((option) => (
              <li key={option._id} className="text-xs">
                {option.name} -{" "}
                {option.price > 0 ? formatCurrency(option.price) : "Free"}
              </li>
            ))}
          </ul>
        )}
        <div className="px-3 pb-2" ref={dropdownRef}>
          <button
            className="w-full px-4 py-2 text-left bg-gray-100 rounded-md flex justify-between items-center mt-2 text-sm"
            onClick={() => setIsOpen(!isOpen)}
            aria-expanded={isOpen}
            aria-controls="options-dropdown"
          >
            Select
            <BiChevronDown
              className={`transform transition-transform ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          </button>
          {isOpen && (
            <div
              id="options-dropdown"
              className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg left-0"
            >
              {category?.items?.map((item, index) => (
                <tr
                  key={item._id}
                  className="hover:bg-lightgray border-b border-mediumgray flex items-center justify-between cursor-pointer"
                  onClick={() => {
                    handleCheckboxChange(item._id);
                    filterOutSelectedOptions(item, item.isChecked);
                  }}
                >
                  <td className="py-2 px-3 text-xs">{item.name}</td>
                  <div className="flex items-center">
                    <td className="py-2 px-3 text-xs">
                      {category.category === "Internet Portals" && index < 2 ? (
                        <input
                          type="text"
                          value={item.price}
                          placeholder="$0"
                          onChange={(e) => {
                            handlePriceChange(item._id, e);
                            handleUpdateSelectedOptionPrice(
                              item._id,
                              e.target.value
                            );
                          }}
                          className="form-input border border-mediumgray text-right"
                          onClick={(e) => e.stopPropagation()}
                        />
                      ) : (
                        formatCurrency(item.price)
                      )}
                    </td>
                    <td className="py-2 px-3">
                      <input
                        type="checkbox"
                        checked={item.isChecked}
                        onChange={() => {
                          handleCheckboxChange(item._id);
                          filterOutSelectedOptions(item, item.isChecked);
                        }} // Toggle when clicked
                        onClick={(e) => e.stopPropagation()} // Prevent row click event
                      />
                    </td>
                  </div>
                </tr>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ShoppingCard;
// export default function PopupModal() {

//   const cardData = [
//     {
//       thumbnail: '/placeholder.svg?height=160&width=240',
//       title: 'Card 1',
//       options: [
//         { label: 'Option 1', price: 10 },
//         { label: 'Option 2', price: 15 },
//         { label: 'Option 3', price: 20 },
//       ],
//     },
//     {
//       thumbnail: '/placeholder.svg?height=160&width=240',
//       title: 'Card 2',
//       options: [
//         { label: 'Option A', price: 5 },
//         { label: 'Option B', price: 8 },
//         { label: 'Option C', price: 12 },
//       ],
//     },
//   ]

//   return (
//     <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//     {cardData.map((card, index) => (
//       <Card key={index} {...card} />
//     ))}
//   </div>
//   )
// }
