// import React, { useContext } from "react";
// import { Navigate, useLocation } from "react-router-dom";
// import { AuthContext } from "../context/AuthContext";

// const ProtectedRoute = ({ children }) => {
//   const { user, loading } = useContext(AuthContext);

//   const location = useLocation();

//     // Allow access to /share URLs without login restriction
//     if (location.pathname.startsWith("/share")) {
//       return children;
//     }

//   if (loading) {
//     return <p></p>
//   }

//   if (!user) {
//     return <Navigate to="/login" />;
//   }

//   if (!user.profileComplete && location.pathname !== "/complete-profile") {
//     return <Navigate to="/complete-profile" />;
//   }

//   if (user.profileComplete && location.pathname === "/complete-profile") {
//     return <Navigate to="/" />;
//   }

//   return children;
// };

// export default ProtectedRoute;


import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);
  const location = useLocation();

  // Define the paths that should not redirect to the login page if unauthenticated
  const exemptPaths = ["/share", "/prelist", "/postlist", "/agreement", "/esign", "/upload"];


  const isExemptPath = () => {
    return exemptPaths.some((exemptPath) => location.pathname.startsWith(exemptPath));
  };

  

  // Allow access to exempt paths without login restriction
  if (isExemptPath()) {
    return children;
  }

  // Display loading state if the authentication is in progress
  if (loading) {
    return <p></p>;
  }

  // If user is not authenticated, redirect to the login page
  if (!user) {
    return <Navigate to="/login" />;
  }

  // If user profile is incomplete and trying to access any other page, redirect to complete profile page
  if (!user.profileComplete && location.pathname !== "/complete-profile") {
    return <Navigate to="/complete-profile" />;
  }

  // If the user has completed their profile, prevent access to the complete profile page
  if (user.profileComplete && location.pathname === "/complete-profile") {
    return <Navigate to="/" />;
  }

  // If all checks pass, render the requested children
  return children;
};

export default ProtectedRoute;
