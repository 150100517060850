// import { MessageCircle } from 'lucide-react';
import { FaRocketchat } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import React, { useState, useEffect } from "react";
import axios from "axios";

const ChatButton = () => {
  const [firstMessage, setFirstMessage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(
          "http://54.253.83.175/api/messages/120363359105466530@g.us"
        );

        if (response.status === 200) {
          setMessages(response.data.messages);
        } else {
          console.error("Failed to fetch messages");
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
        // Fallback to dummy data if API request fails
        setMessages([
          {
            sender: "Sandy",
            senderNumber: "61 404 693 073",
            message:
              "Hi Team, Welcome! We have created this group to ensure smooth communication. Looking forward to achieving the maximum outcome. Regards, Sandy",
            timestamp: 1730868454,
          },
        ]);
      }
    };

    fetchMessages();
  }, []);

  return (
    <div
      className="fixed bottom-32 right-6 flex flex-col items-end gap-4"
      style={{ zIndex: "1200" }}
    >
      {isOpen && (
        <div className="bg-white rounded-lg shadow-lg p-4 w-80 h-96 flex flex-col">
          <div className="flex justify-between items-center border-b pb-2">
            <h4>Whatsapp</h4>
            <button
              onClick={() => setIsOpen(false)}
              className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            >
              <IoIosClose />
            </button>
          </div>

          {firstMessage && (
            <div className="w-full flex-1 overflow-y-auto py-4 max-w-md mx-auto">
              {messages.map((msg, index) => (
                <div key={index} className="flex items-start mb-4">
                  <div className="bg-lightergray p-3 rounded-md shadow-sm w-full">
                    <div className="flex justify-between">
                      <p className="text-xs text-darkergray font-bold">
                        {msg.sender}
                      </p>
                      <p className="text-xs text-darkergray">
                        {msg.senderNumber}
                      </p>
                    </div>

                    <p className="text-sm mt-2">{msg.message}</p>
                    <p className="text-right text-xs text-darkgray mt-2">
                      {new Date(msg.timestamp * 1000).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

<div className="absolute bottom-0 left-0 right-0 p-4 bg-white border-t">
            <div className="grid grid-cols-3 gap-2">
              <button
                className="bg-lightgray p-2 rounded hover:bg-gray-200 transition-colors text-sm"
                onClick={() => setFirstMessage(true)}
              >
                Vendor
              </button>
              <button className="bg-lightgray p-2 rounded hover:bg-gray-200 transition-colors text-sm">
                Buyer
              </button>
              <button className="bg-lightgray p-2 rounded hover:bg-gray-200 transition-colors text-sm">
                Tenant
              </button>
            </div>
          </div>
        </div>
      )}

      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-black text-white rounded-full p-3 shadow-lg transition-colors"
      >
        <FaRocketchat />
      </button>
    </div>
  );
};

export default ChatButton;
