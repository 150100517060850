import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "utils/axios";
import Error from "components/ui/Error";
import {
  Autocomplete,
  GoogleMap,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";
import Button from "components/ui/Button";
import { showToast } from "components/ui/toast";
import { IoSend } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { MdOutlineEdit } from "react-icons/md";
import { HiChevronDown } from "react-icons/hi2";
import PageLoader from "components/ui/PageLoader";
import GoogleMaps from "components/GoogleMaps";
import GoogleChart from "components/GoogleChart";
import video from "assets/videos/postlist-video.mp4";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "components/ui/Tooltip";
import arrow from "assets/images/arrow.png";
import GooglePieChart from "components/GooglePieChart";
import Modal from "components/ui/Modal";
import ausrealtyLogo from "assets/images/ausrealy-logo.svg";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  adaptiveHeight: true,
  arrows: true,
  // centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        slidesToShow: 1,
      },
    },
  ],
};

const QuickSearchShare = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // Initialize loading as true
  const [error, setError] = useState(false);

  // Extract the UUID from the URL
  const getUUIDFromURL = () => {
    const url = window.location.pathname;
    if (url.includes("/quick-search/share/")) {
      const uuid = url.split("/quick-search/share/")[1];
      return uuid ? uuid.split(/[?#]/)[0] : null;
    }
    return null;
  };

  const fetchPropertyByUUID = async (uuid) => {
    try {
      const response = await axios.get(`/share/quickShare/${uuid}`);
      setData(response.data.data);
    } catch (err) {
      console.error("Error fetching quick search data:", err);
      setError(true);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    const uuid = getUUIDFromURL();
    if (uuid) {
      fetchPropertyByUUID(uuid); // Fetch data by UUID
    }
  }, []);

  const formatCurrency = (value) =>
    value ? `$${new Intl.NumberFormat().format(value)}` : "N/A";

  const convertDate = (dateString) =>
    new Date(dateString).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <Error />;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  function getAverageValueInUnits(range) {
    // Remove dollar signs and split the range into two numbers
    const [low, high] = range
      .replace(/\$/g, "") // Remove dollar signs
      .split("-") // Split by the hyphen into low and high values
      .map((str) => str.trim()); // Trim spaces in case they exist

    // Function to convert the value based on suffix (M, K, or no suffix)
    const convertToUnits = (value, suffix) => {
      if (suffix === "M") {
        return parseFloat(value) * 1000000; // Convert millions to units
      } else if (suffix === "K") {
        return parseFloat(value) * 1000; // Convert thousands to units
      } else {
        return parseFloat(value); // If no suffix, assume it's already in units
      }
    };

    // Determine if the high value has a suffix (M or K)
    const highSuffix = high.includes("M") ? "M" : high.includes("K") ? "K" : "";

    // Strip suffix from the high value before conversion
    const highValue = high.replace(/[MK]/, "");

    // Convert low and high values to unit form, applying the same suffix to both
    const lowInUnits = convertToUnits(low, highSuffix); // Apply the suffix from high to low
    const highInUnits = convertToUnits(highValue, highSuffix); // Convert high value without suffix

    console.log("Low in units:", lowInUnits);
    console.log("High in units:", highInUnits);

    // Calculate the average
    const average = (lowInUnits + highInUnits) / 2;

    // Return the average in units format, no suffix
    return average;
  }

  const {
    property,
    saleProperties,
    soldProperties,
    areaDynamics,
    pieChartData,
    agent,
  } = data || {};
  const { logicalPrice, logicalReasoning } = property || {};
  const { name, email, picture } = agent || {};

  const dataPoints = [
    [
      property.landArea || 0,
      logicalPrice ? getAverageValueInUnits(logicalPrice) : 0,
      property.address,
    ],
    ...(() => {
      // Get today's date
      const today = new Date();

      // Filter soldProperties based on dateListed within the last 90 days
      const recentSoldProperties = soldProperties.filter(({ property }) => {
        const dateListed = new Date(property.dateListed);
        const diffInDays = (today - dateListed) / (1000 * 60 * 60 * 24); // Convert milliseconds to days
        return diffInDays <= 180 && property.landArea && property.price;
      });

      // If there are more than 5 properties listed in the last 90 days, return them
      if (recentSoldProperties.length > 5) {
        return recentSoldProperties.map(({ property }) => [
          property.landArea || 0,
          property.price || 0,
          property.address,
        ]);
      } else {
        // If there are 5 or fewer recent properties, return all sold properties
        return soldProperties.map(({ property }) => [
          property.landArea || 0,
          property.price || 0,
          property.address,
        ]);
      }
    })(),
  ];

  return (
    <>
      <header className="bg-black text-white mb-20 py-24 text-center flex items-center justify-center">
        <img src={ausrealtyLogo} alt="AUSREALTY" />
      </header>
      <div className="container">
        <div className="w-full max-w-4xl mx-auto flex flex-col items-center justify-center text-center space-y-20">
          <h4>{property?.address}</h4>

          <div className="space-y-8">
            <h4>LOGICAL PRICE</h4>

            {/* Price and Info Icon */}
            <div className="flex items-center justify-center gap-4">
              <span className="font-bold">
                {logicalPrice ? logicalPrice : "N/A"}
              </span>
              <Tooltip
                className="w-[300px]"
                text={<i className="fa fa-info-circle text-xs"></i>}
                tooltip="This is just a logical estimated price, and is grounded on a comprehensive set of factors including recent local sales, property size, number of bedrooms, the topography of the land, street traffic, recent updates to the property, and various other determinants. The information is sourced from public datasets which, while extensive, might be incomplete or contain inaccuracies, so should not be solely relied upon. For a more precise and accurate estimation of price, we strongly recommend consulting with a licensed real estate agent or a registered valuer. Occasionally, we may send you updates on the property market"
              />
            </div>

            {/* Logical Reasoning */}
            {logicalReasoning && (
              <div className="text-start my-1 text-sm">
                <span className="font-medium">Reasoning:</span>{" "}
                {logicalReasoning}
              </div>
            )}

            {/* Property Details */}
            <div className="max-w-md mx-auto space-y-6">
              {/* Property Image */}
              <img
                // className="w-full h-auto rounded-lg filter grayscale"
                className="w-full h-auto rounded-lg"
                src={property?.media?.[0]?.url}
                alt="property"
              />

              {/* Property Address */}
              <p className="text-center">{property.address}</p>

              {/* Property Features */}
              <div className="flex items-center justify-center gap-4">
                <div className="text-sm">
                  <i className="fa fa-bed me-2"></i>
                  {property.bedrooms || "N/A"}
                </div>
                <div className="text-sm">
                  <i className="fa fa-bath me-2"></i>
                  {property.bathrooms || "N/A"}
                </div>
                <div className="text-sm">
                  <i className="fa fa-car me-2"></i>
                  {property.carspaces || "N/A"}
                </div>
                <div className="text-sm">{property.propertyType}</div>
              </div>

              {/* Sale Details */}
              <div className="flex justify-between gap-4 text-left text-sm">
                <div className="flex flex-col">
                  <span className="font-semibold">Sale Price:</span>
                  <span>{formatCurrency(property.price)}</span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold">Sale Date:</span>
                  <span>
                    {property.dateListed
                      ? convertDate(property.dateListed)
                      : "N/A"}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold">Days to Sell:</span>
                  <span>{property.daysListed}</span>
                </div>
              </div>
            </div>
          </div>

          {soldProperties && soldProperties.length > 0 && (
            <div class="w-full space-y-8">
              <h4>SOLD MATCHES</h4>
              <Slider {...settings} className="mr-4">
                {soldProperties.map(({ property, score, keyMatches }) => (
                  <div
                    key={property._id}
                    className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                  >
                    <img
                      className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] "
                      src={property?.media?.[0]?.url}
                      alt="property"
                    />
                    <div className="text-center mt-4 space-y-3">
                      <p className="font-semibold">
                        {formatCurrency(property.price)} ({score}%)
                      </p>
                      <p className="text-sm">{property.address}</p>
                      <div className="flex items-center justify-center gap-4">
                        <div className="text-sm">
                          <i className="fa fa-bed me-2"></i>
                          {property.bedrooms || "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-bath me-2"></i>
                          {property.bathrooms || "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-car me-2"></i>
                          {property.carspaces || "N/A"}
                        </div>
                        <div className="text-sm">{property.propertyType}</div>
                      </div>
                      <div className="text-xs text-start space-y-1">
                        <p className="font-semibold">Key Matches</p>
                        <p className="italic">{keyMatches.join(", ")}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}

          {saleProperties && saleProperties.length > 0 && (
            <div class="w-full space-y-8">
              <h4>ON MARKET MATCHES</h4>
              <Slider {...settings} className="mr-4">
                {saleProperties.map(({ property, score, keyMatches }) => (
                  <div
                    key={property._id}
                    className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                  >
                    <img
                      className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] "
                      src={property?.media?.[0]?.url}
                      alt="property"
                    />
                    <div className="text-center mt-4 space-y-3">
                      <p className="font-semibold">
                        {formatCurrency(property.price)} ({score}%)
                      </p>
                      <p className="text-sm">{property.address}</p>
                      <div className="flex items-center justify-center gap-4">
                        <div className="text-sm">
                          <i className="fa fa-bed me-2"></i>
                          {property.bedrooms || "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-bath me-2"></i>
                          {property.bathrooms || "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-car me-2"></i>
                          {property.carspaces || "N/A"}
                        </div>
                        <div className="text-sm">{property.propertyType}</div>
                      </div>
                      <div className="text-xs text-start space-y-1">
                        <p className="font-semibold">Key Matches</p>
                        <p className="italic">{keyMatches.join(", ")}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}

          {soldProperties && soldProperties.length > 0 && (
            <GoogleChart dataPoints={dataPoints} />
          )}

          {pieChartData && (
            <div className="w-full">
              <GooglePieChart data={pieChartData} />
            </div>
          )}

          <div class="w-full max-w-lg mx-auto space-y-8">
            <h4>YOUR AREA DYNAMICS</h4>
            {areaDynamics ? (
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-start border border-mediumgray border-collapse">
                  <thead>
                    <tr className="bg-mediumgray border border-mediumgray">
                      <th className="py-2 px-3 border border-mediumgray"></th>
                      <th className="py-2 px-3 text-left border border-mediumgray">
                        <i className="fa-solid fa-house mr-2"></i> House
                      </th>
                      <th className="py-2 px-3 text-left border border-mediumgray">
                        <i className="fa-solid fa-building mr-2"></i> Unit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b border-mediumgray">
                      <td className="py-2 px-3 border border-mediumgray">
                        Median Sale Price
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.houseStats
                          ? `$${areaDynamics.houseStats.medianSalePrice?.toLocaleString()}`
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.unitStats
                          ? `$${areaDynamics.unitStats.medianSalePrice?.toLocaleString()}`
                          : "N/A"}
                      </td>
                    </tr>
                    <tr className="border-b border-mediumgray">
                      <td className="py-2 px-3 border border-mediumgray">
                        Annual Sales Volume
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.houseStats
                          ? areaDynamics.houseStats.annualSalesVolume
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.unitStats
                          ? areaDynamics.unitStats.annualSalesVolume
                          : "N/A"}
                      </td>
                    </tr>

                    <tr className="border-b border-mediumgray">
                      <td className="py-2 px-3 border border-mediumgray">
                        Suburb Growth
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.houseStats
                          ? areaDynamics.houseStats.suburbGrowth
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.unitStats
                          ? areaDynamics.unitStats.suburbGrowth
                          : "N/A"}
                      </td>
                    </tr>
                    <tr className="border-b border-mediumgray">
                      <td className="py-2 px-3 border border-mediumgray">
                        High Demand Area
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.houseStats
                          ? areaDynamics.houseStats.highDemandArea
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.unitStats
                          ? areaDynamics.unitStats.highDemandArea
                          : "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <i className="fa-solid fa-spinner animate-spin mr-2"></i>{" "}
                Loading ...
              </div>
            )}
          </div>

          <GoogleMaps lat={property?.latitude} lon={property?.longitude} />

          <div class="space-y-8 flex flex-col">
            <img src={picture} alt="Agent" className="max-h-[400px]" />
            <p>{name}</p>
            <p>{email}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickSearchShare;
