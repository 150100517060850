import React, { useEffect, useState, useRef, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "utils/axios";
import Button from "components/ui/Button";
import { AuthContext } from "context/AuthContext";
import GooglePlacesAutocomplete from "components/GooglePlacesAutoComplete";
import EditableField from "components/ui/EditableField";
import { PDFDownloadLink } from "@react-pdf/renderer";
import RentalAppraisalPDF from "./RentalAppraisalPdf";
import { pdf } from '@react-pdf/renderer';

const fetchImageAsBase64 = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl, { mode: 'cors' });
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // Data URI
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error fetching image as base64:', error);
    return null;
  }
};


const RentalAppraisal = ({ isRentalAppraisal }) => {
  const { user } = useContext(AuthContext);

  const [messages, setMessages] = useState([
    {
      role: "system",
      content: "", // Start with an empty string
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const messagesContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [price, setPrice] = useState(null);

  const [signatureData, setSignatureData] = useState(null);

  useEffect(() => {
    const fetchSignatureUrl = async () => {
      try {
        const response = await axios.get("/profile/get-signature-url");
        if (response.data.success) {
          const imageUrl = response.data.data;
          const base64Image = await fetchImageAsBase64(imageUrl);
          setSignatureData(base64Image);
        }
      } catch (error) {
        console.error("Error fetching signature URL:", error);
      }
    };

    fetchSignatureUrl();
  }, []);

  const [logoData, setLogoData] = useState(null);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const imageUrl = "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/ausrealty-logo+(1).jpg";
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result; // base64 data URI
          setLogoData(base64data);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error fetching logo image:', error);
      }
    };

    fetchLogo();
  }, []);

  useEffect(() => {
    // Apply the necessary styles for Google Places Autocomplete dropdown positioning
    const style = document.createElement("style");
    style.textContent = `
      .pac-container.pac-logo {
        position: absolute !important;
        bottom: 60px !important;
        top: auto !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    // Apply type riter effect to the first message
    const initialMessage = "Please provide address";
    typewriterEffect(initialMessage, 0);
    isRentalAppraisal(false);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    resizeTextarea();
  }, [description]);

  const chatgptAPICall = async (input) => {
    try {
      const response = await axios.post(`/openai/text`, {
        systemPrompt: "RENTAL_APPRAISAL_PROMPT",
        userMessage: input,
      });

      if (response.data.success) {
        return response.data.data;
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      console.log(errorMessage);
    }
  };


  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  

  const handleSend = async (addressParam) => {
    try {
      // Prevent event object from being used as addressParam
      if (addressParam && addressParam.preventDefault) {
        addressParam = undefined;
      }

      const addressToUse = String(addressParam || selectedAddress || "");

      if (messages.length === 1) {
        if (!addressToUse.trim()) {
          toast.error("Please type or select an address");
          return;
        }

        const userMessage = {
          role: "user",
          content: addressToUse.trim(),
        };

        const secondQuestion =
          "Could you please provide the property type, number of bedrooms, bathrooms, and whether it has been renovated?";

        // Update the messages state and then access the latest state for further processing
        setMessages((prevMessages) => {
          const updatedMessages = [
            ...prevMessages,
            userMessage,
            { role: "system", content: secondQuestion },
          ];

          // Trigger the typewriter effect immediately
          setTimeout(() => {
            typewriterEffect(secondQuestion, updatedMessages.length - 1);
          }, 0);

          return updatedMessages;
        });

        return;
      }

      if (!description.trim()) {
        toast.error("Please type property description");
        return;
      }

      const userMessage = {
        role: "user",
        content: description.trim(),
      };

      const thankyouMessage = "Thank you for providing the details.";

      // Update the messages state and then access the latest state for further processing
      setMessages((prevMessages) => {
        const updatedMessages = [
          ...prevMessages,
          userMessage,
          { role: "system", content: thankyouMessage },
        ];

        // Trigger the typewriter effect immediately
        setTimeout(() => {
          typewriterEffect(thankyouMessage, updatedMessages.length - 1);
        }, 0);

        // Make the API call here after messages state is fully updated
        handleAPICall(updatedMessages);

        return updatedMessages;
      });

      setDescription("");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
    }
  };

  const handleAPICall = async (updatedMessages) => {
    try {
      setLoading(true);

      const combinedMessage = `${updatedMessages[1]?.content} ${updatedMessages[3]?.content}`;
      const result = await chatgptAPICall(combinedMessage);
      if (!result) {
        throw new Error("Error in response");
      }
      setPrice(result);
      isRentalAppraisal(true);
      // Handle the API response here if needed
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (loading) {
        e.preventDefault();
        return;
      }
      e.preventDefault();
      handleSend();
    }
  };

  const typewriterEffect = (text, index) => {
    let charIndex = 0;
    const interval = setInterval(() => {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        if (charIndex < text.length) {
          updatedMessages[index].content = text.slice(0, charIndex + 1); // Add the character at the current index
          charIndex++;
        }
        return updatedMessages;
      });

      if (charIndex === text.length) {
        clearInterval(interval);
      }
    }, 30);
  };

  const resizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset the height to auto to calculate the new height correctly

      // Attempt to get the computed line height, fallback to a default value if not available
      let lineHeight = parseInt(
        window.getComputedStyle(textarea).lineHeight,
        10
      );
      if (isNaN(lineHeight)) {
        lineHeight = 26.8; // Fallback to a default line height (in pixels)
      }

      const maxRows = 5;
      const maxHeight = lineHeight * maxRows;

      // Apply the max height with overflow handling
      if (textarea.scrollHeight > maxHeight) {
        textarea.style.height = `${maxHeight}px`;
        textarea.style.overflowY = "auto"; // Allow scrolling if content exceeds max height
      } else {
        textarea.style.height = `${textarea.scrollHeight}px`;
        textarea.style.overflowY = "hidden"; // Hide overflow when within limits
      }
    }
  };

  const handleAddressSelect = (address) => {
    console.log(address);
    setSelectedAddress(address);
    handleSend(address); // Trigger send functionality with the selected address
  };

  const messagesEndRef = useRef(null);

  const generatePdfBlob = async () => {
    const doc = (
      <RentalAppraisalPDF
        selectedAddress={selectedAddress}
        price={price}
        user={user}
        signature={signatureData}
        logo={logoData}
      />
    );
  
    // Use the pdf() function to create an instance
    const asPdf = pdf();
    asPdf.updateContainer(doc);
  
    // Generate the PDF Blob
    const blob = await asPdf.toBlob();
    return blob;
  };
  
  const handleShare = async () => {
    if (!navigator.share) {
      toast.error('Sharing is not supported in your browser.');
      return;
    }
  
    try {
      setLoading(true);
  
      const blob = await generatePdfBlob();
  
      const file = new File([blob], 'rental_appraisal.pdf', { type: 'application/pdf' });
  
      await navigator.share({
        title: 'Rental Appraisal',
        text: 'Please find the rental appraisal attached.',
        files: [file],
      });
  
      toast.success('PDF shared successfully.');
    } catch (error) {
      console.error('Error sharing PDF:', error);
      toast.error('An error occurred while sharing the PDF.');
    } finally {
      setLoading(false);
    }
  };
  
  if (price) {
    return (
      <div className="max-w-4xl mx-auto pb-28">
        <div className="flex gap-2 justify-end">
          <Button onClick={handleShare} loading={loading}className="gray-button">Share</Button>
          <PDFDownloadLink
            document={
              <RentalAppraisalPDF
                selectedAddress={selectedAddress}
                price={price}
                user={user}
                signature={signatureData}
                logo={logoData}
              />
            }
            fileName="rental_appraisal.pdf"
            className="black-button"
          >
            {({ loading }) =>
              loading ? "Loading document..." : "Download Pdf"
            }
          </PDFDownloadLink>
        </div>
        {/* This content should come in react pdf */}
        <div className="space-y-8 text-left">
          <p>
            {new Date().toLocaleDateString("en-AU", {
              timeZone: "Australia/Sydney",
            })}
          </p>

          <p>To Whom It May Concern</p>

          <p>
            PROPERTY:{" "}
            <strong>
              <EditableField
                value={selectedAddress}
                onSave={setSelectedAddress}
              />
            </strong>
          </p>

          <p>
            In appraising the above mentioned property, we have taken into
            account several items such as:
          </p>

          <ul>
            <li>Properties recently leased within the area</li>
            <li>Properties we are currently marketing</li>
            <li>Our knowledge of the local area</li>
            <li>Current leasing enquiry</li>
          </ul>

          <p>
            A statistical leasing price for your property in the current market
            is{" "}
            <strong>
              <EditableField value={price} onSave={setPrice} />
            </strong>
            per week.
          </p>

          <p>
            Although care has been taken in arriving at this figure, we note
            that this is an opinion only, of what would be a reasonable rent
            attainable and is not to be taken as a formal valuation of the
            rental. If a formal valuation of the rental is required, a
            registered Valuer should be retained.
          </p>

          <p>
            Please feel free to contact our office if you have any further
            queries.
          </p>

          <p>Yours faithfully,</p>

          <img src={signatureData} alt="Signature" className="w-32" />

          <div>
            <p>{user.name}</p>

            <p>{user.title}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <ToastContainer />

      <div className="w-full max-w-4xl mx-auto flex flex-col flex-grow">
        <div
          id="msg"
          ref={messagesContainerRef}
          className="enhanced-textarea overflow-y-auto p-3 pl-0 pb-16"
        >
          {messages.map((message, index) => (
            <div
              key={index}
              className={`mb-4 ${
                message.role === "system" ? "text-left" : "text-right"
              }`}
            >
              <span
                className={`inline-block p-2 max-w-[80%] rounded-lg ${
                  message.role === "system"
                    ? "bg-white rounded-br-none"
                    : "text-start bg-gray-200 rounded-bl-none"
                }`}
              >
                <pre>{message.content}</pre>
              </span>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <div className="w-full fixed bottom-0 left-0 right-0 px-6 pt-2.5 pb-8 z-50 bg-white">
        <div className="w-full max-w-4xl mx-auto flex gap-2 items-end justify-center relative">
          {messages.length === 1 ? (
            <GooglePlacesAutocomplete
              onSelectAddress={handleAddressSelect}
              isNSWCheck={false}
              address={selectedAddress}
              setAddress={setSelectedAddress}
            />
          ) : (
            <textarea
              ref={textareaRef}
              value={description}
              onChange={handleDescriptionChange}
              onKeyPress={handleKeyPress}
              placeholder="Type here..."
              className="flex-grow p-2 bg-white border border-mediumgray rounded outline-none focus:outline-none resize-none overflow-y-hidden min-h-[38px]"
              rows="1"
            />
          )}
          <Button
            onClick={() => handleSend()}
            loading={loading}
            className="black-button h-[38px]"
          >
            <i className="fa-solid fa-location-arrow"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RentalAppraisal;
