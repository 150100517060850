import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { VscMenu } from "react-icons/vsc";
import { RxCross1 } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import { FaRegCircleUser } from "react-icons/fa6";
import { AiOutlineLogout } from "react-icons/ai";
import { HiChevronDown } from "react-icons/hi2";
import { CiMenuBurger } from "react-icons/ci";
import axios from "utils/axios";
import debounce from "lodash/debounce";
import logo from "assets/images/logo.png";
import "assets/css/style.css";
import { AuthContext } from "context/AuthContext";
import Button from "./Button";
import profileImg from "assets/images/profile (1).png";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";

const logout = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/auth/logout`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (response.ok) {
      window.location.href = "/login";
    } else {
      console.error("Logout failed.");
    }
  } catch (error) {
    console.error("An error occurred during logout:", error);
  }
};

const HowItWorks = () => {
  const listItems = [
    "- Assess logical price",
    "- Choose Process",
    "- Communicate to the owner and buyer",
    "- Sets you free",
  ];

  const [visibleItems, setVisibleItems] = useState([]);

  const handleClick = () => {
    // Clear visible items before starting
    setVisibleItems([]);

    listItems.forEach((item, index) => {
      setTimeout(() => {
        setVisibleItems((prevItems) => [...prevItems, item]);
      }, index * 500); // Delay each item by 500ms
    });
  };

  return (
    <div>
      <h6 onClick={handleClick} className="cursor-pointer">
        How it works
      </h6>
      <ul className="m-0 my-2">
        {visibleItems.map((item, index) => (
          <li key={index} className="list-none">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Navbar = () => {
  const location = useLocation();

  // Function to generate URLs with updated 'tab' parameter
  const generateTabUrl = (tabName) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", tabName);
    return `${location.pathname}?${searchParams.toString()}`;
  };

  const { user } = useContext(AuthContext);
  const { name, email, picture } = user || {};

  const [chats, setChats] = useState([]);

  const [bookedAppraisalChats, setBookedAppraisalChats] = useState([]);
  const [appraisedChats, setAppraisedChats] = useState([]);
  const [agreements, setAgreements] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [sidebarLoading, setSidebarLoading] = useState(false);
  const [isShortCutDropdown, setIsShortCutDropdown] = useState(false);
  const [isShortCutIcon, setIsShortCutIcon] = useState(false);
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const profileDropdownRef = useRef(null);
  const shortcutDropdownRef = useRef(null);

  const checkRouteForChat = () => {
    const chatPattern = /^\/chat\/[^/]+$/;
    setIsShortCutIcon(chatPattern.test(location.pathname));
  };

  const fetchChats = async () => {
    try {
      setSidebarLoading(true);

      const response = await axios.get("/bookings");

      if (response?.data?.success) {
        setChats(response.data.data);
        filterChats(response.data.data);
        setSidebarLoading(false);
      }
    } catch (error) {
      setSidebarLoading(false);
      console.error("Error fetching chats:", error);
    }
  };

  const filterChats = (chats) => {
    const lowercasedQuery = searchQuery.toLowerCase();

    setBookedAppraisalChats(
      chats.filter(
        (chat) =>
          chat.status === "Active" &&
          chat.address.toLowerCase().includes(lowercasedQuery)
      )
    );

    setAppraisedChats(
      chats.filter(
        (chat) =>
          chat.status === "Completed" &&
          chat.address.toLowerCase().includes(lowercasedQuery)
      )
    );
  };

  const fetchAgreements = async () => {
    try {
      setSidebarLoading(true);

      const response = await axios.get("/authSchedule");

      if (response.data.success) {
        setAgreements(response.data.data);
        setSidebarLoading(false);
      }
    } catch (error) {
      setSidebarLoading(false);
      console.error("Error fetching chats:", error);
    }
  };

  const debouncedFilterChats = debounce(() => {
    filterChats(chats);
  }, 300);

  const shortCutDropdown = () => {
    setIsShortCutDropdown(!isShortCutDropdown);
    setIsProfileDropdown(false); // Close the profile dropdown when opening shortcut dropdown
  };

  const profileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
    setIsShortCutDropdown(false); // Close the shortcut dropdown when opening profile dropdown
  };

  const handleTogglerClick = () => {
    const newSidebarState = !isSidebarOpen;
    setIsSidebarOpen(newSidebarState);

    // Call the API to fetch chats only if the sidebar is being opened
    if (newSidebarState) {
      fetchChats();
      fetchAgreements();
    }
  };

  const handleClickOutside = (event) => {
    if (
      profileDropdownRef.current &&
      !profileDropdownRef.current.contains(event.target)
    ) {
      setIsProfileDropdown(false);
    }
    if (
      shortcutDropdownRef.current &&
      !shortcutDropdownRef.current.contains(event.target)
    ) {
      setIsShortCutDropdown(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchChats();
    }
  }, [user]);

  useEffect(() => {
    checkRouteForChat();
  }, [location]);

  useEffect(() => {
    debouncedFilterChats();
  }, [searchQuery]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-white sticky top-0" style={{ zIndex: 1001 }}>
      <div className="container flex items-center justify-between py-6">
        <Button onClick={handleTogglerClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30"
            height="30"
          >
            <path
              stroke="rgba(33, 37, 41, 0.75)"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
              d="M4 7h22M4 15h22M4 23h22"
            />
          </svg>
        </Button>

        {/* Logo */}
        <div className="navbar-image flex items-center gap-4">
          <Link to="/">
            <img src={logo} alt="Beleef" className="w-[150px] h-auto" />
          </Link>
          {isShortCutIcon && (
            <HiChevronDown
              onClick={shortCutDropdown}
              className="text-sm cursor-pointer"
            />
          )}
          {isShortCutDropdown && (
            <div className="shortcut-dropdown-menu" ref={shortcutDropdownRef}>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("open-home")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Open Home
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("my-sign")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                My Sign
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("compliance")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Compliance
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("comms-log")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Comms Log
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("data-reports")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Data Reports
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("negotiation")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Negotiation
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("rental-appraisal")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Rental Appraisal
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("notifications")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Notifications
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("area-insights")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Area Insights
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("toolkit")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Toolkit
              </Link>
            </div>
          )}
        </div>

        {/* User Authentication */}
        {!user ? (
          <Link to="/login" className="text-sm">
            Sign In
          </Link>
        ) : (
          <div className="relative">
            <img
              // src={picture || profileImg}
              src={`${picture}?${new Date().getTime()}` || profileImg}
              className="w-8 h-8 rounded-full cursor-pointer profile"
              alt="Profile"
              onClick={profileDropdown}
            />
            {isProfileDropdown && (
              <div className="profile-dropdown-menu" ref={profileDropdownRef}>
                <div className="flex items-center mb-4 px-2">
                  <img
                    src={`${picture}?${new Date().getTime()}` || profileImg}
                    className="w-10 h-10 rounded-full object-fill"
                    alt="Profile"
                  />
                  <div className="ml-3">
                    <p className="truncate w-44 text-sm font-semibold mb-0">
                      {name}
                    </p>
                    <p className="truncate w-44 text-sm text-darkergray mb-0">
                      {email}
                    </p>
                  </div>
                </div>
                <hr className="my-1" />
                <Link className="profile-dropdown-item gap-2" to="/profile">
                  <FaRegCircleUser />
                  <span>Profile</span>
                </Link>
                <Link className="profile-dropdown-item gap-2" onClick={logout}>
                  <AiOutlineLogout />
                  Logout
                </Link>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 bg-white shadow-lg w-full max-w-[400px] transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-40`}
      >
        <div
          className="mt-4 flex justify-end pr-4 cursor-pointer"
          onClick={() => setIsSidebarOpen(false)}
        >
          <RxCross1 />
        </div>
        <div className="flex flex-col p-4">
          <HowItWorks />

          <h6 className="mt-4">Community Marketing</h6>

          {sidebarLoading ? (
            <div className="flex items-center justify-center py-8">
              <i className="fa-solid fa-spinner animate-spin text-lg"></i>
            </div>
          ) : (
            <div>
              <div className="relative py-4">
                <input
                  className="search-input bg-lightgray rounded-md w-full py-2 pr-3 pl-8"
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <IoSearchOutline className="text-sm text-darkergray" />
                </div>
              </div>

              <Disclosure as="div" className="w-full" defaultOpen={false}>
                <DisclosureButton className="group flex w-full items-center justify-between">
                  <span className="text-sm text-darkergray">
                    Booked Appraisal
                  </span>
                  <HiChevronDown className="text-xs group-data-[open]:rotate-180" />
                </DisclosureButton>
                <DisclosurePanel className="mt-2">
                  <ul className="list-none m-0">
                    {bookedAppraisalChats.map((chat) => (
                      <li
                        key={chat.id}
                        className="text-xs my-2 flex justify-between items-center"
                      >
                        <Link
                          className="block pl-4 "
                          to={`/chat/${encodeURIComponent(chat.address)}?tab=book-appraisal`}
                          onClick={() => setIsSidebarOpen(false)}
                        >
                          {chat.address}
                        </Link>
                        <Link
                          className="flex gap-2 items-center"
                          to={chat?.prelistLink}
                          onClick={() => setIsSidebarOpen(false)}
                        >
                          {chat?.prelistViewCount || 0}
                          <i class="fa-regular fa-eye text-xs"></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </DisclosurePanel>
              </Disclosure>

              <br></br>

              <Disclosure as="div" className="w-full" defaultOpen={false}>
                <DisclosureButton className="group flex w-full items-center justify-between">
                  <span className="text-sm text-darkergray">Appraised</span>
                  <HiChevronDown className="text-xs group-data-[open]:rotate-180" />
                </DisclosureButton>
                <DisclosurePanel className="mt-2">
                  <ul className="list-none m-0">
                    {appraisedChats.map((chat) => (
                      <li
                        key={chat.id}
                        className="text-xs my-2 flex justify-between items-center"
                      >
                        <Link
                          className="block pl-4 "
                          to={`/chat/${encodeURIComponent(chat.address)}?tab=book-appraisal`}
                          onClick={() => setIsSidebarOpen(false)}
                        >
                          {chat.address}
                        </Link>
                        <Link
                          className="flex gap-2 items-center"
                          to={chat?.prelistLink}
                          onClick={() => setIsSidebarOpen(false)}
                        >
                          {chat?.prelistViewCount || 0}
                          <i class="fa-regular fa-eye text-xs"></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </DisclosurePanel>
              </Disclosure>

              <br></br>

              <Disclosure as="div" className="w-full" defaultOpen={false}>
                <DisclosureButton className="group flex w-full items-center justify-between">
                  <span className="text-sm text-darkergray">Agreements</span>
                  <HiChevronDown className="text-xs group-data-[open]:rotate-180" />
                </DisclosureButton>
                <DisclosurePanel className="mt-2">
                  <ul className="list-none m-0">
                    {agreements.map((agreement) => (
                      <li
                        key={agreement.id}
                        className="text-xs my-2 flex justify-between items-center"
                      >
                        <Link
                          className="block pl-4 "
                          to={`/chat/${agreement.address}?tab=authorise-and-schedule`}
                          onClick={() => setIsSidebarOpen(false)}
                        >
                          {agreement.address}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </DisclosurePanel>
              </Disclosure>
            </div>
          )}
        </div>
      </div>

      {/* Sidebar Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </nav>
  );
};

export default Navbar;
