import React, { useState, useEffect } from "react";
import axios from "axios";

const CommsLog = ({property}) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(
          "http://54.253.83.175/api/messages/120363359105466530@g.us"
        );

        if (response.status === 200) {
          setMessages(response.data.messages);
        } else {
          console.error("Failed to fetch messages");
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
        // Fallback to dummy data if API request fails
        setMessages([
          {
            sender: "Sandy",
            senderNumber: "61 404 693 073",
            message: "Hi Team, Welcome! We have created this group to ensure smooth communication. Looking forward to achieving the maximum outcome. Regards, Sandy",
            timestamp: 1730868454,
          }
        ]);
      }
    };

    fetchMessages();
  }, []);

  return (
    <div className="container">
      <p className="mb-4 text-darkgray text-center">{property.address}</p>
      <h4 className="text-center mb-4">WhatsApp</h4>
      <div className="w-full max-w-md mx-auto">
        {messages.map((msg, index) => (
          <div key={index} className="flex items-start mb-4">
            <div className="bg-lightergray p-3 rounded-md shadow-sm w-full">
              <div className="flex justify-between">
                <p className="text-xs text-darkergray font-bold">
                  {msg.sender}
                </p>
                <p className="text-xs text-darkergray">
                  {msg.senderNumber}
                </p>
              </div>

              <p className="text-sm mt-2">{msg.message}</p>
              <p className="text-right text-xs text-darkgray mt-2">
                {new Date(msg.timestamp * 1000).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommsLog;
