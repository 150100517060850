import { useState, useEffect } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { HiChevronDown } from "react-icons/hi2";
import { formatCurrency } from "utils/helperFunctions";
import axios from "utils/axios";
import Button from "./Button";
import Calendar from "react-calendar";
import Modal from "./Modal";
import PopupModal from "./ShoppingCard";
import ShoppingCard from "./ShoppingCard";
import { BiChevronDown } from "react-icons/bi";

const MarketingPrice = ({
  price,
  suburb,
  tab = "authorise-and-schedule",
  onConfirm,
  existingData,
  isEdit = "true",
}) => {
  const [categories, setCategories] = useState([]);
  const [agentContribution, setAgentContribution] = useState(
    existingData?.agentContribution?.amount || "$0"
  );

  const [viewCart, setViewCart] = useState(false);
  const [isAgentContributionChecked, setIsAgentContributionChecked] = useState(
    existingData?.agentContribution?.isChecked || false
  );

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Check if isEdit is true before fetching categories
    if (!isEdit) {
      setCategories(existingData?.categories || []); // Set categories directly from existingData
      setViewCart(true);
      return; // Exit the useEffect to prevent API call
    }

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`/marketingPrice/${price}/${suburb}`);
        if (response.data.success) {
          const initializedCategories = response.data.data.categories.map(
            (fetchedCategory) => {
              const existingCategory = existingData?.categories?.find(
                (cat) => cat.category === fetchedCategory.category
              );

              return {
                ...fetchedCategory,
                items: fetchedCategory.items.map((fetchedItem, index) => {
                  const existingItem = existingCategory?.items?.find(
                    (item) => item._id === fetchedItem._id
                  );

                  return {
                    ...fetchedItem,
                    isChecked: existingItem
                      ? existingItem.isChecked // Retain the checked status if it exists
                      : fetchedCategory.category === "Internet Portals"
                      ? index < 2 // Default: first two items checked for "Internet Portals"
                      : false, // Default: unchecked for other categories
                  };
                }),
              };
            }
          );
          setCategories(initializedCategories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();

    if (existingData?.total) {
      setViewCart(true);
    }
  }, [price]);

  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       const response = await axios.get(`/marketingPrice/${price}/${suburb}`);
  //       if (response.data.success) {
  //         const initializedCategories = response.data.data.categories.map(
  //           (category) => {
  //             // Check if the category is "Internet Portals"
  //             if (category.category === "Internet Portals") {
  //               return {
  //                 ...category,
  //                 items: category.items.map((item, index) => ({
  //                   ...item,
  //                   // Set the first two items to isChecked: true by default
  //                   isChecked: index < 2 ? true : false,
  //                 })),
  //               };
  //             } else {
  //               return {
  //                 ...category,
  //                 items: category.items.map((item) => ({
  //                   ...item,
  //                   isChecked: false,
  //                 })),
  //               };
  //             }
  //           }
  //         );
  //         setCategories(initializedCategories);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching categories:", error);
  //     }
  //   };

  //   if (existingData?.categories) {
  //     setCategories(existingData?.categories); // Initialize categories with existing data
  //   } else {
  //     fetchCategories(); // Fetch categories if no existing data
  //   }

  //   if (existingData?.total) {
  //     setViewCart(true);
  //   }
  // }, [existingData]);

  // Handle checkbox change
  const handleCheckboxChange = (id) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => ({
        ...category,
        items: category.items.map((item) =>
          item._id === id ? { ...item, isChecked: !item.isChecked } : item
        ),
      }))
    );
    console.log(categories);
  };

  const handlePriceChange = (id, e) => {
    const value = e.target.value;
    const numericValue = value.split("$")[1]?.replace(/,/g, "") || "0";
    const parsedValue = Math.max(0, parseFloat(numericValue) || 0);

    setCategories((prevCategories) =>
      prevCategories.map((category) => ({
        ...category,
        items: category.items.map((item) =>
          item._id === id
            ? { ...item, price: formatCurrency(parsedValue) }
            : item
        ),
      }))
    );
  };
  const handleAgentContributionChange = (e) => {
    const value = e.target.value;
    // Use split to remove the dollar sign and extract numeric part
    const numericValue = value.split("$")[1]?.replace(/,/g, "") || "0"; // Get numeric part after the dollar sign
    const parsedValue = Math.max(0, parseFloat(numericValue) || 0); // Parse the number and ensure it's non-negative
    setAgentContribution(formatCurrency(parsedValue));
  };

  const handleAgentContributionCheckboxChange = () => {
    setIsAgentContributionChecked(!isAgentContributionChecked);
  };

  // Calculate total price for selected items
  const totalPrice = categories.reduce(
    (total, category) =>
      total +
      category.items.reduce((catTotal, item) => {
        // Safely handle item.price
        const priceString =
          typeof item.price === "string"
            ? item.price
            : item.price?.toString() || "0"; // Convert to string if it's a number
        const numericPrice =
          parseFloat(priceString.replace(/^\$/, "").replace(/,/g, "")) || 0; // Ensure price is numeric
        return item.isChecked ? catTotal + numericPrice : catTotal; // Add to total if checked
      }, 0),
    0
  );
  // Apply agent contribution if applicable
  const finalTotal = Math.max(
    0,
    isAgentContributionChecked
      ? totalPrice -
          parseFloat(agentContribution.replace(/^\$/, "").replace(/,/g, ""))
      : totalPrice // Extract numeric value from formatted agent contribution
  );
  const handleConfirmClick = () => {
    onConfirm({
      categories,
      agentContribution: {
        amount: agentContribution,
        isChecked: isAgentContributionChecked,
      },
      total: finalTotal,
    });
    setViewCart(true); // Set view to cart
  };

  function importAllImages(requireContext) {
    const images = requireContext.keys().map(requireContext);

    // Use a Set to ensure only unique images are used (by file path)
    const uniqueImages = new Set(images.map((image) => image.default || image));

    return [...uniqueImages];
  }

  function importAllVideos(requireContext) {
    const videos = requireContext.keys().map(requireContext);

    // Use a Set to ensure only unique videos are used (by file path)
    const uniqueVideos = new Set(videos.map((video) => video.default || video));

    return [...uniqueVideos];
  }

  const brochure8Folder1 = importAllImages(
    require.context(
      "assets/images/BROCHURE 8 PG/26-765 Princes Highway, Blakehurst",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const brochure8Folder2 = importAllImages(
    require.context(
      "assets/images/BROCHURE 8 PG/4 5 B E L M O R E R O A D , P E A K H U R S T",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const brochure16Folder1 = importAllImages(
    require.context(
      "assets/images/BROCHURE 16 PG/45 BELMORE",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const droneFolder1 = importAllImages(
    require.context("assets/images/DRONE PHOTOS", false, /\.(png|jpe?g|svg)$/)
  );
  const duskFolder1 = importAllImages(
    require.context("assets/images/DUSK PHOTOS", false, /\.(png|jpe?g|svg)$/)
  );
  const floorplanFolder1 = importAllImages(
    require.context("assets/images/FLOORPLAN", false, /\.(png|jpe?g|svg)$/)
  );
  const photosFolder1 = importAllImages(
    require.context("assets/images/PHOTOS", false, /\.(png|jpe?g|svg)$/)
  );
  const socialMediaFolder1 = importAllImages(
    require.context("assets/images/SOCIAL MEDIA", false, /\.(png|jpe?g|svg)$/)
  );
  const mailCardsFolder = importAllImages(
    require.context("assets/images/MAILCARDS", false, /\.(png|jpe?g|svg)$/)
  );
  const signboardFolder1 = importAllImages(
    require.context(
      "assets/images/SIGNBOARDS/STANDARD",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const signboardFolder2 = importAllImages(
    require.context(
      "assets/images/SIGNBOARDS/ILLUMINATED",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const auctioneerFolder = importAllImages(
    require.context("assets/images/AUCTIONEERS", false, /\.(png|jpe?g|svg)$/)
  );
  // const videosFolder = importAllVideos(
  //   require.context(
  //     "assets/images/VIDEO STANDARD", // Your video folder path
  //     false,
  //     /\.(mp4|webm|ogg)$/
  //   )
  // );

  // const storyFolder = importAllVideos(
  //   require.context(
  //     "assets/images/VIDEO STORYTELLING", // Your video folder path
  //     false,
  //     /\.(mp4|webm|ogg)$/
  //   )
  // );

  const payLaterTotal = Math.max(
    0,
    (finalTotal +
      (isAgentContributionChecked
        ? parseFloat(agentContribution.replace(/^\$/, "").replace(/,/g, ""))
        : 0)) *
      1.069
  ).toFixed(2);

  const folderBasedOnName = (name) => {
    if (name?.includes("mail")) {
      return mailCardsFolder;
    }
    if (name?.includes("brochure")) {
      return brochure8Folder1;
    }
    if (name?.includes("drone")) {
      return droneFolder1;
    }
    if (name?.includes("dusk")) {
      return duskFolder1;
    }
    if (name?.includes("floor")) {
      return floorplanFolder1;
    }
    if (name?.includes("photo")) {
      return photosFolder1;
    }
    if (name?.includes("social")) {
      return [socialMediaFolder1[2], socialMediaFolder1[3]];
    }
    if (name?.includes("video")) {
      return [
        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-standard/1.mp4",
        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-standard/2.mp4",
        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-standard/3.mp4",
      ];
    }
    if (name?.includes("story")) {
      return [
        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-storytelling/1.mp4",
        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-storytelling/2.mp4",
        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-storytelling/3.mp4",
        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-storytelling/4.mp4",
      ];
    }
    if (name?.includes("social")) {
      return socialMediaFolder1;
    }
    if (name?.includes("signboards")) {
      return signboardFolder1?.concat(signboardFolder2);
    }
    if (name?.includes("auctioneer")) {
      return auctioneerFolder;
    }
  };
  return (
    <div className="w-full mx-auto relative">
      {!viewCart ? (
        <>
          <table className="w-full flex justify-center border-lightgray text-xs">
            <tbody>
              <tr className="grid grid-cols-2 gap-4 w-full">
                {categories.map((category, categoryIndex) => (
                  <ShoppingCard
                    key={categoryIndex}
                    category={category}
                    handleCheckboxChange={handleCheckboxChange}
                    handlePriceChange={handlePriceChange}
                    formatCurrency={formatCurrency}
                    thumbnailFolder={folderBasedOnName(
                      category?.category?.toLowerCase()
                    )}
                    isVideo={
                      category?.category?.toLowerCase().includes("video") ||
                      category?.category?.toLowerCase().includes("story")
                    }
                  />
                ))}
              </tr>

              <tr
                className="w-full hover:bg-lightgray border-b border-mediumgray flex items-center justify-between cursor-pointer"
                // onClick={handleAgentContributionCheckboxChange} // Toggle on row click
              >
                <td className="py-2 px-3 text-xs w-[200px]">
                  Agent Contribution
                </td>
                <div className="flex items-center">
                  <td className="py-2 px-3 text-xs">
                    <input
                      type="text"
                      className="form-input border border-mediumgray text-right"
                      placeholder="$0"
                      value={agentContribution}
                      onChange={handleAgentContributionChange}
                    />
                  </td>
                  <td className="py-2 px-3">
                    <input
                      type="checkbox"
                      checked={isAgentContributionChecked}
                      onChange={handleAgentContributionCheckboxChange} // Toggle when clicked
                      onClick={(e) => e.stopPropagation()} // Prevent row click event
                    />
                  </td>
                </div>
              </tr>

              <tr className="hover:bg-lightgray font-semibold flex items-center justify-between">
                <td className="py-2 px-3 text-center text-xs">TOTAL</td>
                <td className="py-2 px-3 text-center text-xs">
                  {formatCurrency(finalTotal)}
                </td>
              </tr>
            </tbody>
          </table>

          {isEdit && (
            <div className="flex justify-end mt-4">
              <Button onClick={handleConfirmClick} className="black-button">
                Confirm
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          {/* Shopping Cart Modal */}
          <div className="overflow-x-auto">
            <table className="w-full border text-xs">
              <tbody>
                {categories.flatMap((category) =>
                  category.items
                    .filter((item) => item.isChecked)
                    .map((item, index) => {
                      // Safely handle item.price
                      const priceString =
                        typeof item.price === "string"
                          ? item.price
                          : item.price?.toString() || "0"; // Convert to string if it's a number
                      const numericPrice =
                        parseFloat(
                          priceString.replace(/^\$/, "").replace(/,/g, "")
                        ) || 0; // Ensure price is numeric

                      return (
                        <tr key={item._id}>
                          <td className="border px-4 py-2 text-xs">
                            {item.name}
                          </td>
                          <td className="border px-4 py-2 text-xs">
                            {formatCurrency(numericPrice)}{" "}
                            {/* Format numeric price */}
                          </td>
                        </tr>
                      );
                    })
                )}
                {isAgentContributionChecked && agentContribution !== "$0" && (
                  <tr>
                    <td className="border px-4 py-2 text-xs">
                      Agent Contribution
                    </td>
                    <td className="border-b px-4 py-2 text-xs">
                      - {agentContribution}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="border px-4 py-2 font-bold text-xs">TOTAL</td>
                  <td className="px-4 py-2 text-xs">
                    {formatCurrency(finalTotal)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {isEdit && (
            <div className="mt-4 flex justify-between">
              <div className="w-full flex flex-col">
                <p>
                  Pay Later <span className="italic text-xs">(6.9%)</span>
                </p>
                {finalTotal && <p>{formatCurrency(payLaterTotal)}</p>}
              </div>

              <div className="w-full flex justify-end">
                <Button
                  onClick={() => setViewCart(false)}
                  className="gray-button"
                >
                  Continue Shopping
                </Button>
                {/* <Button
                  onClick={() => handleDeferClick("yes")}
                  className="black-button"
                >
                  Defer
                </Button> */}
              </div>
            </div>
          )}

          {/* Defer Options - Yes/No */}
          {/* {deferOption === "yes" && (
            <div className="mt-4 relative">
              <i
                onClick={handleCloseDefer}
                class="absolute top-0 right-2 fa-solid fa-xmark"
              ></i>
              <label>Write a note:</label>
              <textarea
                className="form-input border w-full mt-2"
                value={deferNote}
                onChange={handleNoteChange}
              />
              <label>Select a date:</label>
              <div className="flex justify-start mt-3">
                <Calendar
                  tileDisabled={tileDisabled}
                  prev2Label={null}
                  next2Label={null}
                  onChange={setDeferDate}
                  value={deferDate}
                />
              </div>
            </div>
          )} */}
        </>
      )}
    </div>
  );
};
export default MarketingPrice;
