// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'utils/axios';

// const PdfViewer = () => {
//   const { id } = useParams();  // Fetch `id` from the route params
//   const [pdfUrl, setPdfUrl] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchPdfUrl = async () => {
//       try {
//         console.log(id)
//         // Replace with your actual API endpoint to get the presigned URL
//         const response = await axios.get(`/authSchedule/get-signature-url/${id}`);
//         setPdfUrl(response.data.data);  // The presigned URL
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching the PDF URL', error);
//         setError('Could not load the PDF');
//         setLoading(false);
//       }
//     };

//     fetchPdfUrl();
//   }, [id]);  // Depend on `id` so that it refetches if the ID changes

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>{error}</div>;

//   return (
//     <div style={{ height: '100vh' }}>
//       {/* Display the PDF using an iframe */}
//       {pdfUrl && (
//         <iframe
//           src={pdfUrl}
//           title="PDF Viewer"
//           style={{ width: '100%', height: '100%' }}
//         />
//       )}
//     </div>
//   );
// };

// export default PdfViewer;


import PageLoader from 'components/ui/PageLoader';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'utils/axios';

const PdfViewer = () => {
  const { id } = useParams();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        const response = await axios.get(`/authSchedule/get-signature-url/${id}`);
        setPdfUrl(response.data.data.agreementUrl);
        setLoading(false);

        window.location.href = response.data.data.agreementUrl;
      } catch (err) {
        console.error('Error fetching the PDF URL', err);
        setError('Could not load the PDF');
        setLoading(false);
      }
    };

    fetchPdfUrl();
  }, [id]);

  if (loading) return <PageLoader/>;
  if (error) return <div>{error}</div>;

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      {pdfUrl ? (
        <object
          data={pdfUrl}
          type="application/pdf"
          width="100%"
          height="100%"
          aria-label="PDF Viewer"
        >
          <p>
            It appears your browser does not support inline PDFs. You can{' '}
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
              download the PDF here.
            </a>
          </p>
        </object>
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

export default PdfViewer;
