import React, { useEffect, useState, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import PageLoader from "../components/ui/PageLoader";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import "./Property.css";

const PropertyTable = () => {
  const [data, setData] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState([]);
  const [pageGroup, setPageGroup] = useState(0); // State for pagination groups
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/admin/property");
        setData(response.data.data); // Adjust this according to your data structure
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "index",
        header: "#",
        cell: (info) => info.row.index + 1,
        enableColumnFilter: false, // Disables search for this column
      },
      {
        accessorKey: "address",
        header: "Address",
      },
      {
        accessorKey: "suburb",
        header: "Suburb",
      },
      {
        accessorKey: "price",
        header: "Price",
        cell: ({ getValue }) =>
          getValue() ? `$${getValue().toLocaleString()}` : "N/A",
      },
      {
        accessorKey: "isCleaned",
        header: "Status",
        cell: ({ getValue }) => (getValue() ? "Cleaned" : "Not Cleaned"),
        filterFn: (row, columnId, filterValue) => {
          if (filterValue === "all") return true;
          const rowValue = row.getValue(columnId);
          return filterValue === "true" ? rowValue : !rowValue;
        },
      },
      {
        accessorKey: "listingType",
        header: "Sale Type",
      },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <button
            onClick={() => navigate(`/property/${row.original._id}`)}
            className="view-icon"
          >
            <i className="fas fa-eye"></i>
          </button>
        ),
      },
    ],
    [navigate]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      pagination,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
  });

  if (!data.length) {
    return <PageLoader />;
  }

  const totalPages = table.getPageCount();
  const pagesPerGroup = 10;
  const currentGroupStart = pageGroup * pagesPerGroup;
  const currentGroupEnd = Math.min(
    currentGroupStart + pagesPerGroup,
    totalPages
  );

  const handleNextGroup = () => {
    if (currentGroupEnd < totalPages) {
      setPageGroup(pageGroup + 1);
    }
  };

  const handlePrevGroup = () => {
    if (pageGroup > 0) {
      setPageGroup(pageGroup - 1);
    }
  };

  return (
    <div className="container mx-auto table-container">
      <div className="pagination-info">
        Showing {pagination.pageIndex * pagination.pageSize + 1}-
        {Math.min(
          (pagination.pageIndex + 1) * pagination.pageSize,
          data.length
        )}{" "}
        of {data.length} items.
      </div>
      <div className="table-responsive">
        <table className="styled-table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    <div
                      onClick={header.column.getToggleSortingHandler()}
                      className="text-sm cursor-pointer flex center items-center"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <div className="flex flex-col ml-2 text-xs">
                        <i
                          className={`fa-solid fa-caret-up ${
                            header.column.getIsSorted() === "asc"
                              ? "text-primary"
                              : ""
                          }`}
                          style={{ lineHeight: "0.5" }}
                        ></i>
                        <i
                          className={`fa-solid fa-caret-down ${
                            header.column.getIsSorted() === "desc"
                              ? "text-primary"
                              : ""
                          }`}
                          style={{ lineHeight: "0.5" }}
                        ></i>
                      </div>
                    </div>
                    {header.column.getCanFilter() &&
                    header.column.id !== "index" ? (
                      header.column.id === "isCleaned" ||
                      header.column.id === "listingType" ? (
                        <select
                          value={header.column.getFilterValue() || ""}
                          onChange={(e) => {
                            header.column.setFilterValue(e.target.value);
                          }}
                          className="column-filter"
                        >
                          {/* Render specific options based on the column */}
                          {header.column.id === "isCleaned" ? (
                            <>
                              <option value="">All</option>
                              <option value="true">Cleaned</option>
                              <option value="false">Not Cleaned</option>
                            </>
                          ) : header.column.id === "listingType" ? (
                            <>
                              <option value="">All</option>
                              <option value="Sale">Sale</option>
                              <option value="Sold">Sold</option>
                            </>
                          ) : header.column.id === "battleAxe" ? (
                            <>
                              <option value="">All</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </>
                          ) : null}
                        </select>
                      ) : (
                        <input
                          type="text"
                          value={header.column.getFilterValue() || ""}
                          onChange={(e) =>
                            header.column.setFilterValue(e.target.value)
                          }
                          placeholder={`Search ${header.column.id}`}
                          className="column-filter"
                        />
                      )
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-controls">
        <button onClick={handlePrevGroup} disabled={pageGroup === 0}>
          &laquo;
        </button>
        {Array.from({ length: currentGroupEnd - currentGroupStart }, (_, i) => (
          <button
            key={i}
            onClick={() => table.setPageIndex(currentGroupStart + i)}
            className={
              currentGroupStart + i === pagination.pageIndex ? "active" : ""
            }
          >
            {currentGroupStart + i + 1}
          </button>
        ))}
        <button
          onClick={handleNextGroup}
          disabled={currentGroupEnd >= totalPages}
        >
          &raquo;
        </button>
      </div>
    </div>
  );
};

export default PropertyTable;
