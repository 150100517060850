import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';
import { showToast } from 'components/ui/toast';
import { AuthContext } from 'context/AuthContext';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { FaMicrophone, FaStop, FaPlay, FaPause } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import axios from "utils/axios";

export default function AudioTranscript({ property,setStale,setNewTranscript }) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(property?.priceProcessAudio || '');
  const [isPaused, setIsPaused] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transcript, setTranscript] = useState(property?.priceProcessTranscript || '');
  const [summary, setSummary] = useState(property?.priceProcessSummary || '');
  const [text, setText] = useState('');
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const audioRef = useRef(null);
  const streamRef = useRef(null);
  const [timer, setTimer] = useState(0);  // Recording timer
  const [playbackTimer, setPlaybackTimer] = useState(0);  // Playback timer
  const recordingInterval = useRef(null);
  const playbackInterval = useRef(null);
  const [isAllowed, setIsAllowed] = useState(false);
  const { user } = useContext(AuthContext);
  const { name} = user || {};
  const [isOpenedPermission, setIsOpenedPermission] = useState(false);
  useEffect(() => {
    if (audioURL) {
      URL.revokeObjectURL(audioURL);
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
  }, [audioURL]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };
  const getSupportedMimeType = () => {
    const types = [
      'audio/webm;codecs=opus',
      'audio/webm',
      'audio/mp4',
      'audio/mpeg',
      'audio/ogg;codecs=opus'
    ];
    
    for (const type of types) {
      if (MediaRecorder.isTypeSupported(type)) {
        return type;
      }
    }
    return null;
  };
  const togglePlayback = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
      setPlaybackTimer(0);  // Reset playback timer to 0
      playbackInterval.current = setInterval(() => setPlaybackTimer(prev => prev + 1), 1000);
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        setIsPlaying(false);
        clearInterval(playbackInterval.current); // Stop playback timer on end
      };
      audioRef.current.onpause = () => clearInterval(playbackInterval.current);
      audioRef.current.onplay = () => {
        clearInterval(playbackInterval.current); // Ensure no extra intervals
        playbackInterval.current = setInterval(() => setPlaybackTimer(prev => prev + 1), 1000);
      };
    }
  }, [audioURL]);
  const startTimer = () => {
    setTimer(0);
    const timerInterval = setInterval(() => {
      setTimer(prevTimer => prevTimer + 1);
    }, 1000);

    audioRef.current.onended = () => {
      setIsPlaying(false);
      clearInterval(timerInterval);
      setTimer(0);
    };
    audioRef.current.onpause = () => clearInterval(timerInterval);
    audioRef.current.onplay = () => startTimer();
  };

  const callApi = async (audioBlob) => {
    setIsLoading(true);
    const formData = new FormData();
    const getFileExtension = (mimeType) => {
      if (mimeType.includes('webm')) return 'webm';
      if (mimeType.includes('mp4')) return 'm4a';
      if (mimeType.includes('mpeg')) return 'mp3';
      if (mimeType.includes('ogg')) return 'ogg';
      return 'audio';
    };
    const extension = getFileExtension(audioBlob.type);  
    formData.append('audio', new File([audioBlob], `recording.${extension}`, { type: audioBlob.type }));
    formData.append('address', property.address);

    try {
      const response = await axios.post(`/userProperty/generate-summary`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setTranscript(response?.data?.data?.transcription);
      setNewTranscript(response?.data?.data?.transcription);
      setStale(true);
      setSummary(response?.data?.data?.summary);
      setAudioURL(response?.data?.data?.audioUrl);
      showToast('success', 'Audio uploaded successfully');
    } catch (error) {
      console.error('Error uploading audio:', error?.response?.data?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const callDeleteApi = async () => { 
    setIsLoading(true);
    if(isPlaying){
      togglePlayback();
    }
    try {
      const response = await axios.delete(`/userProperty/delete-summary/${property._id}`,{
        data: { address: property.address }
      });
      setTranscript('');
      setSummary('');
      setAudioURL('');
      setStale(true);
      setNewTranscript('');
      setPlaybackTimer(0);
    setTimer(0);
    setIsPlaying(false);
      showToast('success', 'Audio deleted successfully');
    } catch (error) {
      console.error('Error deleting audio:', error?.response?.data?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  }
  const startRecording = async () => {
    try {
      if(isPlaying){
        togglePlayback();
      }
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error('Audio recording is not supported in this browser');
      }
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          // Add these constraints for better Safari support
          sampleRate: 44100,
          channelCount: 1,
          echoCancellation: true,
          noiseSuppression: true
        } 
      });
      
      streamRef.current = stream;
      const mimeType = getSupportedMimeType();
      if (!mimeType) {
        throw new Error('No supported audio MIME type found');
      }
      
          mediaRecorder.current = new MediaRecorder(stream, {
      mimeType: mimeType,
    });

      audioChunks.current = [];
      setTimer(0);  // Reset timer
      recordingInterval.current = setInterval(() => setTimer(prev => prev + 1), 1000);
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onstop = async () => {
        clearInterval(recordingInterval.current);
        if (audioChunks.current.length > 0) {
          const audioBlob = new Blob(audioChunks.current, { type: mimeType });
          setAudioURL(URL.createObjectURL(audioBlob));
          await callApi(audioBlob);
        }
      };

      mediaRecorder.current.start(1000);
      setIsRecording(true);
    } catch (err) {
      if (err.name === 'NotAllowedError') {
        showToast('error', 'Microphone permission was denied. Please allow microphone access in your browser settings.');
      } else if (err.name === 'NotFoundError') {
        showToast('error', 'No microphone found. Please check your microphone connection.');
      } else if (err.name === 'NotSupportedError') {
        showToast('error', 'Audio recording is not supported in this browser.');
      } else {
        showToast('error', `Error accessing microphone: ${err.message}`);
      }
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      clearInterval(recordingInterval.current);
      if (streamRef.current) {
        
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      setIsPaused(false);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && isRecording && !isPaused) {
      mediaRecorder.current.pause();
      setIsPaused(true);
      clearInterval(recordingInterval.current);

      
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && isRecording && isPaused) {
      mediaRecorder.current.resume();
      setIsPaused(false);
      recordingInterval.current = setInterval(() => setTimer(prev => prev + 1), 1000);  // Resume timer
    }
  };

  return (
    <>
      <Modal
        title="Audio Transcript"
        isOpen={isOpened}
        onClose={() => {
          setIsOpened(false);
          setText('');
        }}
      >
        <div className="p-4">
          <pre>{text}</pre>
        </div>
      </Modal>
      <Modal
        title="Permission"
        isOpen={isOpenedPermission}
        onClose={() => {
          setIsOpenedPermission(false);
          setIsAllowed(false);
        }}
      >
      {/* 
        set a check boc and text to allow permission
       */}
       <div className="p-4">
    
    <div className="flex items-center mt-4">
      <input
        type="checkbox"
        checked={isAllowed}
        onChange={(e) => {
          setIsAllowed(e.target.checked);
          if(e.target.checked){
            startRecording();
            setIsOpenedPermission(false);
            setIsAllowed(false);
          }
        }}
        className="mr-2 w-5 h-5"
      /><p>
      I, {name}, confirm that the vendor is aware and agreed to record and transcribe this conversation.
    </p>
    </div>
  </div>
      </Modal>
      <div className="text-lg font-semibold text-gray-700">
          {
            isRecording ? formatTime(timer) : formatTime(playbackTimer)
          }
        </div>
      <div className="flex justify-center space-x-2 overflow-auto">
        <Button
          onClick={() => {
            if(isRecording){
              stopRecording();
            }
            else{
              setIsOpenedPermission(true);
            }
            
          }}
          loading={isLoading}
          className={`black-button ${isRecording && 'bg-red-500 hover:bg-red-600'} text-white transition-colors duration-200`}
        >
          {isRecording ? <FaStop /> : <FaMicrophone />}
          
        </Button>

        {isRecording && (
          <Button
            onClick={isPaused ? resumeRecording : pauseRecording}
            disabled={!isRecording}
            className="black-button"
          >
            {isPaused ? <FaPlay /> : <FaPause />}
          </Button>
        )}

        {(!isRecording && !isLoading && audioURL) && (
          <>
            <Button
              onClick={togglePlayback}
              disabled={!audioURL}
              className="black-button"
            >
              {isPlaying ? <FaPause /> : <FaPlay />}
            </Button>

            
          </>
        )}
        {(!isRecording && !isLoading && transcript) && (
              <><Button
                onClick={() => {
                  setText(transcript);
                  setIsOpened(true);
                }}
                className="black-button"
                disabled={!transcript || isLoading}
              >
                Transcript
              </Button>
              </>
              

            )}
            {(!isRecording && !isLoading && summary) && (
              <>
              <Button
                onClick={() => {
                  setText(summary);
                  setIsOpened(true);
                }}
                className="black-button"
                disabled={!summary  || isLoading}
                
              >
                Summary
              </Button>
              
              </>
              

            )}
            {
              (!isRecording && !isLoading && audioURL) && (
                <Button
                onClick={() => {
                  callDeleteApi();
                }}
                className="black-button"
                disabled={!audioURL || isLoading}
                loading={isLoading}
              >
                <MdDelete />
              </Button>
              )
            }
      </div>
      <audio ref={audioRef} src={audioURL}/>
    </>
  );
}
