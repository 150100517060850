import Button from 'components/ui/Button';
import React, { useState, useRef, useEffect } from 'react';
import { showToast } from 'components/ui/toast';
import axios from "utils/axios";

export default function VoiceRecorder({audio}) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const audioRef = useRef(null);
  const streamRef = useRef(null);

  useEffect(() => {
    if (audio) {
      setAudioURL(audio);
    }
  }, [audio]);

  useEffect(() => {
    return () => {
      // Cleanup function
      if (audioURL) {
        URL.revokeObjectURL(audioURL);
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, [audioURL]);

  const callApi = async (audioBlob) => {
    // Log the blob size before uploading
    console.log('Audio Blob size:', audioBlob.size);
    
    const formData = new FormData();
    // Use .webm format for better compatibility
    const audioFile = new File([audioBlob], 'recording.webm', { 
      type: 'audio/webm' 
    });
    formData.append('audio', audioFile);

    try {
      const response = await axios.post('/profile/add-audio', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if(response.data.success){
        showToast('success','Audio uploaded successfully');
      }
    } catch (error) {
      console.error('Error uploading audio:', error);
      showToast('error','Error uploading audio');
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      
      mediaRecorder.current = new MediaRecorder(stream, {
        mimeType: 'audio/webm;codecs=opus'
      });

      // Clear previous chunks
      audioChunks.current = [];

      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onstop = async () => {
        // Only create blob if we have chunks
        if (audioChunks.current.length > 0) {
          const audioBlob = new Blob(audioChunks.current, { 
            type: 'audio/webm;codecs=opus' 
          });
          console.log('Recording stopped, blob size:', audioBlob.size);
          const url = URL.createObjectURL(audioBlob);
          setAudioURL(url);
          await callApi(audioBlob);
        }
      };

      // Request data every 1 second
      mediaRecorder.current.start(1000);
      setIsRecording(true);
    } catch (err) {
      console.error('Error accessing microphone:', err);
      showToast('error', 'Error accessing microphone');
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      
      // Stop all tracks in the stream
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Create a blob from the uploaded file
      const blob = new Blob([await file.arrayBuffer()], { type: file.type });
      console.log('Uploaded file size:', blob.size);
      
      const url = URL.createObjectURL(blob);
      setAudioURL(url);
      await callApi(blob);
    }
  };

  return (
    <div className="space-y-6">
      {!audioURL && (
        <p>Add Your Audio</p>
      )}
      {audioURL && (
        <audio ref={audioRef} src={audioURL} className="w-full" controls />
      )}
      <div className="flex justify-center space-x-4">
        <Button
          onClick={isRecording ? stopRecording : startRecording}
          className={`px-4 py-2 rounded-md ${
            isRecording
              ? 'bg-red-500 hover:bg-red-600'
              : 'black-button'
          } text-white font-semibold transition-colors duration-200`}
        >
          {isRecording ? 'Stop' : 'Record'}
        </Button>
        
        <label className="black-button px-4 py-2 disabled:opacity-50 disabled:cursor-not-allowed">
          Upload
          <input
            type="file"
            accept="audio/*"
            onChange={handleFileUpload}
            className="hidden"
            disabled={isRecording}
          />
        </label>
      </div>
    </div>
  );
}