import React, { useState, useEffect, useCallback } from 'react'
import { BiChevronLeft, BiChevronRight, BiPause, BiPlay,BiX } from 'react-icons/bi'

export default function Carousel({ 
  media,
  interval = 5000, 
  pauseOnHover = true,
  showControls = true,
  autoplayVideos = true,
  muteVideos = true,
  isVisible = false,
  onClose
}) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isAutoPlaying, setIsAutoPlaying] = useState(true)
  const [isVideoPlaying, setIsVideoPlaying] = useState(autoplayVideos)

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % (media?.length || 1))
  }, [media?.length])

  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + (media?.length || 1)) % (media?.length || 1))
  }, [media?.length])

  useEffect(() => {
    let intervalId = null
    if (isAutoPlaying && media.length > 1 && isVisible) {
      intervalId = setInterval(() => {
        if (media[currentIndex].type === 'image' || (media[currentIndex].type === 'video' && !isVideoPlaying)) {
          nextSlide()
        }
      }, interval)
    }
    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [isAutoPlaying, nextSlide, interval, media, currentIndex, isVideoPlaying, isVisible])

  const toggleVideoPlayback = () => {
    setIsVideoPlaying(!isVideoPlaying)
  }

  if (!isVisible) {
    return null
  }

  if (media.length === 0) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose()
          setIsAutoPlaying(true)
          setCurrentIndex(0)
        }
      }}
      role="dialog"
      aria-label="Image carousel"
      >
        <div className="bg-white p-4 rounded-lg">
          <p className="text-gray-500">No media to display</p>
        </div>
      </div>
    )
  }

  return (
    <div className="fixed inset-0 bg-black/90 z-[9999] flex justify-center items-center p-4 md:p-6 lg:p-8"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose()
          setIsAutoPlaying(true)
          setCurrentIndex(0)
        }
      }}
      role="dialog"
      aria-label="Image carousel"
    >
     <button
        className="absolute top-4 right-4 z-50 bg-white/80 hover:bg-white text-gray-800 p-1 md:p-2 rounded-full shadow-lg transition-colors"
        onClick={() => {
          onClose()
          setIsAutoPlaying(true)
          setCurrentIndex(0)
        }}
        aria-label="Close carousel"
      >
        <BiX className="w-6 h-6 md:w-8 md:h-8" />
      </button>
      <div 
        className="relative w-full h-full max-w-7xl mx-auto bg-transparent rounded-xl overflow-hidden flex items-center justify-center"
        onMouseEnter={() => pauseOnHover && setIsAutoPlaying(false)}
        onMouseLeave={() => pauseOnHover && setIsAutoPlaying(true)}
      >
        <div className="relative w-full h-full">
          <div 
            className="flex transition-transform duration-500 ease-out h-full"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {media.map((item, index) => (
              <div key={index} className="w-full flex-shrink-0 h-full flex items-center justify-center">
                <div className="relative w-full h-full flex items-center justify-center">
                  <div className="absolute inset-0 flex items-center justify-center">
                    {item.type === 'image' ? (
                      <img
                        src={item.src}
                        alt={item.alt || `Slide ${index + 1}`}
                        className="max-w-full max-h-full w-auto h-auto object-contain"
                        style={{
                          margin: 'auto',
                          maxHeight: 'min(calc(100vh - 6rem), 90vh)',
                          maxWidth: 'min(calc(100vw - 4rem), 100%)',
                          width: 'auto',
                          height: 'auto'
                        }}
                      />
                    ) : (
                      <video
                        className="max-w-full max-h-full w-auto h-auto object-contain"
                        style={{
                          margin: 'auto',
                          maxHeight: 'min(calc(100vh - 6rem), 90vh)',
                          maxWidth: 'min(calc(100vw - 4rem), 100%)',
                          width: 'auto',
                          height: 'auto'
                        }}
                        autoPlay={autoplayVideos}
                        loop
                        preload="metadata"
                        muted={muteVideos}
                        controls
                        playsInline
                        onPlay={() => setIsVideoPlaying(true)}
                        onPause={() => setIsVideoPlaying(false)}
                      >
                        <source src={item.src} type="video/mp4" />
                      </video>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {showControls && media.length > 1 && (
          <>
            <button
              className="absolute top-1/2 left-2 md:left-4 transform -translate-y-1/2 bg-white/80 text-gray-800 p-1 md:p-2 rounded-full shadow-lg hover:bg-white transition-colors z-10"
              onClick={prevSlide}
            >
              <BiChevronLeft className="w-4 h-4 md:w-6 md:h-6" />
            </button>

            <button
              className="absolute top-1/2 right-2 md:right-4 transform -translate-y-1/2 bg-white/80 text-gray-800 p-1 md:p-2 rounded-full shadow-lg hover:bg-white transition-colors z-10"
              onClick={nextSlide}
            >
              <BiChevronRight className="w-4 h-4 md:w-6 md:h-6" />
            </button>

            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 z-10">
              {media.map((_, index) => (
                <button
                  key={index}
                  className={`w-2 h-2 md:w-3 md:h-3 rounded-full transition-colors ${
                    index === currentIndex ? 'bg-white' : 'bg-white/50'
                  }`}
                  onClick={() => setCurrentIndex(index)}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}