import React, { useState, useEffect } from 'react';
import { BiPlus } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md'
import axios from 'utils/axios'; // Adjust path as needed
import Button from '../Button';
import PageLoader from '../PageLoader';

export default function SalesTable({propertyId,salesTable}) {
  const [properties, setProperties] = useState(salesTable || []);
  // useEffect(() => {
  //   const fetchProperties = async () => {

  //     try {
  //       const response = await axios.get(`/customTable/sales/${propertyId}`);
        
  //       setProperties(response?.data?.data);
  //       setLoading(false);
  //     }
  //     catch (error) {
  //       console.error('Error fetching properties:', error);
  //       setLoading(false);
  //     }
  //   }
  //   setLoading(true);
  //   fetchProperties();
  // }, []);
  // Add a new row locally and in backend
  const addRow = async () => {
    const tempId = Date.now();
    const newProperty = {
      _id: tempId,
      property: '', specifics: '', sold: '', features: '', price: '' };
      setProperties(prevProperties => [...prevProperties, newProperty]);
    try {

      const response = await axios.post(`/customTable/sales/${propertyId}`, {
        data: {
          property: '', specifics: '', sold: '', features: '', price: ''
        },
        tempId,
      });
      const savedProperty = response?.data?.data;
      
      setProperties(prevProperties =>
        prevProperties.map(p => (p._id === tempId ? { ...p, _id: savedProperty._id } : p))
      );
    } catch (error) {
      console.error('Error adding new property:', error);
    }
  };

  // Delete row locally and in backend
  const deleteRow = async (_id) => {
    try {
      // Optimistic UI update
      const updatedProperties = properties.filter(p => p._id !== _id);
      setProperties(updatedProperties);

      await axios.delete(`/customTable/sales/${propertyId}/${_id}`);
    } catch (error) {
      console.error('Error deleting property:', error);
      
    }
  };

  // Update row locally and in backend
  const updateProperty = async (_id, field, value) => {
    const updatedProperties = properties.map(p => p._id === _id ? { ...p, [field]: value } : p);
    setProperties(updatedProperties);
  };
const callUpdate = async (_id, field, value) => {

  const updatedProperty = properties.find(p => p._id === _id);
  try {
    await axios.put(`/customTable/sales/${propertyId}/${_id}`, {data:updatedProperty});
  } catch (error) {
    console.error('Error updating property:', error);
  }
}

  if (properties.length === 0) {
    return (
      <Button onClick={addRow}>
        Add Sales Table
      </Button>
    );
  }

  return (
    <div className="container p-6">
      <div className="mb-2 flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0">
        <h2 className="text-2xl font-semibold text-black">SALES.</h2>
        <Button 
          onClick={addRow} 
          className="flex items-center black-button"
          aria-label="Add new property row"
        >
          <BiPlus className="w-5 h-5 mr-2" />
          Add Row
        </Button>
      </div>
      <div className="border border-darkgray rounded-lg overflow-hidden">
        {properties.map((property, index) => (
          <div key={property?._id} className={`grid grid-cols-1 md:grid-cols-2 ${index !== properties.length - 1 ? 'border-b border-darkgray' : ''}`}>
            <div className="p-4 md:border-r border-darkgray space-y-3">
              <div className="flex flex-col sm:flex-row sm:items-start">
                <label htmlFor={`property-${property?._id}`} className="w-full sm:w-24 font-semibold text-dark mb-1 sm:mb-0">Property:</label>
                <input
                  id={`property-${property?._id}`}
                  className="bg-transparent flex-grow border-b border-black focus:border-[#e5e5e5] transition-colors pb-1 outline-none"
                  value={property.property}
                  onChange={(e) => updateProperty(property._id, 'property', e.target.value)}
                  onBlur={()=>{
                    callUpdate(property._id, 'property', property.property)
                  }}
                />
              </div>
              <div className="flex flex-col sm:flex-row sm:items-">
                <label htmlFor={`specifics-${property?._id}`} className="w-full sm:w-24 font-semibold text-dark mb-1 sm:mb-0">Specifics:</label>
                <input
                  id={`specifics-${property?._id}`}
                  className="bg-transparent flex-grow border-b border-black focus:border-[#e5e5e5] transition-colors pb-1 outline-none"
                  value={property.specifics}
                  onChange={(e) => updateProperty(property?._id, 'specifics', e.target.value)}
                  onBlur={()=>{
                    callUpdate(property._id, 'property', property.property)
                  }}
                />
              </div>
              <div className="flex flex-col sm:flex-row sm:items-">
                <label htmlFor={`sold-${property?._id}`} className="w-full sm:w-24 font-semibold text-dark mb-1 sm:mb-0">Sold Date:</label>
                <input
                  id={`sold-${property?._id}`}
                  className="bg-transparent flex-grow border-b border-black focus:border-[#e5e5e5] transition-colors pb-1 outline-none"
                  value={property.sold}
                  onChange={(e) => updateProperty(property?._id, 'sold', e.target.value)}
                  onBlur={()=>{
                    callUpdate(property._id, 'property', property.property)
                  }}
                />
              </div>
              <div className="flex flex-col sm:flex-row sm:items-">
                <label htmlFor={`features-${property?._id}`} className="w-full sm:w-24 font-semibold text-dark mb-1 sm:mb-0">Features:</label>
                <input
                  id={`features-${property?._id}`}
                  className="bg-transparent flex-grow border-b border-black focus:border-[#e5e5e5] transition-colors pb-1 outline-none"
                  value={property.features}
                  onChange={(e) => updateProperty(property?._id, 'features', e.target.value)}
                  onBlur={()=>{
                    callUpdate(property._id, 'property', property.property)
                  }}
                />
              </div>
            </div>
            <div className="p-4 flex items-center justify-between">
              <div className="flex flex-col sm:flex-row sm:items- flex-grow">
                <label htmlFor={`price-${property?._id}`} className="w-full sm:w-24 font-semibold text-dark mb-1 sm:mb-0">Price:</label>
                <div className="relative flex-grow">
                  <span className="absolute left-0 top-0 text-dark">$</span>
                  <input
                    id={`price-${property?._id}`}
                    className="bg-transparent w-full pl-4 border-b border-black focus:border-[#e5e5e5] transition-colors pb-1 outline-none"
                    value={property.price}
                    onChange={(e) => updateProperty(property?._id, 'price', e.target.value)}
                    onBlur={()=>{
                    callUpdate(property._id, 'property', property.property)
                  }}
                  />
                </div>
              </div>
              <button
                onClick={() => deleteRow(property?._id)}
                className="text-darkergray"
                aria-label={`Delete property row ${property?._id}`}
              >
                <MdDelete className="w-5 h-5" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
