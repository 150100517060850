import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import axios from "utils/axios";
import "./CalendarEvent.css"; // Assuming custom CSS for styling

const localizer = momentLocalizer(moment);

const CalendarEvent = ({ events, invitesSent = false }) => {
  const [googleEvents, setGoogleEvents] = useState([]);

  useEffect(() => {
    const fetchGoogleEvents = async () => {
      try {
        const response = await axios.get(`/events/googleCalendar`);

        // Format and filter google events based on matching eventId with parent events
        const fetchedGoogleEvents = response.data.data.map((event) => ({
          title: event.summary,
          start: new Date(event.start.dateTime || event.start.date),
          end: event.end
            ? new Date(event.end.dateTime || event.end.date)
            : null,
          description: event.description || "No description available",
          eventId: event.id, // Assuming 'id' is the event identifier
        }));

        // Filter events based on matching eventId
        const filteredEvents = fetchedGoogleEvents.filter((googleEvent) =>
          events.some(
            (parentEvent) => parentEvent.eventId === googleEvent.eventId
          )
        );

        setGoogleEvents(filteredEvents);
      } catch (error) {
        console.error("Error fetching Google events", error);
      }
    };

    if (invitesSent) {
      fetchGoogleEvents();
    } else {
      const formatEvents = events.map((event) => ({
        ...event,
        title: event.summary,
        start: new Date(event.start),
        end: new Date(event.end),
        description: event.description || "No description available",
      }));

      setGoogleEvents(formatEvents);
    }
  }, [events]); // Dependency array includes `events` to refetch if parent data changes

  // const [events, setEvents] = useState([]);
  // useEffect(() => {
  //   const fetchEvents = async () => {
  //     try {
  //       const response = await axios.get(`/events/googleCalendar`);

  //       const googleEvents = response.data.data.map((event) => ({
  //         title: event.summary,
  //         start: new Date(event.start.dateTime || event.start.date),
  //         end: new Date(event.end.dateTime || event.end.date),
  //         description: event.description || "No description available", // Add description
  //       }));

  //       setEvents(googleEvents);
  //     } catch (error) {
  //       console.error("Error fetching events", error);
  //     }
  //   };
  //   fetchEvents();
  // }, []);

  const [selectedEvent, setSelectedEvent] = useState(null); // State for selected event

  // Event selection handler
  const handleSelectEvent = (event) => {
    console.log(event);
    setSelectedEvent(event); // Store selected event details
  };

  // Close modal
  const closeModal = () => {
    setSelectedEvent(null);
  };

  return (
    <div>
      {/* Calendar Component */}
      <div style={{ marginTop: "20px", height: "100vh" }}>
        <Calendar
          localizer={localizer}
          events={googleEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "500px", margin: "20px 0" }}
          onSelectEvent={handleSelectEvent} // Handle event click
          popup // Show events in a popup
        />
      </div>

      {/* Event Details Modal */}
      {selectedEvent && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <p>
              <strong>Event:</strong> {selectedEvent.title}
            </p>
            <p>
              <strong>Start:</strong>{" "}
              {new Date(selectedEvent.start).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
              ,{" "}
              {new Date(selectedEvent.start)
                .toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                })
                .toLowerCase()}
            </p>
            <p>
              <strong>End:</strong>{" "}
              {new Date(selectedEvent.end).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
              ,{" "}
              {new Date(selectedEvent.end)
                .toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                })
                .toLowerCase()}
            </p>
            <p>
              <strong>Description:</strong>{" "}
              <span
                dangerouslySetInnerHTML={{ __html: selectedEvent.description }}
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarEvent;
