import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { FiEdit, FiSave, FiX } from 'react-icons/fi'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import Button from 'components/ui/Button'
import MarkdownEditor from 'components/markdown/MarkdownEditor'

import axios from "utils/axios";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`


export default function ViewReport({ pdfUrl,isEditing, setIsEditing,markdown,weeks,address,reportWeeks,setReportWeeks, setSelectedUrl,editIndex,setEditIndex,setIsOpen,auction }) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [value, setValue] =useState(markdown);
  const [updating, setUpdating] = useState(false)
  
  const [containerWidth, setContainerWidth] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  const updateAPICall = async (index) => {
      const input = index===5?weeks:weeks.slice(0, index + 1)
      
      setUpdating(true)
      try {
        let response;
        if(index===5){
          response = await axios.post(`/userProperty/update-conclusion-report`, {
            updatedText: value,
            userMessage: input,
            address:address,
            auction:auction
    
          });
        }else{
          response = await axios.post(`/userProperty/update-report`, {
          updatedText: value,
          userMessage: input,
          address:address,
          auction:auction
  
        });
        }
  
        if (response.data.success) {
          // replace if exists or add new report
          console.log(response.data.data)
          const newReportWeeks = reportWeeks.filter(
            (report) => report.index !== index
          );
          setReportWeeks([
            ...newReportWeeks,
            {
              index,
              data: response.data.data,
            },
          ]);
          setSelectedUrl(response?.data?.data?.url)
          setUpdating(null)
          
          setIsEditing(false)
          setEditIndex(null)
          setIsOpen(false)
          setNumPages(null)
          setPageNumber(1)
          setValue('')
          return response.data.data;
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";
          setUpdating(null)
          setIsEditing(false)
          setEditIndex(null)
          setIsOpen(false)
          setNumPages(null)
          setPageNumber(1)
          setValue('')
      }
    };
    const measureContainer = (element) => {
      if (element) {
        const width = element.getBoundingClientRect().width;
        setContainerWidth(width);
      }
    };
  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex justify-end items-center p-4">
        <div className="flex space-x-2">
          {!isEditing ? (
            <Button
              onClick={() => setIsEditing(true)}
              className="flex items-center px-3 py-2 bg-black text-white"
            >
              <FiEdit className="mr-2" /> Edit
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  updateAPICall(editIndex)
                }}
                loading={updating}
                className="flex items-center px-3 py-2 bg-black text-white"
              >
                <FiSave className="mr-2" /> Save
              </Button>
              <Button
                onClick={() => setIsEditing(false)}
                className="flex items-center px-3 py-2 bg-darkergray text-white"
              >
                <FiX className="mr-2" /> Cancel
              </Button>
            </>
          )}
        </div>
      </div>
      {
        isEditing && (
          <MarkdownEditor 
          value={value}
            setValue={setValue}
          />
        )
      }
      {
        !isEditing && (
          <>
          <div 
        ref={measureContainer}
        className="flex-grow overflow-auto py-4 w-full px-0 md:px-2 lg:px-4"
      >
        <div className="max-w-4xl mx-auto">
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            className="flex flex-col items-center"
          >
            {Array.from(new Array(numPages), (el, index) => (
              <div key={`page_${index + 1}`} className="mb-4">
                <Page
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={containerWidth ? Math.min(containerWidth - 32, 896) : undefined}
                  className="shadow-lg"
                />
              </div>
            ))}
          </Document>
        </div>
      </div>
      
      </>
        )
      }
    </div>
  )
}