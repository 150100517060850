// StartCampaign.jsx
import React, { useState } from "react"; 
import { useNavigate } from "react-router-dom";
import Error from "components/ui/Error";
import { Autocomplete } from "@react-google-maps/api"; // Removed useJsApiLoader
import { showToast } from "components/ui/toast";
import { IoSend } from "react-icons/io5";
import axios from "utils/axios";
import PageLoader from "components/ui/PageLoader";

const StartCampaign = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState("");

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const searchProperty = async (
    shortAddress,
    suburb,
    postcode,
    latitude,
    longitude
  ) => {
    try {
      setLoading(true);
      const response = await axios.post("/userProperty", {
        address: shortAddress,
        suburb,
        postcode,
        latitude,
        longitude,
      });

      if (response.data.success) {
        console.log(response.data.data);
        navigate(`/chat/${encodeURIComponent(shortAddress)}?tab=book-appraisal`);
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
      showToast("error", "Failed to fetch properties. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePlaceChanged = async () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      console.log(place);
    
      if (place && place.address_components && place.geometry) {
        const addressComponents = place.address_components;
    
        // Extract the formatted address components
        const suburb =
          addressComponents.find(
            (component) =>
              component.types.includes("locality") &&
              component.types.includes("political")
          )?.long_name || "";
    
        const postcode =
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "";
    
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
    
        let fullAddress = place.formatted_address;
    
        // Check if the address contains 'NSW'
        if (!fullAddress.includes("NSW")) {
          showToast("error", "Only NSW properties are allowed.");
          return;
        }
    
        // Step 1: Remove everything after 'NSW'
        let shortAddress = fullAddress.split("NSW")[0].trim();
    
        // Step 2: Dynamically replace abbreviations with long form from address components
        addressComponents.forEach((component) => {
          const longName = component.long_name;
          const shortName = component.short_name;
    
          // If the fullAddress contains the abbreviation (short name), replace it with the long name
          if (shortName && fullAddress.includes(shortName)) {
            shortAddress = shortAddress.replace(shortName, longName);
          }
        });
    
        // Set the final formatted address
        setAddress(shortAddress);
        console.log(shortAddress);
    
        // Proceed to search the property
        await searchProperty(shortAddress, suburb, postcode, latitude, longitude);
      } else {
        // Logic when the input is cleared or invalid place selected
        showToast("error", "Invalid place selected.");
      }
    }
  };

  const handleInputChange = (e) => {
    setAddress(e.target.value);
  };

  const handleSend = async () => {
    console.log(address)
    if (address.trim() === "") {
      showToast("error", "Please type or select a valid address.");
      return;
    }
    // if (!address.toLowerCase().includes("nsw")) {
    //   showToast("error", "Only NSW properties are allowed.");
    //   return;
    // }

    const addressWithoutNSW = address.replace("NSW", "").trim();
    const [streetAddress, suburb] = addressWithoutNSW.split(", ");

    if (!suburb) {
      showToast("error", "Invalid address format.");
      return;
    }

    await searchProperty(addressWithoutNSW, suburb);
    // navigate(`/chat/${encodeURIComponent(address)}`);
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="container flex flex-col items-center">
      <div className="w-full fixed bottom-8 left-0 right-0 px-6 pt-2.5 pb-4 z-50 bg-white">
        <div className="w-full max-w-4xl mx-auto flex gap-2 items-end justify-center relative">
          <Autocomplete
            onLoad={handleLoad}
            onPlaceChanged={handlePlaceChanged}
            options={{
              componentRestrictions: { country: ["au"] },
              fields: ["address_components", "geometry", "formatted_address"],
              types: ["geocode"],
            }}
            className="w-full"
          >
            <div className="max-w-md mx-auto relative text-xs">
              <input
                type="text"
                value={address}
                onChange={handleInputChange}
                placeholder="Enter address here"
                className="start-campaign-input w-full relative z-10 flex-grow p-2 bg-lightgray rounded-md py-3 pl-3 pr-8 outline-none focus:outline-none resize-none overflow-y-hidden"
              />
              {address && (
                <IoSend
                  onClick={handleSend}
                  className="cursor-pointer text-darkgray hover:text-darkergray text-xl absolute z-20 top-[10px] right-[10px]"
                />
              )}
            </div>
          </Autocomplete>
        </div>
      </div>
    </div>
  );
};

export default StartCampaign;
