import React, { useState, useEffect } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import axios from "utils/axios";
import { HiChevronDown } from "react-icons/hi2";

const Compliance = ({ property }) => {
  const [agreementUrl, setAgreementUrl] = useState(null);

  const [proofUrl, setProofUrl] = useState(null);
  const [solicitorUrl, setSolicitorUrl] = useState(null);

  const [serviceBuildingUrl, setServiceBuildingUrl] = useState(null);
  const [serviceStrataUrl, setServiceStrataUrl] = useState(null);

  const [vendors, setVendors] = useState([]);
  const [authData, setAuthData] = useState(null);

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        const objectIdString = property._id.toString(); // Convert ObjectId to string

        const response = await axios.get(
          `/authSchedule/get-signature-url/${objectIdString}`
        );
        if (response.data.success) {
          setAgreementUrl(response.data.data.agreementUrl);
          setProofUrl(response.data.data.proofUrl);
          setVendors(response.data.data.vendors);
          setSolicitorUrl(response.data.data?.solicitorUrl || null);
          setServiceBuildingUrl(response.data.data?.serviceBuildingUrl || null);
          setServiceStrataUrl(response.data.data?.serviceStrataUrl || null);
        }
      } catch (err) {
        console.error("Error fetching the PDF URL", err);
      }
    };

    fetchPdfUrl();
  }, [property]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/authSchedule/${property._id}`);
        const data = response.data.data; // Adjust according to your API response structure

        if (data) {
          setAuthData(data);
        }
      } catch (error) {
        console.error("Error fetching authorise schedule data:", error);
      }
    };

    fetchData();
  }, [property]);

  return (
    <div className="w-full max-w-4xl mx-auto text-center flex flex-col justify-between space-y-4">
      <h4 className="text-center">Compliance</h4>
      <p>{property.address}</p>
      <Disclosure as="div" className="w-full" defaultOpen={false}>
        <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
          <span className="font-medium">Agency agreement</span>
          <HiChevronDown className="group-data-[open]:rotate-180" />
        </DisclosureButton>
        <DisclosurePanel className="mt-2">
          <div className="flex justify-between p-2">
            <p>Agreement</p>
            <i
              className="fa-solid fa-circle-down cursor-pointer"
              onClick={() => {
                window.location.href = agreementUrl;
              }}
            ></i>
          </div>
          <div className="flex justify-between p-2">
            <p>Proof of ID Checklist</p>
            <i
              className="fa-solid fa-circle-down cursor-pointer"
              onClick={() => {
                window.location.href = proofUrl;
              }}
            ></i>
          </div>
          {vendors.map((vendor, index) => (
            <div key={index} className="flex justify-between p-2 items-center">
              <p>
                Vendor: {vendor.firstName} {vendor.lastName}
              </p>
              {vendor.licenceSignedUrl ? (
                <i
                  className="fa-solid fa-circle-down cursor-pointer"
                  onClick={() => {
                    window.location.href = vendor.licenceSignedUrl;
                  }}
                ></i>
              ) : (
                <p>No License Image Available</p>
              )}
            </div>
          ))}
        </DisclosurePanel>
      </Disclosure>
      <br></br>
      <Disclosure as="div" className="w-full" defaultOpen={false}>
        <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
          <span className="font-medium">Contract of Sale</span>
          <HiChevronDown className="group-data-[open]:rotate-180" />
        </DisclosureButton>
        <DisclosurePanel className="mt-2">
          {solicitorUrl && (
            <div className="flex justify-between p-2">
              <p>Contract</p>
              <i
                className="fa-solid fa-circle-down cursor-pointer"
                onClick={() => {
                  window.location.href = solicitorUrl;
                }}
              ></i>
            </div>
          )}
          {serviceBuildingUrl && (
            <div className="flex justify-between p-2">
              <p>Building & Pest</p>
              <i
                className="fa-solid fa-circle-down cursor-pointer"
                onClick={() => {
                  window.location.href = serviceBuildingUrl;
                }}
              ></i>
            </div>
          )}
          {serviceStrataUrl && (
            <div className="flex justify-between p-2">
              <p>Strata</p>
              <i
                className="fa-solid fa-circle-down cursor-pointer"
                onClick={() => {
                  window.location.href = serviceStrataUrl;
                }}
              ></i>
            </div>
          )}
        </DisclosurePanel>
      </Disclosure>
    </div>
  );
};

export default Compliance;
